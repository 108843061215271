import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { QueueStatusEnum } from '../../apiClient/types';
import { QueueStatusBadge } from '../hardware/QueueStatusBadge';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const QueueStatusBadgeDescriptionModal = ({ isOpen, onClose }: Props) => {
  const statusDescriptions = {
    [QueueStatusEnum.FIRST]: <FormattedMessage id="queue_status_description_first" />,
    [QueueStatusEnum.FIRST_FOR_ME]: <FormattedMessage id="queue_status_description_first_for_me" />,
    [QueueStatusEnum.PENDING]: <FormattedMessage id="queue_status_description_pending" />,
    [QueueStatusEnum.PENDING_FOR_ME]: (
      <FormattedMessage id="queue_status_description_pending_for_me" />
    ),
    [QueueStatusEnum.QUEUED]: <FormattedMessage id="queue_status_description_queued" />,
    [QueueStatusEnum.QUEUED_FOR_ME]: (
      <FormattedMessage id="queue_status_description_queued_for_me" />
    ),
    [QueueStatusEnum.NOT_QUEUED]: '',
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="queue_status_description_header" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="start">
            {Object.entries(QueueStatusEnum).map(([status, value]) => (
              <HStack key={status}>
                <Box w="125px">
                  <QueueStatusBadge queueStatus={value} />
                </Box>
                <Text>{statusDescriptions[value]}</Text>
              </HStack>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
