import Ajv from 'ajv';
import configSchema from './configSchema.json';
import type { FoxWebAppRuntimeLoadedConfig } from './configSchema';
import type { Config } from './types';

export async function loadConfig(): Promise<Config> {
  const res = await fetch('/config.json');

  if (!res.ok) throw new Error('Failed to load config: Response not OK.');

  const loadedConfig = await res.json();

  const validate = new Ajv().compile<FoxWebAppRuntimeLoadedConfig>(configSchema);
  if (!validate(loadedConfig)) throw new Error('Failed to load config: Invalid config.');

  return applyDefaults(applyIgnored(loadedConfig));
}

function applyIgnored(loadedConfig: FoxWebAppRuntimeLoadedConfig): FoxWebAppRuntimeLoadedConfig {
  const { $schema, ...nonIgnoredConfig } = loadedConfig;
  return nonIgnoredConfig;
}

function applyDefaults(loadedConfig: FoxWebAppRuntimeLoadedConfig): Config {
  const { scopes, authority, redirectUri, logoutUri, protocolMode, ...requiredConfig } =
    loadedConfig;
  return {
    ...requiredConfig,
    clientId: requiredConfig.clientId,
    protocolMode: protocolMode ?? 'OIDC',
    scopes: scopes ?? ['openid', 'profile', 'email', 'offline_access'],
    authority: authority ?? 'https://auth.devicevault.fi/realms/beta',
    redirectUri: redirectUri ?? `${requiredConfig.publicUrl}`,
    logoutUri: logoutUri ?? `${requiredConfig.publicUrl}`,
  };
}
