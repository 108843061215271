import { useCallback } from 'react';
import { useToast } from '@chakra-ui/toast';
import { useIntl } from 'react-intl';

export function useShowOutOfDate() {
  const toast = useToast();
  const intl = useIntl();

  return useCallback(
    function useShowOutOfDate(options: { description?: string } = {}) {
      let description;
      if (options.description) {
        description = options.description;
      }

      if (!toast.isActive(1)) {
        toast({
          id: 1,
          title: intl.formatMessage({ id: 'useshowoutofdate_warning_title' }),
          description,
          duration: null,
          position: 'bottom-left',
          isClosable: true,
        });
      }
    },
    [toast, intl]
  );
}
