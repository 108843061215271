import { Spinner, Text } from '@chakra-ui/react';

type Props = {
  label: string | React.ReactElement;
};

export const Loader = ({ label }: Props) => (
  <>
    <Text>{label}</Text>
    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.500" size="xl" />
  </>
);
