import { useState } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { DesktopActions, DesktopContent, MobileLoggedIn, MobileLoggedOut } from './HeaderContent';
import deviceVaultLogo from './devicevaultLogoR.webp';
import { Box, Container, HStack, Image } from '@chakra-ui/react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useIsMobile } from '../../useIsMobile';

// Render header content conditionally based on screen size
const NavigationMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isLoggedIn = useIsAuthenticated();
  const isMobile = useIsMobile();

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  let content;
  if (isMobile) {
    content = (
      <HStack w="full" justifyContent="space-between" alignItems="center">
        <HStack spacing={8}>
          <RouterNavLink to="/">
            <Box width="190px">
              <Image src={deviceVaultLogo} />
            </Box>
          </RouterNavLink>
        </HStack>
        <HStack>
          {!isLoggedIn && <MobileLoggedOut />}
          {isLoggedIn && <MobileLoggedIn isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
        </HStack>
      </HStack>
    );
  } else {
    content = (
      <HStack w="full" justifyContent="space-between" alignItems="center">
        <HStack spacing={8}>
          <RouterNavLink to="/">
            <Box width="220px">
              <Image src={deviceVaultLogo} />
            </Box>
          </RouterNavLink>
          <DesktopContent />
        </HStack>
        <HStack spacing={6}>
          <DesktopActions />
        </HStack>
      </HStack>
    );
  }

  return content;
};

export function Header() {
  return (
    <Box paddingX={{ base: 0, md: 8 }} paddingY={{ base: 4, md: 6 }} bg="brand.500">
      <Container maxW="container.lg" display="flex">
        <NavigationMenu />
      </Container>
    </Box>
  );
}
