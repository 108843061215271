import { Text } from '@chakra-ui/react';
import { FormikErrors, FormikTouched } from 'formik';
import { FormattedMessage } from 'react-intl';
import { AclAccessLevelEnum } from '../../apiClient/types';
import { FormValues } from '../realmManagement/AddUserModal';

export const isAdminOrRoot = (accessLevel: AclAccessLevelEnum | undefined) => {
  return accessLevel === AclAccessLevelEnum.ADMIN || accessLevel === AclAccessLevelEnum.ROOT;
};

export const renderAddUserValidationError = (
  field: keyof FormValues,
  touched: FormikTouched<FormValues>,
  errors: FormikErrors<FormValues>
) => {
  let messageId = '';

  if (touched[field] && errors[field]) {
    if (field === 'userIds') {
      const isInvalidEmailError = errors[field]?.includes('Invalid email address');
      messageId = isInvalidEmailError
        ? 'realm_add_user_invalid_email'
        : 'realm_add_user_missing_id';
    } else if (field === 'groupIds') {
      messageId = 'realm_add_user_missing_groups';
    }
  }

  return messageId ? (
    <Text color="red.500" mr={3}>
      <FormattedMessage id={messageId} />
    </Text>
  ) : null;
};
