import { useBreakpointValue } from '@chakra-ui/react';

/**
 * Returns if the current viewport corresponds to a mobile device.
 * Utilizes 'useBreakpointValue' hook from chakra.
 *
 * @see useBreakpointValue {@link https://chakra-ui.com/docs/hooks/use-breakpoint-value}
 */

export function useIsMobile() {
  return useBreakpointValue({ base: true, custom: false });
}
