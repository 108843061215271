import { HStack, VStack, Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Hardware } from '../../apiClient/types';
import { AttributesTable } from '../../hardware/AttributesTable';
import { getTableAttributes } from '../../hardware/getTableAttributes';
import { AccordionLayout } from './AccordionLayout';

type Props = {
  hardware: Hardware;
};

export const AttributesSection = ({ hardware }: Props) => {
  const intl = useIntl();
  return (
    <>
      <AccordionLayout
        buttonContent={
          <HStack justify="space-between" px={0} w="full" mr={2}>
            <Text fontWeight="bold">
              <FormattedMessage id="hardwaredetailpage_title_attributes" />
            </Text>
          </HStack>
        }
        panelContent={
          <VStack w="full" align="stretch" justify="center" p={0}>
            <AttributesTable
              attributes={getTableAttributes('product', hardware, intl)}
              caption={<FormattedMessage id="hardwaredetailgrid_product_attributes_caption" />}
            />
            <AttributesTable
              attributes={getTableAttributes('hardware', hardware, intl)}
              caption={<FormattedMessage id="hardwaredetailgrid_hardware_attributes_caption" />}
            />
          </VStack>
        }
        buttonStyles={{ borderBottom: 'none' }}
        panelStyles={{ px: '6', py: '4' }}
      />
    </>
  );
};
