import { useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useIsMobile } from '../../useIsMobile';
import { HardwareFilterInputDrawer } from './HardwareFilterInputDrawer';
import { HardwareFilterInputMenu } from './HardwareFilterInputMenu';

type Props = {
  onSearchChange: (search: string, isExclude: boolean) => void;
  inputSearchString: string;
};

export const HardwareFilterInput = (props: Props) => {
  const intl = useIntl();
  const isMobile = useIsMobile();

  const [inputValue, setInputValue] = useState(props.inputSearchString);
  const [isExcluding, setIsExcluding] = useState(false);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      props.onSearchChange(inputValue, isExcluding);
      setInputValue('');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleToggleExclude = () => {
    setIsExcluding(!isExcluding);
  };

  return (
    <InputGroup size="md" w={isMobile ? '100%' : '30%'}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="brand.600" />
      </InputLeftElement>
      <Input
        bg="white"
        placeholder={intl.formatMessage({ id: 'hardwarefilter_filter_hardware' })}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        value={inputValue}
        noOfLines={1}
      />
      <InputRightElement>
        {isMobile ? (
          <HardwareFilterInputDrawer
            isExcluding={isExcluding}
            handleToggleExclude={handleToggleExclude}
          />
        ) : (
          <HardwareFilterInputMenu
            isExcluding={isExcluding}
            handleToggleExclude={handleToggleExclude}
          />
        )}
      </InputRightElement>
    </InputGroup>
  );
};
