import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';

export const useRealmInformation = (realmId: string) => {
  const { getRealmInformation } = useApiClient();
  const showError = useShowError();
  const intl = useIntl();

  const {
    data: realmInformation,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ['realm', realmId],
    queryFn: () => getRealmInformation(realmId),
  });

  useEffect(() => {
    if (isError) {
      showError(error.message, {
        description: intl.formatMessage({ id: 'realms_get_information_error' }),
      });
    }
  }, [isError, error, showError, intl]);

  return { realmInformation, refetch, isError, error, isLoading };
};
