import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, Td, Tr } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Hardware } from '../../apiClient/types';

export const SingleQueueHardwareItem = ({ hardware }: { hardware: Hardware }) => {
  const navigate = useNavigate();

  const navigateToHardware = () => {
    navigate(`/hardware/${hardware.location}`);
  };

  const count = hardware.status.reservation.queueCount;

  return (
    <Tr onClick={() => navigateToHardware()} _hover={{ bgColor: 'gray.50', cursor: 'pointer' }}>
      <Td>{hardware.name}</Td>
      <Td>
        {hardware.serialNumber} - {hardware.server.host}
      </Td>
      <Td>
        <FormattedMessage id="queue_single_hardware_in_queue" values={{ count }} />
      </Td>
      <Td textAlign="end">
        <Button
          onClick={() => navigateToHardware()}
          variant="ghost"
          leftIcon={<ExternalLinkIcon />}
          marginY="-2.5"
        >
          <FormattedMessage id="queue_single_hardware_view" />
        </Button>
      </Td>
    </Tr>
  );
};
