import {
  Button,
  ButtonGroup,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleConfirmClick: () => void;
  selectedHardware?: Set<string>;
  setMessage: (message: string | undefined) => void;
};

export const QueueConfirmationModal = (props: Props) => {
  const intl = useIntl();
  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setMessage(event.target.value);
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="queue_modal_confirm_selection" />
        </ModalHeader>
        <ModalCloseButton onClick={props.onClose} />
        <ModalBody>
          <VStack align="left" spacing={1}>
            <Text>
              <FormattedMessage id="queue_modal_message_optional" />
            </Text>
            <Input
              onChange={handleMessageChange}
              placeholder={intl.formatMessage({ id: 'queue_modal_input_placeholder' })}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={props.onClose}>
              <FormattedMessage id="commons_close" />
            </Button>
            <Button onClick={props.handleConfirmClick}>
              <FormattedMessage id="queue_queue" />
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
