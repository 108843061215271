import { type ReactNode } from 'react';
import { Box, Heading, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';

export function SectionLink({
  heading,
  description,
  to,
  disabled,
  external,
}: { heading: ReactNode; description: ReactNode; external?: boolean } & (
  | { to: string; disabled?: never }
  | { to?: never; disabled: true }
)) {
  if (disabled) {
    return (
      <Box backgroundColor="whiteAlpha.500" color="gray.500" p={8} maxW={500}>
        <Heading as="h4" size="md" mb={2}>
          {heading}
        </Heading>
        <Text>{description}</Text>
      </Box>
    );
  }
  return (
    <LinkBox backgroundColor="white" p={8}>
      <Heading as="h4" size="md" mb={2}>
        {external ? (
          <LinkOverlay target="_blank" href={to}>
            {heading}
          </LinkOverlay>
        ) : (
          <LinkOverlay as={RouterLink} to={to}>
            {heading}
          </LinkOverlay>
        )}
        <ChevronRightIcon color="brand.500" />
      </Heading>
      <Text>{description}</Text>
    </LinkBox>
  );
}
