import { Moment } from 'moment';
import { EnumType } from 'typescript';

export type ApiClient = {
  freeHardware: (params: { hardwareLocation: Hardware['location'] }) => Promise<unknown>;
  listProxyManagers: () => Promise<ProxyManager[]>;
  listHardware: (
    fortKnoxServer?: Mailbox['identifier'],
    artifactId?: Artifact['id']
  ) => Promise<Hardware[]>;
  reserveHardware: (params: { hardwareLocation: Hardware['location'] }) => Promise<unknown>;
  downloadProxyManager: () => Promise<DownloadableFile>;
  selectHardware: (params: {
    hardwareLocation: Hardware['location'];
    proxyManager?: HardwareProxy['proxyManagerName'];
    proxyParameters?: HardwareProxyParameters;
  }) => Promise<unknown>;
  getProxyParametersDefinition: (params: {
    hardwareLocation: Hardware['location'];
    proxyManager: HardwareProxy['proxyManagerName'];
  }) => Promise<HardwareProxyParametersDefinitionView>;
  executeHardwareAction: (params: {
    hardwareLocation: Hardware['location'];
    actionId: Action['id'];
    optionId?: ActionOption['id'];
  }) => Promise<ActionResult>;
  getSingleHardware: (params: {
    hardwareLocation: Hardware['location'];
  }) => Promise<SingleHardwareResult>;
  getHardwareActionResult: (params: {
    hardwareLocation: Hardware['location'];
    actionRunId: ActionRun['id'];
  }) => Promise<ActionResult>;
  listArtifacts: () => Promise<Artifact[]>;
  downloadArtifact: (artifact: Artifact) => Promise<DownloadableFile>;
  authenticateWebSocket: (webSocket: WebSocket) => Promise<unknown>;
  connectWebSocket: (() => WebSocket) | undefined;
  getRealmInformation: (realmId: string) => Promise<RealmAdmin>;
  editUserGroups: (realmGroupId: string, userId: string, retirement?: boolean) => Promise<unknown>;
  removeFromGroup: (realmGroupId: string, userId: string) => Promise<unknown>;
  listRealms: () => Promise<RealmAdmin[]>;
  addUsersToRealm: (
    realmId: string,
    userIds: string[],
    groupIds: string[],
    retirement?: ValueRetirement
  ) => Promise<unknown>;
  setRealmUserGroups: (
    realmId: string,
    userId: string,
    groupIds?: string[],
    retirement?: ValueRetirement
  ) => Promise<unknown>;
  getSessionInformation: () => Promise<SessionInformationResponse>;
  updateSessionGroups: (realmGroupIds: string[]) => Promise<unknown>;
  listSearchArtifacts: () => Promise<Artifact[]>;
  hardwareQueueFromSearch: (artifactLocation: string, message?: string) => Promise<string>;
  hardwareQueueFromSelection: (hardwareLocation: string[], message?: string) => Promise<string>;
  getHardwareQueue: (queueId: string) => Promise<HardwareQueue>;
  listHardwareQueues: (all?: boolean) => Promise<HardwareQueue[]>;
  removeHardwareQueue: (queueId: string) => Promise<unknown>;
  reserveHardwareQueue: (
    queueId: string,
    message?: string,
    hardwareLocation?: string
  ) => Promise<string>;
};

export type SingleHardwareResult = {
  detail: HardwareDetail;
  hardware: Hardware;
};

export type TerminalCommandResult = {
  commandName: string;
  success: boolean;
  stdOut?: string;
  errOut?: string;
  exitValue?: number;
  exception?: Exception;
};

export type Action = {
  accessLevel: AclAccessLevelEnum;
  id: string;
  name: string;
  options?: Array<ActionOption>;
};

export type ActionOption = {
  id: string;
  name: string;
};

export type ActionResult = {
  run: ActionRun;
  progress: ProgressMessage;
  server: string;
  hardware?: HardwareSummary;
  completedOn?: Moment;
  commandResult?: TerminalCommandResult;
};

export type ActionRun = {
  accessLevel: AclAccessLevelEnum;
  action: Action;
  createdBy: string;
  createdOn: string;
  id: string;
  status: ActionRunProgressEnum;
  option?: ActionOption;
};

export type Exception = {
  class: string;
  details: string;
  message?: string;
};

export type ProgressMessage = {
  status: ProgressMessageEnum;
  details: string;
};

export type HardwareDetail = {
  actionRuns?: Array<ActionRun>;
};

export type AppProxy = {
  address: string;
  type: string;
};

export type ProxyManager = Mailbox;

export type Mailbox = {
  accessLevel: AclAccessLevelEnum;
  host: string;
  name: string;
  displayName: string;
  identifier: string;
  compatibilityCode: CompatibilityCodeEnum;
  compatibilityMessage: string;
};

export type Hardware = {
  accessLevel: AclAccessLevelEnum;
  product: Product;
  guard: {
    canFree: boolean;
    canConnect: boolean;
    canReserve: boolean;
    canQueue: boolean;
  };
  actions?: Array<Action>;
  interfaces: HardwareInterface[];
  attributes: Object;
  name: string;
  serialNumber: string;
  server: Mailbox;
  location: string;
  status: HardwareStatus;
};

export type HardwareSummary = {
  attributes: Object;
  name: string;
  product: Product;
  serialNumber: string;
};

export type Product = {
  id: string;
  name: string;
  attributes: Object;
};

export type HardwarePreparation = {
  available: boolean;
  lastRunId: string;
  lastRunName?: string;
  active?: number;
  total?: number;
};

export type HardwareReservation = {
  status: ReservationStatusEnum;
  reservedBy?: string;
  reservedDate?: Moment;
  message?: string;
  queueCount?: number;
};

export type HardwareStatus = {
  connection: ConnectionStatusEnum;
  proxy: HardwareProxy;
  preparation: HardwarePreparation;
  reservation: HardwareReservation;
};

export type HardwareProxy = {
  active: boolean;
  proxyManagerName?: string;
  hostNetwork?: string;
  hostPrimaryAddress?: string;
  hostAllAddresses?: Array<string>;
  proxies?: Array<AppProxy>;
};

export type HardwareInterface = {
  id: string;
  name: string;
  type: EnumType;
  typeDisplay: string;
  typeDisplayShort: string;
  port?: number;
  host?: string;
};

export type Artifact = {
  id: string;
  location: string;
  path: string;
  name: string;
  serverName: string;
  mailboxId: string;
  uploadedBy: string;
  uploadedOn: string;
  matchWeight?: number;
  groupName?: string;
  groupVersion?: number;
};

export type MessageProgress = {
  details: string;
  status: ProgressMessageEnum;
};

export type HardwareProxyParametersDefinitionView = {
  hardwareName: string;
  productName: string;
  interfaces: InterfaceProxyParametersDefinitionView[];
};

export type InterfaceProxyParametersDefinitionView = {
  name: string;
  id: string;
  parameters: ProxyParameterDefinition[];
};

export type ProxyParameterDefinition = {
  key: string;
  type: number | string | boolean;
  label: string;
  defaultValue?: string;
  discreet?: ProxyParameterDiscreteValue[];
};

export type ProxyParameterDiscreteValue = {
  id: string;
  display: string;
};

export type HardwareProxyParameters = {
  interfaces: InterfaceProxyParameters[];
};

export type InterfaceProxyParameters = {
  id: string;
  parameters: ProxyParameter[];
};

export type ProxyParameter = {
  key: string;
  value: string;
};

export enum CompatibilityCodeEnum {
  OK = 'OK',
  APP_PM_INCOMPATIBLE = 'APP_PM_INCOMPATIBLE',
  HW_PM_INCOMPATIBLE = 'HW_PM_INCOMPATIBLE',
  HW_INCOMPATIBLE = 'HW_INCOMPATIBLE',
}

export enum ConnectionStatusEnum {
  NO_CONNECTION = 'NO_CONNECTION',
  UNKNOWN = 'UNKNOWN',
  OFFLINE = 'OFFLINE',
  PARTIALLY_ONLINE = 'PARTIALLY_ONLINE',
  ONLINE = 'ONLINE',
}

export enum ProgressMessageEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  COMPLETE = 'COMPLETE',
}

export enum ActionRunProgressEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  ERROR = 'ERROR',
}

export enum AclAccessLevelEnum {
  NONE = 'NONE',
  LIST = 'LIST',
  READ = 'READ',
  USE = 'USE',
  ADMIN = 'ADMIN',
  ROOT = 'ROOT',
}

export enum ReservationStatusEnum {
  FREE = 'FREE',
  PENDING_FOR_ME = 'PENDING_FOR_ME',
  PENDING = 'PENDING',
  RESERVED_BY_ME = 'RESERVED_BY_ME',
  RESERVED = 'RESERVED',
}

export enum QueueStatusEnum {
  PENDING_FOR_ME = 'PENDING_FOR_ME',
  FIRST_FOR_ME = 'FIRST_FOR_ME',
  QUEUED_FOR_ME = 'QUEUED_FOR_ME',
  PENDING = 'PENDING',
  FIRST = 'FIRST',
  QUEUED = 'QUEUED',
  NOT_QUEUED = 'NOT_QUEUED',
}

export enum AttributeTypeEnum {
  TEXT = 'TEXT',
  DATETIME = 'DATETIME',
}

export interface DownloadableFile {
  blob: Blob;
  name: string;
}

export type GroupAdmin = {
  id: string;
  name: string;
  retirement?: ValueRetirement;
  inactive?: boolean;
  description?: string;
  includeGroups?: string[];
  users?: GroupUserAdmin[];
  effectiveUsers?: string[];
  admin?: AclAccessLevelEnum;
};

export type GroupSession = {
  id: string;
  name: string;
  description?: string;
  active: boolean;
  activeParent: boolean;
  activeDefault: boolean;
  activeOverride: boolean;
};

export type GroupUserAdmin = {
  id: string;
  retirement?: ValueRetirement;
};

export type RealmAdmin = {
  id: string;
  name: string;
  retirement?: ValueRetirement;
  description?: string;
  groups: GroupAdmin[];
  accessLevel?: AclAccessLevelEnum;
};

export type RealmSession = {
  id: string;
  name: string;
  description?: string;
  groups: GroupSession[];
};

export type ValueRetirement = {
  retired?: boolean;
  validFrom?: string;
  validUntil?: string;
};

export type SessionInformationResponse = {
  realms: RealmSession[];
  realmCreate?: boolean;
  realmAdmin?: boolean;
};

export type GetHardwareQueueResponse = {
  queue: HardwareQueue;
};

export type HardwareQueue = {
  id: string;
  name: string;
  status: QueueStatusEnum;
  accessLevel: AclAccessLevelEnum;
  myQueue: boolean;
  allReserved: boolean;
  canReserve: number;
  userId: string;
  message?: string;
  artifact?: Artifact;
  hardware?: Hardware[];
};
