import { InfoIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Badge, HStack, Text } from '@chakra-ui/react';

export enum BadgeVariant {
  WARNING,
  ERROR,
  INFO,
}

type Props = {
  variant: BadgeVariant;
  label: string | React.ReactElement;
};

/**
 * Badge component for RealmManagement, used to display warnings, errors and info.
 * @param variant - variant of the badge, warning, error or info
 * @param label - label of the badge
 */

export const RealmWarningBadge = ({ variant, label }: Props) => {
  let colorScheme, textColor, icon;

  switch (variant) {
    case BadgeVariant.WARNING:
      colorScheme = 'orange';
      textColor = 'red.900';
      icon = <WarningTwoIcon color="red.900" />;
      break;
    case BadgeVariant.ERROR:
      colorScheme = 'red';
      textColor = 'white';
      icon = <WarningIcon color="white" />;
      break;
    case BadgeVariant.INFO:
      colorScheme = 'blue';
      textColor = 'white';
      icon = <InfoIcon color="white" />;
      break;
    default:
      break;
  }

  return (
    <Badge
      textTransform="none"
      fontSize="14px"
      colorScheme={colorScheme}
      px={3}
      h="32px"
      borderRadius="md"
    >
      <HStack w="full" align="center" h="100%">
        {icon}
        <Text color={textColor}>{label}</Text>
      </HStack>
    </Badge>
  );
};
