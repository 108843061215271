import { FormattedMessage } from 'react-intl';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { LanguageAndLocaleSelector } from '../../i18n/LanguageAndLocaleSelector';
import { PrimaryNavLink } from '../PrimaryNavLink';
import { LoggedInUserMenu } from './LoggedInUserMenu';
import {
  HStack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Display header content as a drawer on smaller screen sizes
export function MenuDrawer({ isOpen, onClose }: Props) {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent bg="brand.500">
          <DrawerCloseButton color="white" mt="2" mr="2" />
          <DrawerHeader color="white">Navigation</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing="4">
              <AuthenticatedTemplate>
                <PrimaryNavLink to="/dashboard">
                  <FormattedMessage id="header_dashboard" />
                </PrimaryNavLink>
                <PrimaryNavLink to="/hardware">
                  <FormattedMessage id="header_hardware" />
                </PrimaryNavLink>

                <PrimaryNavLink to="/queues">
                  <FormattedMessage id="queue_heading" />
                </PrimaryNavLink>

                <PrimaryNavLink to="/download">
                  <FormattedMessage id="header_downloads" />
                </PrimaryNavLink>
              </AuthenticatedTemplate>
              <HStack spacing="4">
                <AuthenticatedTemplate>
                  <LoggedInUserMenu />
                </AuthenticatedTemplate>
                <LanguageAndLocaleSelector />
              </HStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
