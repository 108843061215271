import { AclAccessLevelEnum } from '../apiClient/types';

export function isRequiredAccessLevelForAction(accessLevel: AclAccessLevelEnum) {
  switch (accessLevel) {
    case AclAccessLevelEnum.ROOT:
    case AclAccessLevelEnum.ADMIN:
    case AclAccessLevelEnum.USE:
      return true;
    case AclAccessLevelEnum.READ:
    case AclAccessLevelEnum.LIST:
    case AclAccessLevelEnum.NONE:
    default:
      return false;
  }
}
