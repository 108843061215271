import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { Heading, HStack } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { SessionContext } from '../../auth/SessionContext';
import { useShowError } from '../../useShowError';
import { Layout } from '../../layout/Layout';

type FromState = { from: string };
function isFromState(state: unknown): state is FromState {
  return state instanceof Object && 'from' in state;
}

export function LoginPage() {
  const { msalLoginRequest } = useContext(SessionContext);
  const { error } = useMsalAuthentication(InteractionType.Redirect, msalLoginRequest);
  const showError = useShowError();
  const isLoggedIn = useIsAuthenticated();
  const location = useLocation();

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error, showError]);

  if (isLoggedIn) {
    let to;
    if (isFromState(location.state)) {
      to = location.state.from;
    } else {
      to = '/dashboard';
    }
    return <Navigate to={to} />;
  }

  return (
    <Layout>
      <HStack spacing={4}>
        <Heading>
          <FormattedMessage id="loginpage_heading" />
        </Heading>
        {!error && <Spinner color="brand.500" size="sm" />}
      </HStack>
    </Layout>
  );
}
