import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import { FiBox } from 'react-icons/fi';
import { Hardware, HardwareQueue, ReservationStatusEnum } from '../../apiClient/types';
import { HardwareDetailGrid } from './HardwareDetailGrid';
import { ReservationStatusBadge } from './ReservationStatusBadge';
import { QueueStatusBadge } from './QueueStatusBadge';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { isRequiredAccessLevelForAction } from '../../hardware/isRequiredAccessLevelForAction';
import { ConnectionStatusBadge } from '../../hardware/ConnectionStatusBadge';

type Props = {
  hardware: Hardware;
  selectedHardware: Set<string>;
  hardwareQueues: HardwareQueue[] | undefined;
  hardwareQueueStatusMap: Map<any, any>;
  multiSelectEnabled: boolean;
  handleSelectHardware: (hardware: Hardware) => void;
  handleRefresh: () => void;
  loadQueues: () => void;
};

export const HardwarePageAccordionContent = (props: Props) => {
  return (
    <Accordion mt="0" bg="white" allowMultiple>
      <AccordionItem>
        <AccordionButton>
          <Flex w="100%" justifyContent="space-between">
            <Flex p={2}>
              {props.multiSelectEnabled && (
                <Checkbox
                  mr={4}
                  _hover={{ borderColor: 'brand.300' }}
                  disabled={!props.hardware.guard.canQueue}
                  isChecked={props.selectedHardware.has(props.hardware.location)}
                  onChange={() => props.handleSelectHardware(props.hardware)}
                />
              )}
              <Icon as={FiBox} mr={4} mt={1} boxSize={8} color="blue.300" />
              <Box textAlign="left">
                <Heading as="h5" size="sm">
                  {[props.hardware.product.name, props.hardware.name].join(' – ')}
                </Heading>
                <HStack>
                  <Text color="gray.500">
                    {[props.hardware.serialNumber, props.hardware.server.host].join(' - ')}
                  </Text>
                  {!isRequiredAccessLevelForAction(props.hardware.accessLevel) && (
                    <WarningTwoIcon color="orange.300" />
                  )}
                </HStack>
              </Box>
            </Flex>
            <HStack>
              <QueueStatusBadge
                hardware={props.hardware}
                queueStatus={props.hardwareQueueStatusMap.get(props.hardware.location)?.[0]}
              />
              {props.hardware.status.reservation.status !== ReservationStatusEnum.FREE && (
                <ReservationStatusBadge hardware={props.hardware} />
              )}
              <ConnectionStatusBadge hardware={props.hardware} />
            </HStack>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pt={4} pb={12}>
          <HardwareDetailGrid
            hardware={props.hardware}
            hardwareQueues={props.hardwareQueues}
            hasQueues={props.hardwareQueueStatusMap.has(props.hardware.location)}
            refresh={props.handleRefresh}
            refreshQueues={props.loadQueues}
            hardwareQueueStatusMap={props.hardwareQueueStatusMap}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
