export class ApiError extends Error {
  public responseText?: string;

  constructor(message?: string) {
    super(message);
    this.name = 'ApiError';
  }
}

export class InvalidSessionError extends ApiError {
  constructor(message?: string) {
    super(message);
    this.name = 'InvalidSessionError';
  }
}

export const extractedError = (error: Error): string | null => {
  try {
    if (isApiError(error) && error.responseText) {
      return error.responseText;
    } else if (isTypeError(error) && error.message === 'Failed to fetch') {
      return 'Network error: Unable to fetch data. Please check your connection';
    }
  } catch (e) {
    console.error('Error extracting error details', e);
  }

  return error.message || null;
};

export const isApiError = (error: unknown): error is ApiError => {
  return error instanceof ApiError && 'responseText' in error;
};

export const isTypeError = (error: unknown): error is TypeError => {
  return error instanceof TypeError;
};

export const isInvaliSessionError = (error: unknown): error is InvalidSessionError => {
  return error instanceof InvalidSessionError;
};
