import { GroupAdmin, GroupUserAdmin } from '../../apiClient/types';

/**
 * Returns unique users in a realm, sorted 0-9, A-Z.
 * @param groups - list of groups
 */
export const getUniqueUsers = (groups: GroupAdmin[]): GroupUserAdmin[] => {
  const uniqueUsersMap = new Map<string, GroupUserAdmin>();
  groups.forEach((group) => {
    group.users?.forEach((user) => {
      if (!uniqueUsersMap.has(user.id)) {
        uniqueUsersMap.set(user.id, user);
      }
    });
  });

  const uniqueUsersArray = Array.from(uniqueUsersMap.values());
  uniqueUsersArray.sort((a, b) => a.id.localeCompare(b.id));

  return uniqueUsersArray;
};

/**
 * Returns count of groups a user is in.
 * @param groups - list of groups
 */
export const getUserGroupCount = (groups: GroupAdmin[]): Map<string, number> => {
  const userInGroups = new Map<string, number>();
  groups.forEach((group) => {
    group.users?.forEach((user) => {
      const currentCount = userInGroups.get(user.id) || 0;
      userInGroups.set(user.id, currentCount + 1);
    });
  });
  return userInGroups;
};

/**
 * Returns the names of groups a user is in.
 * @param groups - list of groups
 */
export const getUserGroups = (groups: GroupAdmin[]): Map<string, string[]> => {
  const userGroupsMap = new Map<string, string[]>();
  groups.forEach((group) => {
    group.users?.forEach((user) => {
      const currentGroups = userGroupsMap.get(user.id) || [];
      userGroupsMap.set(user.id, [...currentGroups, group.name]);
    });
  });
  return userGroupsMap;
};
