import { Badge, Text } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

type Props = {
  isReserved: boolean | undefined;
  canPerformAction: boolean | undefined;
};

export const HardwareDetailInformationBadge = ({ isReserved, canPerformAction }: Props) => {
  const intl = useIntl();
  let label;

  if (isReserved && canPerformAction) {
    label = intl.formatMessage({ id: 'hardwaredetailbadge_reserved' });
    return (
      <Badge borderRadius="sm" colorScheme="orange" textTransform="none" py={0.5} px={3}>
        <Text>{label}</Text>
      </Badge>
    );
  } else if (!canPerformAction) {
    label = intl.formatMessage({ id: 'hardwaredetailbadge_no_access' });
    return (
      <Badge borderRadius="sm" colorScheme="orange" textTransform="none" py={0.5} px={3}>
        <Text>{label}</Text>
      </Badge>
    );
  }

  return null;
};
