import { FormattedMessage } from 'react-intl';
import { Hardware, ReservationStatusEnum } from '../../apiClient/types';
import { ReservationStatusBadge } from '../hardware/ReservationStatusBadge';
import moment from 'moment';
import { Alert, AlertIcon, Text, HStack, Button, VStack, Stack } from '@chakra-ui/react';
import { AccordionLayout } from './AccordionLayout';
import { isRequiredAccessLevelForAction } from '../../hardware/isRequiredAccessLevelForAction';
import { NavLink } from 'react-router-dom';
import { QueueStatusBadge } from '../hardware/QueueStatusBadge';
import { useIsMobile } from '../../useIsMobile';

type ReservationInformationProps = {
  hardware: Hardware;
};

type ReservationSectionProps = {
  hardware: Hardware;
  isLoadingReserve: boolean;
  isLoadingFree: boolean;
  isLoadingQueue: boolean;
  isReserved: boolean | undefined;
  canPerformAction: boolean | undefined;
  pendingForMe: boolean;
  hasQueues: boolean;
  handleReserve: () => Promise<void>;
  handleFree: () => Promise<void>;
  handleReserveQueue: (queueId: string) => Promise<void>;
  handleRemoveQueue: (queueId: string) => Promise<void>;
  onQueueOpen: () => void;
  queueId: string;
  statusMap: Map<any, any>;
};

const ReservationInformation = ({ hardware }: ReservationInformationProps) => {
  const reservedDate = moment(hardware.status.reservation.reservedDate);
  const isMobile = useIsMobile();

  return (
    <Stack direction={isMobile ? 'column' : 'row'} gap={1} w="100%" pl={2}>
      <VStack align="start">
        <Text fontWeight="bold">
          <FormattedMessage id="hardwaredetailpage_reserved_by" />
        </Text>
        <Text mr={2}>{hardware.status.reservation.reservedBy}</Text>
      </VStack>
      <VStack align="start">
        <Text fontWeight="bold">
          <FormattedMessage id="hardwaredetailpage_date" />
        </Text>
        <Text mr={2}>{reservedDate.isValid() ? reservedDate.format('DD/MM/YYYY HH:mm') : ''}</Text>
      </VStack>
      <VStack align="start">
        <Text fontWeight="bold">
          <FormattedMessage id="hardwaredetailpage_message" />
        </Text>
        <Text>{hardware.status.reservation.message}</Text>
      </VStack>
    </Stack>
  );
};

const ReservationActions = (props: ReservationSectionProps) => {
  return (
    <>
      {!props.isReserved && props.canPerformAction && !props.pendingForMe && (
        <Button size="sm" onClick={props.handleReserve} isLoading={props.isLoadingReserve}>
          <FormattedMessage id="hardwaredetailgrid_reserve" />
        </Button>
      )}
      {!props.isReserved && !props.canPerformAction && (
        <Button size="sm" onClick={props.handleReserve} isLoading={props.isLoadingReserve}>
          <FormattedMessage id="hardwaredetailgrid_reserve" />
        </Button>
      )}
      {props.isReserved && props.canPerformAction && (
        <Button size="sm" onClick={props.onQueueOpen} isLoading={props.isLoadingQueue}>
          <FormattedMessage id="queue_queue" />
        </Button>
      )}
      {!props.isReserved && props.pendingForMe && (
        <>
          <Button
            size="sm"
            onClick={() => props.handleReserveQueue(props.queueId)}
            isLoading={props.isLoadingQueue}
          >
            <FormattedMessage id="queue_reserve_queue" />
          </Button>
          <Button
            size="sm"
            onClick={() => props.handleRemoveQueue(props.queueId)}
            isLoading={props.isLoadingQueue}
          >
            <FormattedMessage id="queue_remove_queue" />
          </Button>
        </>
      )}

      {props.hardware.status.reservation.status === ReservationStatusEnum.RESERVED_BY_ME &&
      props.hardware.guard.canFree &&
      isRequiredAccessLevelForAction(props.hardware.accessLevel) ? (
        <Button size="sm" onClick={props.handleFree} isLoading={props.isLoadingFree}>
          <FormattedMessage id="hardwaredetailgrid_free" />
        </Button>
      ) : null}

      {props.hasQueues && (
        <NavLink to={`/queue/hardware/${props.hardware.location}`}>
          <Button size="sm" variant="outline">
            <FormattedMessage id="queue_heading" />
          </Button>
        </NavLink>
      )}
    </>
  );
};

export const ReservationSection = (props: ReservationSectionProps) => {
  const isMobile = useIsMobile();
  const canReserve = props.hardware.guard.canReserve;

  return (
    <AccordionLayout
      buttonContent={
        <HStack justify="space-between" px={0} w="full" mr={2}>
          <Text fontWeight="bold">
            <FormattedMessage id="hardwaredetailpage_title_reservation" />
          </Text>
          <HStack>
            {props.hasQueues && (
              <QueueStatusBadge
                hardware={props.hardware}
                queueStatus={props.statusMap.get(props.hardware.location)?.[0]}
              />
            )}
            <ReservationStatusBadge hardware={props.hardware} />
          </HStack>
        </HStack>
      }
      panelContent={
        <VStack w="full" py={2}>
          <HStack h="fit-content" w="full" justify="space-between">
            {canReserve ? (
              <HStack w="full">
                <Alert status="info" bg="none" pl={0} py={0}>
                  <AlertIcon />
                  <FormattedMessage id="hardwaredetailpage_no_current_reservation" />
                </Alert>
                {isMobile && <ReservationActions {...props} />}
              </HStack>
            ) : (
              <ReservationInformation hardware={props.hardware} />
            )}
            {!isMobile && <ReservationActions {...props} />}
          </HStack>
          {isMobile && !canReserve && (
            <HStack pt={2} w="full" minW="max-content" justify="end">
              <ReservationActions {...props} />
            </HStack>
          )}
        </VStack>
      }
      buttonStyles={{ borderBottom: 'none' }}
      accordionStyles={{ defaultIndex: 0 }}
      panelStyles={{ px: '6', py: '4' }}
    />
  );
};
