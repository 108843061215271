import { Th, Tooltip } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

type AttributeHeaderProps = {
  localeId: string;
  tooltip?: string;
};

export function AttributeHeader({ localeId, tooltip }: AttributeHeaderProps) {
  return (
    <Th>
      {tooltip ? (
        <Tooltip label={tooltip}>
          <span>
            <FormattedMessage id={localeId} />
          </span>
        </Tooltip>
      ) : (
        <span>
          <FormattedMessage id={localeId} />
        </span>
      )}
    </Th>
  );
}
