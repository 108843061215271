import { useEffect, useContext } from 'react';
import { useQueueHardware } from './useQueueHardware';
import { PermissionsContext } from '../realms/PermissionsProvider';
import { EmptyContent } from '../../layout/common/EmptyContent';
import { Loader } from '../../layout/common/Loader';
import { Layout } from '../../layout/Layout';
import { QueuePageContent } from './QueuePageContent';
import { FormattedMessage } from 'react-intl';

export const QueuePage = () => {
  const { sessionInformation } = useContext(PermissionsContext) || {};
  const { loadHardwareQueues, hardwareQueues, isLoading } = useQueueHardware();

  const isAdmin = sessionInformation?.realmAdmin;

  useEffect(() => {
    loadHardwareQueues(isAdmin ? { loadAllQueues: true } : undefined);
  }, [loadHardwareQueues, isAdmin]);

  let content;
  if (isLoading) {
    content = <Loader label={<FormattedMessage id="queue_loading" />} />;
  } else if (hardwareQueues && hardwareQueues.length > 0) {
    content = (
      <QueuePageContent
        isLoading={isLoading}
        queues={hardwareQueues}
        refresh={() => loadHardwareQueues(isAdmin ? { loadAllQueues: true } : undefined)}
      />
    );
  } else {
    content = (
      <EmptyContent
        heading={<FormattedMessage id="queue_heading" />}
        description={<FormattedMessage id="queue_description" />}
        emptyContentLabel={
          isAdmin ? (
            <FormattedMessage id="queue_no_content_label_admin" />
          ) : (
            <FormattedMessage id="queue_no_content_label" />
          )
        }
      />
    );
  }

  return <Layout>{content}</Layout>;
};
