import { Text, VStack, HStack, Checkbox, FormControl, FormLabel } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { CustomDatePicker } from './CustomDatePicker';

type Props = {
  retired: boolean | undefined;
  onChangeRetirement: (retired: boolean | undefined) => void;
  startDate: Date | null;
  onChangeStartDate: (date: Date | null) => void;
  endDate: Date | null;
  onChangeEndDate: (date: Date | null) => void;
  error: boolean;
};

type RowProps = {
  label?: string | React.ReactElement;
  children: React.ReactNode;
  isLastRow?: boolean;
};

export const UserRetirementFormControl = ({
  retired,
  onChangeRetirement,
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
  error,
}: Props) => {
  return (
    <FormControl isInvalid={error}>
      <FormLabel fontWeight="bold" textTransform="uppercase" fontSize="14px" htmlFor="retirement">
        <FormattedMessage id="realm_add_user_header_retirement" />
      </FormLabel>
      <VStack w="full" justify="space-between" pt={2}>
        <RetirementSectionRow label={<FormattedMessage id="realm_add_user_retired" />}>
          <Checkbox
            isChecked={retired}
            borderColor="gray.400"
            onChange={(e) => onChangeRetirement(e.target.checked)}
          />
        </RetirementSectionRow>
        <RetirementSectionRow>
          <CustomDatePicker
            label={<FormattedMessage id="realm_add_user_validFrom" />}
            labelWidth={'100%'}
            labelPadding={0}
            date={startDate}
            onChangeDate={onChangeStartDate}
          />
        </RetirementSectionRow>
        <RetirementSectionRow isLastRow>
          <CustomDatePicker
            label={<FormattedMessage id="realm_add_user_validUntil" />}
            labelWidth={'100%'}
            labelPadding={0}
            date={endDate}
            onChangeDate={onChangeEndDate}
          />
        </RetirementSectionRow>
      </VStack>
    </FormControl>
  );
};

const RetirementSectionRow = ({ label, children, isLastRow }: RowProps) => {
  return (
    <HStack
      w="full"
      justify="space-between"
      borderBottom={isLastRow ? 'none' : '1px solid'}
      borderColor="gray.200"
      pb={isLastRow ? 0 : 2}
    >
      {label && <Text>{label}</Text>}
      {children}
    </HStack>
  );
};
