import { FormattedMessage } from 'react-intl';
import { Action, ActionRun, Hardware, HardwareDetail } from '../../apiClient/types';
import { HardwareActionContainer } from './HardwareActionContainer';
import { Alert, AlertIcon, Text, Link, Flex, HStack, Box, VStack } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { AccordionLayout } from './AccordionLayout';

type ButtonProps = {
  onHistoryOpen: () => void;
  hardwareDetail: HardwareDetail;
};

type ActionSectionProps = {
  hardware: Hardware;
  handleStartAction: any;
  isLoadingAction: boolean;
  onHistoryOpen: () => void;
  hardwareDetail: HardwareDetail;
};

const ButtonContentWithoutActions = (
  <HStack w="full">
    <Text fontWeight="bold">
      <FormattedMessage id="hardwaredetailpage_title_actions" />
    </Text>
  </HStack>
);

const ButtonContentWithActions = ({ hardwareDetail, onHistoryOpen }: ButtonProps) => (
  <HStack w="full">
    <Flex w="50%">
      <Text fontWeight="bold">
        <FormattedMessage id="hardwaredetailpage_title_actions" />
      </Text>
    </Flex>
    <Flex w="25%">
      <Text fontWeight="bold">
        <FormattedMessage id="hardwaredetailpage_title_actions_previous" />
      </Text>
    </Flex>
    {hardwareDetail.actionRuns && hardwareDetail.actionRuns.length > 0 && (
      <Link
        fontWeight="bold"
        onClick={(e) => {
          e.stopPropagation();
          onHistoryOpen();
        }}
      >
        <ExternalLinkIcon mb="5px" mr="7.5px" />
        <FormattedMessage id="hardwaredetailpage_title_actions_history" />
      </Link>
    )}
  </HStack>
);

export const ActionSection = ({
  hardware,
  handleStartAction,
  isLoadingAction,
  onHistoryOpen,
  hardwareDetail,
}: ActionSectionProps) => {
  const actionRunsById: Record<string, ActionRun> = {};

  if (hardwareDetail && hardwareDetail.actionRuns) {
    const sortedActionRuns = [...hardwareDetail.actionRuns].sort(
      (a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()
    );
    sortedActionRuns.forEach((run) => {
      actionRunsById[run.action.id] = run;
    });
  }

  return (
    <>
      {hardware.actions ? (
        <AccordionLayout
          buttonContent={
            <ButtonContentWithActions
              hardwareDetail={hardwareDetail}
              onHistoryOpen={onHistoryOpen}
            />
          }
          panelContent={
            <VStack w="full" align="start" spacing={0}>
              {hardware.actions.map((action: Action) => (
                <HardwareActionContainer
                  key={action.id}
                  hardwareAction={action}
                  actionRun={actionRunsById[action.id]}
                  isLoading={isLoadingAction}
                  onStartAction={handleStartAction}
                  isReserved={hardware.guard.canReserve}
                  hardware={hardware}
                />
              ))}
            </VStack>
          }
          panelStyles={{ borderBottom: '1px solid lightgray' }}
          accordionStyles={{ defaultIndex: 0 }}
        />
      ) : (
        <AccordionLayout
          buttonContent={ButtonContentWithoutActions}
          panelContent={
            <Box h="40px">
              <Alert status="info" bg="none" py={2} pl={0}>
                <AlertIcon />
                <FormattedMessage id="hardwaredetailpage_no_actions" />
              </Alert>
            </Box>
          }
          buttonStyles={{ mt: '-1px' }}
          panelStyles={{ borderBottom: '1px solid lightgray', px: '6', py: '4' }}
        />
      )}
    </>
  );
};
