import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  HStack,
  LinkBox,
  LinkOverlay,
  VStack,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

export function AdministrationAccordion() {
  return (
    <Accordion bg="white" allowMultiple>
      <AccordionItem border={0}>
        <AccordionButton textAlign="left">
          <Box py={6} px={4}>
            <HStack>
              <Heading as="h4" size="md" mb={2}>
                <FormattedMessage id="dashboardpage_administration_heading" />
              </Heading>
              <AccordionIcon />
            </HStack>
            <FormattedMessage id="dashboardpage_administration_description" />
          </Box>
        </AccordionButton>
        <AccordionPanel p={0}>
          <VStack align="stretch" spacing={0}>
            <LinkBox _hover={{ backgroundColor: 'blackAlpha.50' }}>
              <Box py={4} px={8}>
                <Heading as="h4" size="md" mb={2}>
                  <LinkOverlay target="_blank" href="/sys/admin">
                    <FormattedMessage id="dashboardpage_system_administration_heading" />
                  </LinkOverlay>
                </Heading>
                <FormattedMessage id="dashboardpage_system_administration_description" />
              </Box>
            </LinkBox>
            <LinkBox _hover={{ backgroundColor: 'blackAlpha.50' }}>
              <Box pt={4} pb={4} px={8}>
                <Heading as="h4" size="md" mb={2}>
                  <LinkOverlay as={RouterLink} to="/realm">
                    <FormattedMessage id="dashboardpage_realm_management_heading" />
                  </LinkOverlay>
                </Heading>
                <FormattedMessage id="dashboardpage_realm_management_description" />
              </Box>
            </LinkBox>
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
