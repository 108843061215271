import { useEffect } from 'react';
import { Box, Center, HStack, IconButton, Spinner, useDisclosure } from '@chakra-ui/react';
import { Layout } from '../../layout/Layout';
import { PageHeading } from '../../layout/PageHeading';
import { useQueueHardware } from './useQueueHardware';
import { useParams } from 'react-router-dom';
import { RepeatIcon } from '@chakra-ui/icons';
import { BackButton } from '../../layout/BackButton';
import { EmptyContent } from '../../layout/common/EmptyContent';
import { QueueStatusBadgeDescriptionModal } from './QueueStatusBadgeDescriptionModal';
import { SingleQueueInformationSection } from './SingleQueueInformationSection';
import { SingleQueueHardwareSection } from './SingleQueueHardwareSection';
import { FormattedMessage, useIntl } from 'react-intl';

export const SingleQueueDetailPage = () => {
  const { isLoading, loadSingleHardwareQueue, queue } = useQueueHardware();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { queueId } = useParams();
  if (!queueId) {
    throw new Error('Component can only be used as a route element with the required params.');
  }

  const intl = useIntl();

  let content;
  if (isLoading || !queue) {
    content = (
      <Center>
        <Spinner />
        <FormattedMessage id="commons_loading" />
      </Center>
    );
  } else if (!queue) {
    content = (
      <EmptyContent
        emptyContentLabel={<FormattedMessage id="queue_single_no_content_label" />}
        hasNavigation
        includesHeading={false}
      />
    );
  } else {
    content = (
      <>
        <SingleQueueInformationSection queue={queue} onOpen={onOpen} />
        <SingleQueueHardwareSection queue={queue} />
      </>
    );
  }

  useEffect(() => {
    loadSingleHardwareQueue(queueId);
  }, [loadSingleHardwareQueue, queueId]);

  return (
    <Layout>
      <HStack align="top">
        <PageHeading
          heading={<FormattedMessage id="queue_single_heading" />}
          description={<FormattedMessage id="queue_single_description" values={{ queueId }} />}
        />
        <IconButton
          isRound
          isLoading={isLoading}
          variant="ghost"
          onClick={() => loadSingleHardwareQueue(queueId)}
          icon={<RepeatIcon />}
          aria-label={intl.formatMessage({ id: 'commons_refresh' })}
        />
      </HStack>
      <HStack pt={2} align="start">
        <BackButton />
      </HStack>

      <Box bg="white" w="full">
        {content}
      </Box>
      <QueueStatusBadgeDescriptionModal isOpen={isOpen} onClose={onClose} />
    </Layout>
  );
};
