import { useIntl } from 'react-intl';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { RealmSession } from '../../apiClient/types';

export function useLoadGroups() {
  const queryClient = useQueryClient();
  const { getSessionInformation, updateSessionGroups } = useApiClient();
  const showError = useShowError();
  const intl = useIntl();

  const {
    data: sessionData,
    refetch,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryFn: () => getSessionInformation(),
    queryKey: ['sessionGroups'],
  });

  useEffect(() => {
    if (isError) {
      showError(error.message, {
        description: intl.formatMessage({ id: 'session_groups_load_groups_error' }),
      });
    }
  }, [isError, error, showError, intl]);

  const realms = useMemo(() => {
    return sessionData ? sessionData.realms : [];
  }, [sessionData]);

  const groups = useMemo(() => {
    return realms ? realms.flatMap((realm: RealmSession) => realm.groups) : [];
  }, [realms]);

  const mutation = useMutation({
    mutationFn: (activeGroupIds: string[]) => updateSessionGroups(activeGroupIds),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sessionGroups'] });
      queryClient.invalidateQueries({ queryKey: ['realms'] });
      queryClient.invalidateQueries({ queryKey: ['realm'] });
    },
    onError: (error: Error) => {
      showError(error, {
        description: intl.formatMessage({ id: 'session_load_groups_error ' }),
      });
    },
  });

  const setSessionGroups = async (activeGroupIds: string[]) => {
    await mutation.mutateAsync(activeGroupIds);
  };

  return { isLoading, realms, groups, setSessionGroups, refetch };
}
