import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Box,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select, Button } from '@chakra-ui/react';
import { Action, ActionOption } from '../../apiClient/types';
import { getHardwareActionMessages } from '../../hardware/getHardwareActionMessages';
import { isRequiredAccessLevelForAction } from '../../hardware/isRequiredAccessLevelForAction';

type Props = {
  hardwareAction: Action;
  isLoading: boolean;
  selectedOption: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onStartAction: () => void;
  onClose: () => void;
  isOpen: boolean;
  isRequiredAccessLevelForAction: boolean;
  isReserved: boolean;
};

export const HardwareActionOptionsModal = (props: Props) => {
  const intl = useIntl();
  const hardwareActionMessages = getHardwareActionMessages(props.hardwareAction.id, intl);
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="hardwareactionmodal_caption" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {props.hardwareAction.options ? (
            <>
              <Box mb={4}>
                <FormattedMessage
                  id="hardwareaction_execute_options"
                  values={{ actionName: props.hardwareAction.name }}
                />
              </Box>
              <Select
                placeholder={intl.formatMessage({ id: 'commons_options' })}
                onChange={props.onChange}
                disabled={!isRequiredAccessLevelForAction(props.hardwareAction.accessLevel)}
              >
                {props.hardwareAction.options.map(({ name, id }: ActionOption) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </>
          ) : (
            <Box mb={2}>
              <FormattedMessage
                id="hardwareaction_execute"
                values={{ actionName: props.hardwareAction.name }}
              />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            onClick={() => props.onStartAction()}
            variant="solid"
            isLoading={props.isLoading}
            disabled={
              !isRequiredAccessLevelForAction(props.hardwareAction.accessLevel) ||
              props.isLoading ||
              props.isReserved ||
              (props.hardwareAction.options && !props.selectedOption)
            }
          >
            {hardwareActionMessages.execute}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
