import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { HardwareQueue } from '../../apiClient/types';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';

export function useQueueHardware() {
  const [hardwareQueues, setHardwareQueues] = useState<HardwareQueue[]>();
  const [queueResult, setQueueResult] = useState<HardwareQueue>();
  const [isLoading, setLoading] = useState(false);
  const {
    hardwareQueueFromSearch,
    hardwareQueueFromSelection,
    getHardwareQueue,
    listHardwareQueues,
    removeHardwareQueue,
    reserveHardwareQueue,
  } = useApiClient();
  const showError = useShowError();
  const intl = useIntl();

  const queueFromSearch = useCallback(
    async function (artifactLocation: string, message?: string) {
      setLoading(true);
      try {
        await hardwareQueueFromSearch(artifactLocation, message);
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({
            id: 'queue_from_search_error',
          }),
        });
      }
      setLoading(false);
    },
    [showError, hardwareQueueFromSearch, intl]
  );

  const queueFromSelection = useCallback(
    async function (hardwareLocation: string[], message?: string) {
      setLoading(true);
      try {
        await hardwareQueueFromSelection(hardwareLocation, message);
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({
            id: 'queue_from_selection_error',
          }),
        });
      }
      setLoading(false);
    },
    [showError, hardwareQueueFromSelection, intl]
  );

  const loadSingleHardwareQueue = useCallback(
    async function (queueId: string) {
      setLoading(true);
      try {
        setQueueResult(await getHardwareQueue(queueId));
        setLoading(false);
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'queue_load_single_error' }),
        });
      }
      setLoading(false);
    },
    [showError, getHardwareQueue, intl]
  );

  const loadHardwareQueues = useCallback(
    async function ({ loadAllQueues } = { loadAllQueues: false }) {
      setLoading(true);
      try {
        setHardwareQueues(await listHardwareQueues(loadAllQueues === true ? true : undefined));
        setLoading(false);
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'queue_load_error' }),
        });
      }
      setLoading(false);
    },
    [showError, listHardwareQueues, intl]
  );

  const reloadHardwareQueue = useCallback(
    async function () {
      await loadHardwareQueues();
    },
    [loadHardwareQueues]
  );

  const deleteHardwareQueue = useCallback(
    async function (queueId: string) {
      setLoading(true);
      try {
        await removeHardwareQueue(queueId);
        setLoading(false);
        await reloadHardwareQueue();
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'queue_remove_error' }),
        });
      }
      setLoading(false);
    },
    [showError, removeHardwareQueue, reloadHardwareQueue, intl]
  );

  const reserveQueue = useCallback(
    async function (queueId: string) {
      setLoading(true);
      try {
        await reserveHardwareQueue(queueId);
        setLoading(false);
        await reloadHardwareQueue();
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'queue_reserve_error' }),
        });
      }
      setLoading(false);
    },
    [showError, reserveHardwareQueue, reloadHardwareQueue, intl]
  );

  return {
    hardwareQueues,
    isLoading,
    queueFromSearch,
    queueFromSelection,
    loadSingleHardwareQueue,
    loadHardwareQueues,
    deleteHardwareQueue,
    reserveQueue,
    queue: queueResult,
  };
}
