import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { SingleQueueLayout } from './SingleQueueLayout';
import { SingleQueueHardwareItem } from './SingleQueueHardwareItem';
import { HardwareQueue } from '../../apiClient/types';
import { FormattedMessage } from 'react-intl';

const removeBorderForLastRow = {
  '& tr:last-of-type': {
    '& td': {
      borderBottomWidth: 0,
    },
  },
};

export const SingleQueueHardwareSection = ({ queue }: { queue: HardwareQueue }) => {
  return (
    <SingleQueueLayout title={<FormattedMessage id="queue_single_table_hardware_heading" />}>
      <Table colorScheme="gray" bg="white">
        <Thead>
          <Tr>
            <Th>
              <FormattedMessage id="queue_single_table_hardware_name" />
            </Th>
            <Th>
              <FormattedMessage id="queue_single_table_hardware_location" />
            </Th>
            <Th>
              <FormattedMessage id="queue_single_table_hardware_queues" />
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody css={removeBorderForLastRow}>
          {queue.hardware?.map((hardware) => (
            <SingleQueueHardwareItem key={hardware.location} hardware={hardware} />
          ))}
        </Tbody>
      </Table>
    </SingleQueueLayout>
  );
};
