import { FormattedMessage, useIntl } from 'react-intl';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Divider,
  Radio,
  Tooltip,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';

type Props = {
  isExcluding: boolean;
  handleToggleExclude: () => void;
};

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  isExcluding: boolean;
  handleToggleExclude: () => void;
};

export function HardwareFilterInputDrawer({ isExcluding, handleToggleExclude }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const intl = useIntl();

  return (
    <>
      <Tooltip hasArrow label={intl.formatMessage({ id: 'hardwarefilter_more_options' })}>
        <IconButton
          variant="ghost"
          color="gray.600"
          icon={<BsThreeDotsVertical />}
          aria-label="More options"
          onClick={onOpen}
        />
      </Tooltip>
      <FilterInputDrawer
        isOpen={isOpen}
        onClose={onClose}
        isExcluding={isExcluding}
        handleToggleExclude={handleToggleExclude}
      />
    </>
  );
}

export function FilterInputDrawer({
  isOpen,
  onClose,
  isExcluding,
  handleToggleExclude,
}: DrawerProps) {
  return (
    <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent bg="brand.500" pb={4}>
          <DrawerCloseButton color="white" mt="2" mr="2" />
          <DrawerHeader color="white">
            <FormattedMessage id="hardwarefilter_keyword_behavior" />
          </DrawerHeader>
          <DrawerBody color="white">
            <VStack align="start" spacing="4">
              <Divider />
              <Radio
                colorScheme="whiteAlpha"
                isChecked={!isExcluding}
                onChange={handleToggleExclude}
              >
                <FormattedMessage id="hardwarefilter_include" />
              </Radio>
              <Radio
                colorScheme="whiteAlpha"
                isChecked={isExcluding}
                onChange={handleToggleExclude}
              >
                <FormattedMessage id="hardwarefilter_exclude" />
              </Radio>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
