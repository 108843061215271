import { useEffect } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { ProtocolMode } from '@azure/msal-browser';
import { Text, Heading, VStack, HStack } from '@chakra-ui/layout';
import { Layout } from '../../layout/Layout';
import { Spinner } from '@chakra-ui/spinner';
import { FormattedMessage } from 'react-intl';

export function LogoutPage() {
  const { instance } = useMsal();
  const isLoggedIn = useIsAuthenticated();

  useEffect(() => {
    if (!isLoggedIn) return;

    const { auth } = instance.getConfiguration();
    if (auth.protocolMode === ProtocolMode.OIDC) {
      // OIDC logout using Keycloak requires either `client_id` or `id_token_hint`
      // See: https://www.keycloak.org/docs/latest/server_admin/#_oidc-logout
      instance.logoutRedirect({ extraQueryParameters: { client_id: auth.clientId } });
    } else {
      instance.logoutRedirect();
    }
  }, [isLoggedIn, instance]);

  return (
    <Layout>
      <VStack align="start">
        <HStack spacing={4}>
          <Heading>
            <FormattedMessage id="logoutpage_heading" />
          </Heading>
          <AuthenticatedTemplate>
            <Spinner color="brand.500" size="sm" />
          </AuthenticatedTemplate>
        </HStack>
        <UnauthenticatedTemplate>
          <Text>
            <FormattedMessage id="logoutpage_description" />
          </Text>
        </UnauthenticatedTemplate>
      </VStack>
    </Layout>
  );
}
