import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { RealmSectionLayout } from './RealmSectionLayout';
import { RealmAdmin } from '../../apiClient/types';
import { FormattedMessage } from 'react-intl';

export const InformationSection = ({
  realmData,
  isMobile,
}: {
  realmData: RealmAdmin;
  isMobile: boolean | undefined;
}) => {
  const isDesktop = !isMobile;

  return (
    <RealmSectionLayout title={<FormattedMessage id="realm_table_header_information" />}>
      <Table colorScheme="gray" bg="white">
        <Thead>
          <Tr>
            <Th>
              <FormattedMessage id="realm_table_header_name" />
            </Th>
            <Th>
              <FormattedMessage id="realm_table_header_id" />
            </Th>
            {isDesktop && (
              <Th>
                <FormattedMessage id="realms_common_description" />
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{realmData.name}</Td>
            <Td>{realmData.id}</Td>
            {isDesktop && (
              <Td>
                {realmData.description ? (
                  realmData.description
                ) : (
                  <FormattedMessage id="realms_no_description" />
                )}
              </Td>
            )}
          </Tr>
        </Tbody>
      </Table>
      {isMobile && (
        <Table colorScheme="gray" bg="white">
          <Thead>
            <Tr>
              <Th>
                <FormattedMessage id="realms_common_description" />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                {realmData.description ? (
                  realmData.description
                ) : (
                  <FormattedMessage id="realms_no_description" />
                )}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      )}
    </RealmSectionLayout>
  );
};
