import { ColorModeScript } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { loadConfig } from './config/loadConfig';
import { theme } from './theme';
import reportWebVitals from './reportWebVitals';

loadConfig().then((config) => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App config={config} />
    </>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
