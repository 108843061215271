import * as React from 'react';
import { ApiClient } from './types';

export const ApiClientContext = React.createContext<ApiClient>({
  freeHardware: Promise.reject,
  listProxyManagers: Promise.reject,
  listHardware: Promise.reject,
  reserveHardware: Promise.reject,
  selectHardware: Promise.reject,
  executeHardwareAction: Promise.reject,
  getSingleHardware: Promise.reject,
  getHardwareActionResult: Promise.reject,
  listArtifacts: Promise.reject,
  downloadProxyManager: Promise.reject,
  downloadArtifact: Promise.reject,
  authenticateWebSocket: Promise.reject,
  connectWebSocket: undefined,
  getProxyParametersDefinition: Promise.reject,
  getRealmInformation: Promise.reject,
  editUserGroups: Promise.reject,
  removeFromGroup: Promise.reject,
  listRealms: Promise.reject,
  addUsersToRealm: Promise.reject,
  setRealmUserGroups: Promise.reject,
  getSessionInformation: Promise.reject,
  updateSessionGroups: Promise.reject,
  listSearchArtifacts: Promise.reject,
  hardwareQueueFromSearch: Promise.reject,
  hardwareQueueFromSelection: Promise.reject,
  getHardwareQueue: Promise.reject,
  listHardwareQueues: Promise.reject,
  removeHardwareQueue: Promise.reject,
  reserveHardwareQueue: Promise.reject,
});
