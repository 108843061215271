import { useState } from 'react';
import { IntlProvider } from 'react-intl';
import moment, { Moment } from 'moment';
import { LOCALES } from './locales';
import localeEnGb from './en-gb.json';
import localeEnUs from './en-us.json';
import localeFiFi from './fi-fi.json';
import { LocaleContext } from './LocaleContext';
import { getInitialLocale } from './getInitialLocale';

const messages = new Map();
messages.set(LOCALES.FINNISH, localeFiFi);
messages.set(LOCALES.ENGLISH_US, localeEnUs);
messages.set(LOCALES.ENGLISH_UK, localeEnGb);

export function LocaleIntlProvider(props: { children: React.ReactNode }) {
  const [locale, setLocale] = useState(getInitialLocale());

  function handleChangeLocale(locale: string) {
    setLocale(locale);
    localStorage.setItem('locale', locale);
  }

  function handleFormatDate(date: string | number | Moment) {
    switch (locale) {
      case 'fi-FI':
        return moment(date).format('DD.MM.YYYY h.mm');
      case 'en-US':
        return moment(date).format('MM/DD/YYYY h:mm A');
      case 'en-GB':
      default:
        return moment(date).format('DD/MM/YYYY h:mm a');
    }
  }
  return (
    <LocaleContext.Provider
      value={{ locale, onChangeLocale: handleChangeLocale, formatDate: handleFormatDate }}
    >
      <IntlProvider
        messages={messages.get(locale)}
        locale={locale}
        defaultLocale={LOCALES.ENGLISH_UK}
      >
        {props.children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}
