import { Text, Tooltip } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { GroupSession } from '../../apiClient/types';

type Props = {
  item: GroupSession;
  status: string;
};

export const groupValueHelper = ({ item, status }: Props) => {
  const isActiveStatus = status === 'active';
  const isDefault = item.activeDefault === isActiveStatus;

  if (isDefault) {
    const tooltipId = isActiveStatus
      ? 'session_groups_modal_default_active'
      : 'session_groups_modal_default_inactive';
    const messageId = isActiveStatus
      ? 'session_groups_modal_active'
      : 'session_groups_modal_inactive';

    return (
      <Tooltip label={<FormattedMessage id={tooltipId} />}>
        <Text borderBottom="1px dashed" borderColor="gray.400">
          <FormattedMessage id={messageId} />
        </Text>
      </Tooltip>
    );
  }

  return <FormattedMessage id={`session_groups_modal_${status}`} />;
};
