import { ConnectionSection } from './ConnectionSection';
import { ReservationSection } from './ReservationSection';
import { ActionSection } from './ActionSection';
import { ProxySection } from './ProxySection';
import { HardwareActionModal } from './HardwareActionModal';
import { useHardwareAction } from './useHardwareAction';
import { VStack, Box, useDisclosure } from '@chakra-ui/react';
import { AttributesSection } from './AttributesSection';
import { ActionHistoryModal } from './ActionHistoryModal';
import { Hardware, HardwareDetail, HardwareQueue } from '../../apiClient/types';
import { useReserveHardware } from '../../hardware/useReserveHardware';
import { useFreeHardware } from '../../hardware/useFreeHardware';
import { ConnectModal } from '../../hardware/ConnectModal';
import { getQueueIdForPendingForMe, isPendingForMe } from '../../hardware/getHardwareUtils';
import { useQueueHardware } from '../hardwareQueue/useQueueHardware';
import { QueueConfirmationModal } from '../hardwareQueue/QueueConfirmationModal';
import { useState } from 'react';

type Props = {
  hardware: Hardware;
  hardwareDetail: HardwareDetail;
  refresh: () => Promise<void>;
  statusMap: Map<any, any>;
  queues: HardwareQueue[] | undefined;
  canPerformAction: boolean | undefined;
  isReserved: boolean | undefined;
};

export function HardwareDetailContent({
  hardware,
  hardwareDetail,
  refresh,
  statusMap,
  queues,
  canPerformAction,
  isReserved,
}: Props) {
  const [message, setMessage] = useState<string | undefined>(undefined);

  const connectModalDisclosure = useDisclosure();
  const actionHistoryModalDisclosure = useDisclosure();
  const queueModalDisclosure = useDisclosure();

  const { handleReserve, isLoading: isLoadingReserve } = useReserveHardware(hardware, refresh);
  const { handleFree, isLoading: isLoadingFree } = useFreeHardware(hardware, refresh);
  const {
    reserveQueue,
    deleteHardwareQueue,
    isLoading: isLoadingQueue,
    queueFromSelection,
  } = useQueueHardware();
  const {
    handleStartAction,
    isLoading: isLoadingAction,
    actionResult,
    handleClearActionResult,
  } = useHardwareAction(hardware);

  const pendingForMe = isPendingForMe(hardware, statusMap);
  const pendingQueueId = getQueueIdForPendingForMe(hardware, queues);
  const hasQueues = statusMap.has(hardware.location);

  const handleQueue = async () => {
    await queueFromSelection([hardware.location], message);
    queueModalDisclosure.onClose();
    refresh();
  };

  const handleReserveQueue = async (queueId: string) => {
    await reserveQueue(queueId);
    refresh();
  };

  const handleRemoveQueue = async (queueId: string) => {
    await deleteHardwareQueue(queueId);
    refresh();
  };

  return (
    <>
      <Box background={'white'}>
        <VStack w="full" spacing="-1px">
          <AttributesSection hardware={hardware} />
          <ConnectionSection hardware={hardware} />
          <ReservationSection
            hardware={hardware}
            isReserved={isReserved}
            canPerformAction={canPerformAction}
            isLoadingReserve={isLoadingReserve}
            isLoadingQueue={isLoadingQueue}
            isLoadingFree={isLoadingFree}
            pendingForMe={pendingForMe}
            hasQueues={hasQueues}
            handleFree={handleFree}
            handleReserve={handleReserve}
            handleRemoveQueue={handleRemoveQueue}
            handleReserveQueue={handleReserveQueue}
            onQueueOpen={queueModalDisclosure.onOpen}
            queueId={pendingQueueId}
            statusMap={statusMap}
          />
          <ProxySection
            hardware={hardware}
            connectModalDisclosure={connectModalDisclosure}
            handleFree={handleFree}
            isLoadingFree={isLoadingFree}
          />
          <ActionSection
            hardware={hardware}
            hardwareDetail={hardwareDetail}
            handleStartAction={handleStartAction}
            isLoadingAction={isLoadingAction}
            onHistoryOpen={actionHistoryModalDisclosure.onOpen}
          />
        </VStack>
      </Box>

      <ActionHistoryModal {...actionHistoryModalDisclosure} hardware={hardware} />

      {actionResult && (
        <HardwareActionModal
          onClose={handleClearActionResult}
          result={actionResult}
          hardware={hardware}
          refresh={refresh}
        />
      )}
      {connectModalDisclosure.isOpen && (
        <ConnectModal {...connectModalDisclosure} hardware={hardware} refresh={refresh} />
      )}
      {queueModalDisclosure.isOpen && (
        <QueueConfirmationModal
          isOpen={queueModalDisclosure.isOpen}
          onOpen={queueModalDisclosure.onOpen}
          onClose={queueModalDisclosure.onClose}
          setMessage={setMessage}
          handleConfirmClick={handleQueue}
        />
      )}
    </>
  );
}
