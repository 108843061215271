import { HardwareFilterSelection } from './HardwareFilterSelection';
import { HardwareFilterInput } from './HardwareFilterInput';
import { HStack, Tag, TagCloseButton, TagLabel, VStack } from '@chakra-ui/react';

type Props = {
  includedKeywords: string[];
  excludedKeywords: string[];
  inputSearchString: string;
  selectedFilter: string;
  handleSearchChange: (search: string, isExclude: boolean) => void;
  handleSelectFilter: (title: string | null) => void;
  onChangeSelectedFilter: (filter: string) => void;
  onChangeKeywordFilters: (keyword: string) => void;
};

export const HardwareFilter = (props: Props) => {
  return (
    <VStack w="full" align="start" spacing="0">
      <HStack w="100%" justify="start" py={2}>
        <HardwareFilterSelection
          selectedFilter={props.selectedFilter}
          onSelect={props.handleSelectFilter}
          onChangeSelectedFilter={props.onChangeSelectedFilter}
        />
        <HardwareFilterInput
          inputSearchString={props.inputSearchString}
          onSearchChange={(search, isExcluding) => props.handleSearchChange(search, isExcluding)}
        />
      </HStack>
      <HStack>
        {props.includedKeywords.map((keyword, index) => (
          <Tag key={index} borderRadius="md" variant="solid" size="lg">
            <TagLabel>{keyword}</TagLabel>
            <TagCloseButton onClick={() => props.onChangeKeywordFilters(keyword)} />
          </Tag>
        ))}
        {props.excludedKeywords.map((keyword, index) => (
          <Tag key={index} borderRadius="md" variant="solid" size="lg" colorScheme="red">
            <TagLabel>{keyword}</TagLabel>
            <TagCloseButton onClick={() => props.onChangeKeywordFilters(keyword)} />
          </Tag>
        ))}
      </HStack>
    </VStack>
  );
};
