import {
  Checkbox,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { GroupAdmin } from '../../apiClient/types';

type Props = {
  groups: GroupAdmin[];
  onGroupChange: (groupId: string, isChecked: boolean) => void;
  error: boolean;
};

export const UserGroupFormControl = ({ groups, onGroupChange, error }: Props) => {
  return (
    <FormControl isInvalid={error}>
      <FormLabel fontWeight="bold" textTransform="uppercase" fontSize="14px" htmlFor="groupIds">
        <FormattedMessage id="realm_add_user_header_groups" />
      </FormLabel>
      <Box
        w="full"
        border="2px solid"
        borderColor={error ? 'red.500' : 'white'}
        borderRadius="lg"
        p={1}
      >
        <Table colorScheme="gray" bg="white">
          <Thead>
            <Tr>
              <Th />
              <Th>
                <FormattedMessage id="realms_common_group" />
              </Th>
              <Th>
                <FormattedMessage id="realms_common_description" />
              </Th>
              <Th>
                <FormattedMessage id="realm_add_user_admin" />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {groups.map((group) => (
              <Tr key={group.id}>
                <Td>
                  <Checkbox onChange={(e) => onGroupChange(group.id, e.target.checked)} />
                </Td>
                <Td>{group.name}</Td>
                <Td>{group.description}</Td>
                <Td color={group.admin ? 'inherit' : 'gray.300'}>
                  {group.admin ? group.admin : <FormattedMessage id="realms_common_none" />}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </FormControl>
  );
};
