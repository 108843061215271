import React, { useState, useCallback } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { Hardware, HardwareQueue } from '../../apiClient/types';
import { HardwarePageAccordionHeader } from './HardwarePageAccordionHeader';
import { QueueConfirmationModal } from '../hardwareQueue/QueueConfirmationModal';
import { HardwarePageAccordionQueueActions } from './HardwarePageAccordionQueueActions';
import { HardwarePageAccordionContent } from './HardwarePageAccordionContent';
import { useQueueHardware } from '../hardwareQueue/useQueueHardware';

type Props = {
  displayedHardwares: Hardware[];
  orderedHardware: boolean;
  hardwareQueues: HardwareQueue[] | undefined;
  hardwareQueueStatusMap: Map<any, any>;
  isLoading: boolean;
  artifactLocation: string | null;
  handleRefresh: () => void;
  loadQueues: () => void;
  handleQueueCreation: (hardwareLocations: string[], message?: string) => Promise<void>;
  onChangeOrderedHardware: (orderByReservation: boolean) => void;
  shouldShowQueueOptions: () => boolean;
};

export const HardwarePageAccordion = (props: Props) => {
  const [selectedHardware, setSelectedHardware] = useState(new Set<string>());
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [multiSelectEnabled, setMultiSelectEnabled] = useState(false);
  const { queueFromSearch } = useQueueHardware();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toggleSelectAllForQueueing = () => {
    if (selectAll) {
      setSelectedHardware(new Set());
    } else {
      const newSelectedHardware = new Set<string>();
      props.displayedHardwares.forEach((hardware) => {
        if (hardware.guard.canQueue) {
          newSelectedHardware.add(hardware.location);
        }
      });
      setSelectedHardware(newSelectedHardware);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectHardware = (hardware: Hardware) => {
    const newSelectedHardware = new Set(selectedHardware);
    if (newSelectedHardware.has(hardware.location)) {
      newSelectedHardware.delete(hardware.location);
    } else {
      newSelectedHardware.add(hardware.location);
    }
    setSelectedHardware(newSelectedHardware);
  };

  const handleQueueToSelect = async () => {
    let hardwareLocations: string[] = [];
    if (selectedHardware.size > 0) {
      hardwareLocations = Array.from(selectedHardware);
    }

    await props.handleQueueCreation(hardwareLocations, message);
    onClose();
    setSelectAll(false);
    setSelectedHardware(new Set());
  };

  const handleQueueToSearch = async () => {
    if (props.artifactLocation) {
      await queueFromSearch(props.artifactLocation, message);
      onClose();
    }
  };

  const clearMultiSelection = useCallback(() => {
    if (!multiSelectEnabled) {
      setSelectAll(false);
      setSelectedHardware(new Set());
    }
  }, [multiSelectEnabled]);

  const canQueue = props.displayedHardwares.some((hardware) => hardware.guard.canQueue);
  const canReserve = props.displayedHardwares.some((hardware) => hardware.guard.canReserve);

  return (
    <Box mt="0">
      <HardwarePageAccordionHeader
        displayedHardwares={props.displayedHardwares}
        orderedHardware={props.orderedHardware}
        selectAll={selectAll}
        multiSelectEnabled={multiSelectEnabled}
        onChangeMultiSelectEnabled={setMultiSelectEnabled}
        onChangeOrderedHardware={props.onChangeOrderedHardware}
        onToggleSelectAll={toggleSelectAllForQueueing}
        clearMultiSelection={clearMultiSelection}
      />
      {props.displayedHardwares.map((hardware) => (
        <React.Fragment key={`${hardware.server.host}-${hardware.serialNumber}`}>
          <HardwarePageAccordionContent
            hardware={hardware}
            handleRefresh={props.handleRefresh}
            loadQueues={props.loadQueues}
            handleSelectHardware={handleSelectHardware}
            hardwareQueues={props.hardwareQueues}
            hardwareQueueStatusMap={props.hardwareQueueStatusMap}
            multiSelectEnabled={multiSelectEnabled}
            selectedHardware={selectedHardware}
          />
        </React.Fragment>
      ))}
      <HardwarePageAccordionQueueActions
        hardwares={props.displayedHardwares}
        selectedHardware={selectedHardware}
        multiSelectEnabled={multiSelectEnabled}
        canQueue={canQueue}
        canReserve={canReserve}
        isLoading={props.isLoading}
        shouldShowQueueOptions={props.shouldShowQueueOptions}
        refresh={props.handleRefresh}
        onOpen={onOpen}
      />

      <QueueConfirmationModal
        selectedHardware={selectedHardware}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        setMessage={setMessage}
        handleConfirmClick={multiSelectEnabled ? handleQueueToSelect : handleQueueToSearch}
      />
    </Box>
  );
};
