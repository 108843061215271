import React, { ReactNode } from 'react';
import {
  HeadingProps,
  IconButton,
  Stack,
  Tag,
  VStack,
  type LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { Link as RouterLink, type LinkProps as RouterLinkProps } from 'react-router-dom';
import { HStack, Heading, Link, Spacer } from '@chakra-ui/react';
import { ChevronRightIcon, RepeatIcon } from '@chakra-ui/icons';
import { useIsMobile } from '../../useIsMobile';

type Props = {
  items: Array<{ headingProps?: HeadingProps; linkProps?: ChakraLinkProps & RouterLinkProps }>;
  isLoading?: boolean;
  rightContent?: ReactNode;
  statusBadgeContent?: ReactNode;
  onReload: () => unknown;
  description?: string;
};

export function HeadingBreadcrumb(props: Props) {
  const isMobile = useIsMobile();
  const lastIndex = props.items.length - 1;
  const elements = props.items.map(({ headingProps, linkProps }, index) => {
    const baseHeadingProps: HeadingProps = {
      key: `heading-${index}`,
      size: isMobile ? 'md' : 'lg',
    };
    if (index === lastIndex) {
      baseHeadingProps.as = 'h3';
    }
    if (headingProps?.children !== undefined) {
      return <Heading key={index} {...baseHeadingProps} {...headingProps} />;
    }
    return (
      <Heading key={index} {...baseHeadingProps} {...headingProps}>
        <Link as={RouterLink} color="brand.500" {...linkProps} />
      </Heading>
    );
  });

  return (
    <VStack align="start" spacing={'2'}>
      <HStack>
        {elements.map((element, index) => (
          <React.Fragment key={`element-${index}`}>
            {index >= 1 && (
              <ChevronRightIcon key={`separator-${index}`} color="gray.400" fontSize="3xl" />
            )}
            {element}
          </React.Fragment>
        ))}
        <IconButton
          isRound
          isLoading={props.isLoading}
          variant="ghost"
          color="brand.500"
          onClick={props.onReload}
          icon={<RepeatIcon />}
          aria-label="Refresh"
        />
        <Spacer />
        {props.rightContent}
      </HStack>
      <Stack direction={isMobile ? 'column' : 'row'}>
        <Tag w={isMobile ? 'max-content' : 'auto'} fontWeight="semibold" fontSize="12px">
          {props.description}
        </Tag>
        {props.statusBadgeContent}
      </Stack>
    </VStack>
  );
}
