import {
  AccordionItem,
  AccordionButton,
  Accordion,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import React from 'react';

export type Props = {
  buttonContent: React.ReactNode;
  panelContent: React.ReactNode;
  buttonStyles?: any;
  panelStyles?: any;
  accordionStyles?: any;
};

export const AccordionLayout = ({
  buttonContent,
  panelContent,
  buttonStyles = {},
  panelStyles = {},
  accordionStyles,
}: Props) => {
  return (
    <Accordion allowToggle w="full" {...accordionStyles}>
      <AccordionItem>
        <AccordionButton
          border="1px solid lightgray"
          p={4}
          _hover={{ bg: 'gray.200 ' }}
          _expanded={{ borderBottom: '0px', bg: 'gray.100' }}
          {...buttonStyles}
        >
          {buttonContent}
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel
          borderLeft="1px solid lightgray"
          borderRight="1px solid lightgray"
          px={0}
          py={0}
          m={0}
          {...panelStyles}
        >
          {panelContent}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
