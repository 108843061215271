import { Badge } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { ActionRun, ActionRunProgressEnum } from '../apiClient/types';

export const ActionStatusBadge = ({ run }: { run: ActionRun }) => {
  switch (run.status) {
    case ActionRunProgressEnum.SUCCESS:
      return (
        <Badge variant="outline" colorScheme="green">
          <FormattedMessage id="hardwareactionprogressbadge_success" />
        </Badge>
      );
    case ActionRunProgressEnum.IN_PROGRESS:
      return (
        <Badge variant="outline" colorScheme="yellow">
          <FormattedMessage id="hardwareactionprogressbadge_inprogress" />
        </Badge>
      );
    case ActionRunProgressEnum.FAILED:
      return (
        <Badge variant="outline" colorScheme="red">
          <FormattedMessage id="hardwareactionprogressbadge_error" />
        </Badge>
      );
    case ActionRunProgressEnum.ERROR:
      return (
        <Badge variant="outline" colorScheme="gray">
          <FormattedMessage id="hardwareactionprogressbadge_error" />
        </Badge>
      );
    default:
      return (
        <Badge variant="outline" colorScheme="gray">
          <FormattedMessage id="hardwareactionprogressbadge_unknown" />
        </Badge>
      );
  }
};
