import { FormattedMessage } from 'react-intl';
import { Hardware, ReservationStatusEnum } from '../../apiClient/types';
import { ReservationStatusBadge } from '../hardware/ReservationStatusBadge';
import {
  Alert,
  AlertIcon,
  Text,
  HStack,
  Box,
  Button,
  Grid,
  VStack,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { AccordionLayout } from './AccordionLayout';
import { isRequiredAccessLevelForAction } from '../../hardware/isRequiredAccessLevelForAction';

type ProxyInformationProps = {
  hardware: Hardware;
};

type ProxySectionProps = {
  hardware: Hardware;
  connectModalDisclosure: UseDisclosureReturn;
  handleFree: () => Promise<void>;
  isLoadingFree: boolean;
};

const ProxyInformation = ({ hardware }: ProxyInformationProps) => {
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={1} w="75%" pl={2}>
      <Text fontWeight="bold">
        <FormattedMessage id="hardwaredetailgrid_proxy_manager_title" />
      </Text>
      <Text fontWeight="bold">
        <FormattedMessage id="hardwaredetailgrid_proxy_network_title" />
      </Text>
      <Text fontWeight="bold">
        <FormattedMessage id="hardwaredetailgrid_proxy_address_title" />
      </Text>

      <Text>{hardware.status.proxy.proxyManagerName}</Text>
      <HStack align="left" spacing={1}>
        <Text>{hardware.status.proxy.hostNetwork}</Text>
        <Text>{hardware.status.proxy.hostPrimaryAddress}</Text>
      </HStack>
      <Text>{hardware.status.proxy.proxies?.[0].address}</Text>
    </Grid>
  );
};

export const ProxySection = ({
  hardware,
  connectModalDisclosure,
  handleFree,
  isLoadingFree,
}: ProxySectionProps) => {
  return (
    <AccordionLayout
      buttonContent={
        <HStack justify="space-between" px={0} w="full" mr={2}>
          <Text fontWeight="bold">
            <FormattedMessage id="hardwaredetailpage_title_proxy" />
          </Text>
          {hardware.status.reservation.status === ReservationStatusEnum.FREE && (
            <ReservationStatusBadge hardware={hardware} />
          )}
        </HStack>
      }
      panelContent={
        <VStack w="full">
          <HStack h="40px" w="full" justify="space-between">
            <Box w="full">
              {!hardware.guard.canConnect && !hardware.status.proxy.active ? (
                <Alert status="info" bg="none" pl={0} py={0}>
                  <AlertIcon />
                  <FormattedMessage id="hardwaredetailpage_no_available_proxy" />
                </Alert>
              ) : !hardware.guard.canConnect && hardware.status.proxy.active ? (
                <ProxyInformation hardware={hardware} />
              ) : (
                <Alert status="info" bg="none" pl={0} py={0}>
                  <AlertIcon />
                  <FormattedMessage id="hardwaredetailpage_no_current_proxy" />
                </Alert>
              )}
            </Box>
            {hardware.guard.canConnect && isRequiredAccessLevelForAction(hardware.accessLevel) ? (
              <Button size="sm" onClick={connectModalDisclosure.onOpen}>
                <FormattedMessage id="hardwaredetailgrid_connect" />
              </Button>
            ) : null}

            {hardware.status.proxy.active &&
            hardware.guard.canFree &&
            isRequiredAccessLevelForAction(hardware.accessLevel) ? (
              <Button size="sm" onClick={handleFree} isLoading={isLoadingFree}>
                <FormattedMessage id="hardwaredetailgrid_free" />
              </Button>
            ) : null}
          </HStack>
        </VStack>
      }
      buttonStyles={{ borderBottom: 'none' }}
      accordionStyles={{
        defaultIndex: !hardware.guard.canConnect && !hardware.status.proxy.active ? 1 : 0,
      }}
      panelStyles={{ px: '6', py: '4' }}
    />
  );
};
