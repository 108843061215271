import { useContext } from 'react';
import {
  Button,
  Stack,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Box,
  VStack,
  Tooltip,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { DownloadIcon, InfoIcon, RepeatIcon } from '@chakra-ui/icons';
import { PageHeading } from '../../layout/PageHeading';
import { LocaleContext } from '../../i18n/LocaleContext';
import { EmptyContent } from '../../layout/common/EmptyContent';
import { Artifact } from '../../apiClient/types';
import { useIsMobile } from '../../useIsMobile';

type Props = {
  isLoadingArtifacts: boolean;
  loadArtifacts: () => void;
  artifacts: Artifact[] | undefined;
  downloadingArtifactIds: any;
  downloadFile: (artifact: any) => void;
  handleToggle: (id: any) => void;
  openTooltipId: string | null;
  setOpenTooltipId: (id: string | null) => void;
};

export function DownloadsPageContent({
  isLoadingArtifacts,
  loadArtifacts,
  artifacts,
  downloadingArtifactIds,
  downloadFile,
  handleToggle,
  openTooltipId,
  setOpenTooltipId,
}: Props) {
  const { formatDate } = useContext(LocaleContext);
  const isMobile = useIsMobile();

  const artifactTooltip = (artifact: Artifact) => (
    <Stack key={artifact.id}>
      <Text>{artifact.serverName}</Text>
      <Text>
        {artifact.path}/{artifact.id}
      </Text>
      <FormattedMessage
        id="downloadspage_uploaded"
        values={{ date: formatDate(artifact.uploadedOn) }}
      />
    </Stack>
  );

  if (artifacts && artifacts.length === 0) {
    return <EmptyContent includesHeading={false} emptyContentLabel="No downloads available" />;
  }

  return (
    <>
      <HStack align="top">
        <PageHeading
          heading={<FormattedMessage id="downloadspage_heading" />}
          description={<FormattedMessage id="downloadspage_description" />}
        />
        <IconButton
          isRound
          isLoading={isLoadingArtifacts}
          variant="ghost"
          onClick={loadArtifacts}
          icon={<RepeatIcon />}
          aria-label="Refresh"
        />
      </HStack>
      {artifacts &&
        (isMobile ? (
          <Box>
            <HStack bg="white" px={4} py={2} borderBottom="1px solid" borderColor="gray.200">
              <Text textTransform="uppercase" color="gray.600" fontWeight="bold" fontSize="xs">
                <FormattedMessage id="downloadspage_artifact_details" />
              </Text>
            </HStack>
            {artifacts.map((artifact) => (
              <HStack
                bg="white"
                key={artifact.id}
                p={4}
                borderBottom="1px solid"
                borderColor="gray.100"
              >
                <VStack align="start" w="50%">
                  <Text>{artifact.name}</Text>
                  <Tooltip label={artifactTooltip(artifact)} isOpen={openTooltipId === artifact.id}>
                    <HStack
                      onClick={() => handleToggle(artifact.id)}
                      onMouseEnter={() => setOpenTooltipId(artifact.id)}
                      onMouseLeave={() => setOpenTooltipId(null)}
                    >
                      <InfoIcon color="brand.600" mt="1.5px" mr="6px" />
                      <FormattedMessage id="downloadspage_more_information" />
                    </HStack>
                  </Tooltip>
                </VStack>
                <HStack w="50%" justify="end">
                  <Button
                    onClick={downloadFile.bind(null, artifact)}
                    variant="solid"
                    leftIcon={<DownloadIcon />}
                    marginY="-1.5"
                    isLoading={downloadingArtifactIds.has(artifact.id)}
                  >
                    <FormattedMessage id="downloadspage_download" />
                  </Button>
                </HStack>
              </HStack>
            ))}
          </Box>
        ) : (
          <Table colorScheme="gray" bg="white">
            <Thead>
              <Tr>
                <Th>
                  <FormattedMessage id="downloadspage_artifact" />
                </Th>
                <Th>
                  <FormattedMessage id="downloadspage_details" />
                </Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {artifacts.map((artifact) => (
                <Tr key={`${artifact.id}-${artifact.name}`}>
                  <Td>{artifact.name}</Td>
                  <Td>
                    <Stack>
                      <Tooltip
                        label={artifactTooltip(artifact)}
                        isOpen={openTooltipId === artifact.id}
                      >
                        <HStack
                          onClick={() => handleToggle(artifact.id)}
                          onMouseEnter={() => setOpenTooltipId(artifact.id)}
                          onMouseLeave={() => setOpenTooltipId(null)}
                        >
                          <InfoIcon color="brand.600" mt="1.5px" mr="6px" />
                          <FormattedMessage id="downloadspage_more_information" />
                        </HStack>
                      </Tooltip>
                    </Stack>
                  </Td>
                  <Td textAlign="end">
                    <Button
                      onClick={downloadFile.bind(null, artifact)}
                      variant="solid"
                      leftIcon={<DownloadIcon />}
                      marginY="-1.5"
                      isLoading={downloadingArtifactIds.has(artifact.id)}
                    >
                      <FormattedMessage id="downloadspage_download" />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ))}
    </>
  );
}
