import { useLocation, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { Alert, AlertIcon, Stack } from '@chakra-ui/react';
import { Layout } from '../../layout/Layout';
import { useLoadSingleHardware } from './useLoadSingleHardware';
import { HardwareDetailContent } from './HardwareDetailContent';
import { HeadingBreadcrumb } from './HeadingBreadcrumb';
import { getHardwareTitle, getHardwareDescription } from '../../hardware/getHardwareUtils';
import { Loader } from '../../layout/common/Loader';
import { useWebSocket } from '../../webSocket/useWebSocket';
import { HardwareDetailInformationBadge } from '../hardware/HardwareDetailInformationBadge';
import { ReservationStatusEnum } from '../../apiClient/types';
import { isRequiredAccessLevelForAction } from '../../hardware/isRequiredAccessLevelForAction';
import { useQueueHardware } from '../hardwareQueue/useQueueHardware';

type PageTitleState = { fallbackPageTitle: string };
function isPageTitleState(state: unknown): state is PageTitleState {
  return state instanceof Object && 'fallbackPageTitle' in state;
}

export function HardwareDetailPage() {
  const { hardwareLocation } = useParams();
  if (!hardwareLocation) {
    throw new Error('Component can only be used as a route element with the required params.');
  }

  const location = useLocation();
  const fallbackPageTitle = isPageTitleState(location.state) && location.state.fallbackPageTitle;

  const {
    loadHardware,
    isLoading: isLoadingHardware,
    hardware,
    hardwareDetail,
  } = useLoadSingleHardware(hardwareLocation);
  const { hardwareQueues, loadHardwareQueues } = useQueueHardware();
  const { reconnectWebSocket } = useWebSocket();

  const hardwareQueueStatusMap = useMemo(() => {
    const statusMap = new Map();
    hardwareQueues?.forEach((queue) => {
      queue.hardware?.forEach((hardware) => {
        if (!statusMap.has(hardware.location)) {
          statusMap.set(hardware.location, [queue.status]);
        } else {
          const existingStatuses = statusMap.get(hardware.location);
          statusMap.set(hardware.location, [...existingStatuses, queue.status]);
        }
      });
    });
    return statusMap;
  }, [hardwareQueues]);

  const handleRefreshClick = async () => {
    await reconnectWebSocket();
    loadHardware();
    loadHardwareQueues({ loadAllQueues: true });
  };

  const refresh = async () => {
    await loadHardware();
    await loadHardwareQueues({ loadAllQueues: true });
  };

  useEffect(() => {
    loadHardware();
    loadHardwareQueues({ loadAllQueues: true });
  }, [loadHardware, loadHardwareQueues]);

  const isReserved = hardware && hardware.status.reservation.status !== ReservationStatusEnum.FREE;
  const canPerformAction = hardware && isRequiredAccessLevelForAction(hardware.accessLevel);

  let content;
  if (isLoadingHardware) {
    content = <Loader label={<FormattedMessage id="hardwaredetailpage_loading_hardware" />} />;
  } else if (hardware && hardwareDetail) {
    content = (
      <HardwareDetailContent
        hardware={hardware}
        hardwareDetail={hardwareDetail}
        refresh={refresh}
        canPerformAction={canPerformAction}
        isReserved={isReserved}
        queues={hardwareQueues}
        statusMap={hardwareQueueStatusMap}
      />
    );
  } else if (isLoadingHardware) {
    content = null;
  } else {
    content = (
      <Alert status="error">
        <AlertIcon />
        <FormattedMessage id="hardwaredetailpage_load_hardware_error" />
      </Alert>
    );
  }

  return (
    <Layout>
      <Stack spacing={6}>
        <HeadingBreadcrumb
          items={[
            { linkProps: { to: '/hardware', children: 'Hardware' } },
            {
              headingProps: {
                children: hardware ? getHardwareTitle(hardware) : fallbackPageTitle,
              },
            },
          ]}
          isLoading={isLoadingHardware}
          onReload={handleRefreshClick}
          description={hardware ? getHardwareDescription(hardware) : undefined}
          statusBadgeContent={
            <HardwareDetailInformationBadge
              isReserved={isReserved}
              canPerformAction={canPerformAction}
            />
          }
        />
        {content}
      </Stack>
    </Layout>
  );
}
