import { Grid, GridItem } from '@chakra-ui/react';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout } from '../../layout/Layout';
import { PageHeading } from '../../layout/PageHeading';
import { useIsMobile } from '../../useIsMobile';
import { PermissionsContext } from '../realms/PermissionsProvider';
import { AdministrationAccordion } from './AdministrationAccordion';
import { InstructionsAccordion } from './InstructionsAccordion';
import { SectionLink } from './SectionLink';

export function DashboardPage() {
  const { sessionInformation } = useContext(PermissionsContext) || {};
  const isMobile = useIsMobile();
  const isDesktop = !isMobile;

  return (
    <Layout>
      <PageHeading
        heading={<FormattedMessage id="dashboardpage_heading" />}
        description={<FormattedMessage id="dashboardpage_description" />}
      />
      <Grid
        gap={4}
        autoFlow="true"
        autoRows="true"
        templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'}
      >
        <GridItem>
          <SectionLink
            to="/hardware"
            heading={<FormattedMessage id="dashboardpage_hardware_heading" />}
            description={<FormattedMessage id="dashboardpage_hardware_description" />}
          />
        </GridItem>
        {isDesktop && (
          <GridItem rowSpan={5} colSpan={1}>
            <InstructionsAccordion />
          </GridItem>
        )}
        <GridItem colSpan={1}>
          <SectionLink
            to="/download"
            heading={<FormattedMessage id="dashboardpage_downloads_heading" />}
            description={<FormattedMessage id="dashboardpage_downloads_description" />}
          />
        </GridItem>
        {sessionInformation?.realmAdmin && (
          <GridItem colSpan={1}>
            <AdministrationAccordion />
          </GridItem>
        )}
        <GridItem colSpan={1}>
          <SectionLink
            heading={<FormattedMessage id="dashboardpage_assets_heading" />}
            description={<FormattedMessage id="dashboardpage_assets_description" />}
            disabled
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <SectionLink
            heading={<FormattedMessage id="dashboardpage_mobile_devices_heading" />}
            description={<FormattedMessage id="dashboardpage_mobile_devices_description" />}
            disabled
          />
        </GridItem>
        {isMobile && (
          <GridItem rowSpan={5} colSpan={1}>
            <InstructionsAccordion />
          </GridItem>
        )}
      </Grid>
    </Layout>
  );
}
