import { Checkbox, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { HardwareQueue } from '../../apiClient/types';

type Props = {
  queue: HardwareQueue;
  allChecked: boolean;
  isIndeterminate: boolean;
  selectedHardwares: Set<string>;
  toggleSelectAll: (isChecked: boolean) => void;
  toggleHardwareSelection: (location: string, isChecked: boolean) => void;
};

export function QueueDetailGridActions(props: Props) {
  return (
    <>
      {props.queue.myQueue ? (
        <VStack w="full" align="left" py={2}>
          <Checkbox
            isChecked={props.allChecked}
            isIndeterminate={props.isIndeterminate}
            onChange={(e) => props.toggleSelectAll(e.target.checked)}
          >
            <FormattedMessage id="queue_details_grid_select_all" />
          </Checkbox>
          {props.queue.hardware?.map((hardware) => (
            <Checkbox
              key={hardware.location}
              isChecked={props.selectedHardwares.has(hardware.location)}
              onChange={(e) => props.toggleHardwareSelection(hardware.location, e.target.checked)}
            >
              {hardware.product.name} - {hardware.name} - {hardware.server.host}
            </Checkbox>
          ))}
        </VStack>
      ) : (
        <>
          {props.queue.hardware?.map((hardware) => (
            <Text key={hardware.location}>
              {hardware.product.name} - {hardware.name} - {hardware.server.host}
            </Text>
          ))}
        </>
      )}
    </>
  );
}
