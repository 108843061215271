import { Checkbox, HStack, Switch, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

type Props = {
  handleToggleSwitch: () => void;
  multiSelectEnabled: boolean;
  selectAll: boolean;
  onToggleSelectAll: (selectAll: boolean) => void;
  orderedHardware: boolean;
  onChangeOrderedHardware: (orderByReservation: boolean) => void;
};

export const HardwarePageAccordionHeaderContent = ({
  handleToggleSwitch,
  multiSelectEnabled,
  selectAll,
  onToggleSelectAll,
  orderedHardware,
  onChangeOrderedHardware,
}: Props) => {
  return (
    <>
      <VStack w="full" align="start">
        <HStack>
          <Text fontWeight="bold">
            <FormattedMessage id="hardwarepage_header_multi_select_label" />
          </Text>
          <Switch onChange={handleToggleSwitch} />
        </HStack>
        <HStack>
          <Checkbox
            bg="white"
            mr="1"
            borderColor="gray.300"
            isDisabled={!multiSelectEnabled}
            isChecked={selectAll}
            onChange={(e) => onToggleSelectAll(e.target.checked)}
          />
          <FormattedMessage id="hardwarepage_select_all_for_queue" />
        </HStack>
      </VStack>

      <VStack w="full" align="start">
        <HStack>
          <Text fontWeight="bold">
            <FormattedMessage id="hardwarepage_order_by_availability" />
          </Text>
          <Switch
            isChecked={orderedHardware}
            onChange={() => onChangeOrderedHardware(!orderedHardware)}
          />
        </HStack>
        <HStack>
          <FormattedMessage id="hardwarepage_availability_description" />
        </HStack>
      </VStack>
    </>
  );
};
