import { type ReactNode } from 'react';
import { Link as ChakraLink, type LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import {
  NavLink as RouterNavLink,
  type NavLinkProps as RouterNavLinkProps,
  type To,
} from 'react-router-dom';

function ChakraLinkAsRouterNavLink(props: RouterNavLinkProps & ChakraLinkProps) {
  return <ChakraLink as={RouterNavLink} {...props} />;
}

export function PrimaryNavLink(props: { children: ReactNode; to: To }) {
  return (
    <ChakraLinkAsRouterNavLink
      color="white"
      style={({ isActive }) => (isActive ? { fontWeight: 'bold' } : {})}
      end
      {...props}
    />
  );
}
