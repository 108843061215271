import { Heading, HStack, VStack, Box } from '@chakra-ui/layout';
import { FormattedMessage } from 'react-intl';
import { ActionResult } from '../../apiClient/types';
import { ActionResultAttributesTable } from './ActionResultAttributesTable';
import { PageHeading } from '../../layout/PageHeading';
import { HardwareActionLogs } from './HardwareActionLogs';
import { BackButton } from '../../layout/BackButton';
import { HardwareActionProgressBadge } from '../../hardware/HardwareActionProgressBadge';

type Props = {
  actionResult: ActionResult;
};

export function HardwareActionLogContent({ actionResult }: Props) {
  return (
    <>
      <PageHeading
        heading={<FormattedMessage id="hardwareactionlogpage_heading" />}
        description={<FormattedMessage id="hardwareactionlogpage_subtitle" />}
      />
      <HStack w="full" justify="space-between">
        <BackButton />
      </HStack>
      <Box background={'white'}>
        <Box p={2}>
          <HStack>
            <HardwareActionProgressBadge result={actionResult} fontSize={14} />
            <Heading
              fontSize={20}
              pb={'1.5px'}
            >{`${actionResult.run.action.name} - ${actionResult.run.id}`}</Heading>
          </HStack>
        </Box>

        <VStack spacing={4} w="full" pb={4}>
          <Box w="full" px={2}>
            <ActionResultAttributesTable actionResult={actionResult} />
          </Box>
          <Box w="full" px={2}>
            <HardwareActionLogs actionResult={actionResult} />
          </Box>
        </VStack>
      </Box>
    </>
  );
}
