import { useEffect } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import { Hardware } from '../../apiClient/types';
import { FiSliders } from 'react-icons/fi';
import { HardwarePageAccordionHeaderContent } from './HardwarePageAccordionHeaderContent';
import { FormattedMessage } from 'react-intl';

type Props = {
  displayedHardwares: Hardware[];
  onToggleSelectAll: (selectAll: boolean) => void;
  selectAll: boolean;
  multiSelectEnabled: boolean;
  onChangeMultiSelectEnabled: (enabled: boolean) => void;
  clearMultiSelection: () => void;
  orderedHardware: boolean;
  onChangeOrderedHardware: (orderByReservation: boolean) => void;
};

export const HardwarePageAccordionHeader = ({
  displayedHardwares,
  onToggleSelectAll,
  selectAll,
  multiSelectEnabled,
  onChangeMultiSelectEnabled,
  clearMultiSelection,
  orderedHardware,
  onChangeOrderedHardware,
}: Props) => {
  const handleToggleSwitch = () => {
    onChangeMultiSelectEnabled(!multiSelectEnabled);
  };

  useEffect(() => {
    clearMultiSelection();
  }, [clearMultiSelection]);

  if (displayedHardwares?.length === 0) {
    return null;
  }

  return (
    <Accordion mt="0" borderColor="transparent" allowToggle>
      <AccordionItem>
        <AccordionButton _expanded={{ bg: 'blackAlpha.50' }}>
          <Flex w="100%" justifyContent="space-between">
            <Flex p={2}>
              <Icon as={FiSliders} mr={4} mt={1} boxSize={8} color="blue.300" />
              <Box textAlign="left">
                <Heading as="h5" size="sm">
                  <FormattedMessage id="hardwarepage_header_title" />
                </Heading>
                <Text color="gray.500">
                  <FormattedMessage id="hardwarepage_header_description" />
                </Text>
              </Box>
            </Flex>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pt={2} pb={4} bg="blackAlpha.50">
          <HStack px="2" w="full" justify="space-between">
            <HardwarePageAccordionHeaderContent
              handleToggleSwitch={handleToggleSwitch}
              multiSelectEnabled={multiSelectEnabled}
              onToggleSelectAll={onToggleSelectAll}
              selectAll={selectAll}
              orderedHardware={orderedHardware}
              onChangeOrderedHardware={onChangeOrderedHardware}
            />
          </HStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
