import { useEffect, useMemo, useCallback, useState } from 'react';
import { useLoadHardware } from './useLoadHardware';
import { HardwarePageContent } from './HardwarePageContent';
import { displayedHardwares } from '../hardwareFilter/HardwareFilterHelper';
import { useQueueHardware } from '../hardwareQueue/useQueueHardware';
import { useFilterHardware } from '../hardwareFilter/useFilterHardware';
import { useSortHardware } from './useSortHardware';
import { useWebSocket } from '../../webSocket/useWebSocket';
import { Artifact } from '../../apiClient/types';
import { useRefreshHardware } from './useRefreshHardware';

export function HardwarePage() {
  const [artifactLocation, setArtifactLocation] = useState<string | null>(null);
  const { reconnectWebSocket } = useWebSocket();
  const { useRefreshHardwareQuery } = useRefreshHardware();
  const { loadHardware, isLoading, hardwares, loadHardwareSearches } = useLoadHardware();
  const { orderByAvailability, orderedHardware, setOrderedHardware } = useSortHardware();
  const {
    loadHardwareQueues,
    hardwareQueues,
    queueFromSelection,
    isLoading: isLoadingQueue,
  } = useQueueHardware();
  const {
    searchString,
    includedKeywords,
    excludedKeywords,
    defaultFilter,
    selectedFilter,
    setSelectedFilter,
    handleSearchChange,
    removeKeywordFilter,
    displayQueueOptions,
  } = useFilterHardware();

  useEffect(() => {
    const init = async () => {
      await loadHardware();
      await loadHardwareSearches();
      await loadHardwareQueues({ loadAllQueues: true });
    };
    init();
  }, [loadHardware, loadHardwareSearches, loadHardwareQueues]);

  const handleRefreshClick = async () => {
    await reconnectWebSocket();
    loadHardwareSearches({ forceRefresh: true });
    loadHardware();
    setSelectedFilter(defaultFilter);
  };

  const handleRefresh = useCallback(async () => {
    const previousFilter = selectedFilter;
    await loadHardwareSearches({ forceRefresh: true });

    const hardwareSearches = JSON.parse(localStorage.getItem('hardware-searches') || '[]');
    const currentFilterArtifact = hardwareSearches.find(
      (artifact: Artifact) => artifact.name === selectedFilter
    );

    if (currentFilterArtifact && hardwares && hardwares?.length > 1) {
      await loadHardware(currentFilterArtifact.location);
      setSelectedFilter(previousFilter);
    } else {
      await loadHardware();
      setSelectedFilter(defaultFilter);
    }

    await loadHardwareQueues({ loadAllQueues: true });
  }, [
    defaultFilter,
    selectedFilter,
    loadHardware,
    loadHardwareSearches,
    loadHardwareQueues,
    setSelectedFilter,
    hardwares,
  ]);

  useRefreshHardwareQuery(handleRefresh);

  const handleQueueCreation = async (hardwareLocations: string[], message?: string) => {
    await queueFromSelection(hardwareLocations, message);
    await loadHardwareQueues({ loadAllQueues: true });
  };

  const handleSelectFilter = (selectedArtifactLocation: string | null) => {
    if (selectedArtifactLocation) {
      setArtifactLocation(selectedArtifactLocation);
      loadHardware(selectedArtifactLocation);
    } else {
      setArtifactLocation(null);
      loadHardware();
    }
  };

  const hardwareQueueStatusMap = useMemo(() => {
    const statusMap = new Map();
    hardwareQueues?.forEach((queue) => {
      queue.hardware?.forEach((hardware) => {
        if (!statusMap.has(hardware.location)) {
          statusMap.set(hardware.location, [queue.status]);
        } else {
          const existingStatuses = statusMap.get(hardware.location);
          statusMap.set(hardware.location, [...existingStatuses, queue.status]);
        }
      });
    });
    return statusMap;
  }, [hardwareQueues]);

  const filterWithSearchString = useMemo(() => {
    let result = displayedHardwares(
      hardwares ?? [],
      searchString,
      includedKeywords,
      excludedKeywords
    );
    if (orderedHardware) {
      result = result.sort(orderByAvailability(hardwareQueueStatusMap));
    }
    return result;
  }, [
    hardwares,
    searchString,
    includedKeywords,
    excludedKeywords,
    orderByAvailability,
    orderedHardware,
    hardwareQueueStatusMap,
  ]);

  return (
    <HardwarePageContent
      hardwares={hardwares}
      hardwareQueues={hardwareQueues}
      hardwareQueueStatusMap={hardwareQueueStatusMap}
      displayedHardwares={filterWithSearchString}
      orderedHardware={orderedHardware}
      isLoading={isLoading}
      isLoadingQueue={isLoadingQueue}
      includedKeywords={includedKeywords}
      excludedKeywords={excludedKeywords}
      inputSearchString={searchString}
      selectedFilter={selectedFilter}
      artifactLocation={artifactLocation}
      loadQueues={loadHardwareQueues}
      handleRefresh={handleRefresh}
      onRefreshClick={handleRefreshClick}
      handleSearchChange={handleSearchChange}
      handleSelectFilter={handleSelectFilter}
      onChangeSelectedFilter={setSelectedFilter}
      onChangeKeywordFilters={removeKeywordFilter}
      onChangeOrderedHardware={setOrderedHardware}
      handleQueueCreation={handleQueueCreation}
      shouldShowQueueOptions={() => displayQueueOptions(filterWithSearchString)}
    />
  );
}
