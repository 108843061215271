import { Hardware, HardwareQueue, QueueStatusEnum } from '../apiClient/types';

export function getHardwareTitle(hardware: Hardware): string {
  return [hardware.product.name, hardware.name].join(' – ');
}

export function getHardwareDescription(hardware: Hardware): string {
  return [hardware.serialNumber, hardware.server.host].join(' - ');
}

export function isPendingForMe(hardware: Hardware | undefined, statusMap: Map<any, any>) {
  const statuses = statusMap.get(hardware?.location);
  return statuses?.some((status: QueueStatusEnum) => status === QueueStatusEnum.PENDING_FOR_ME);
}

export function getQueueIdForPendingForMe(
  hardware: Hardware | undefined,
  queues: HardwareQueue[] | undefined
) {
  const latestQueue = queues?.find(
    (queue: HardwareQueue) =>
      queue.hardware?.some((hw) => hw.location === hardware?.location) &&
      queue.status === QueueStatusEnum.PENDING_FOR_ME
  );
  return latestQueue?.id ?? '';
}
