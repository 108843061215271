import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useIntl } from 'react-intl';
import { enUS, enGB, fi } from 'date-fns/locale';
import { useContext } from 'react';
import { LocaleContext } from '../../i18n/LocaleContext';

type Props = {
  label?: string | React.ReactElement;
  disabled?: boolean;
  date: Date | null;
  onChangeDate: (date: Date | null) => void;
  labelWidth?: string;
  labelPadding?: string | number;
  variant?: 'horizontal' | 'vertical';
};

registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
registerLocale('fi-FI', fi);

export const CustomDatePicker = ({
  label,
  disabled = false,
  date,
  onChangeDate,
  labelWidth = '50%',
  labelPadding = 3,
  variant = 'horizontal',
}: Props) => {
  const DatePickerLayout = variant === 'horizontal' ? HStack : VStack;
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const dateFnsLocaleMap: { [key: string]: Locale } = {
    'en-US': enUS,
    'en-GB': enGB,
    'fi-FI': fi,
  };

  const currentLocale = locale;
  const datePickerLocale = dateFnsLocaleMap[currentLocale];

  const getCurrentTime = () => {
    const now = new Date();
    const localTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
    return localTime.substring(11, 16);
  };

  const placeholder = intl.formatMessage(
    { id: 'realm_user_date_placeholder' },
    { time: getCurrentTime() }
  );

  return (
    <DatePickerLayout w="full" m={0}>
      {label && (
        <Box w={labelWidth}>
          <Text px={labelPadding} color={disabled ? 'gray.200' : 'black'}>
            {label}
          </Text>
        </Box>
      )}
      <Box
        p={0.5}
        pl={2}
        border="1px solid"
        borderColor={disabled ? 'gray.200' : 'gray.400'}
        borderRadius="lg"
      >
        <ReactDatePicker
          disabled={disabled}
          selected={date}
          placeholderText={placeholder}
          onChange={(date) => onChangeDate(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption={intl.formatMessage({ id: 'realm_user_date_time' })}
          dateFormat="dd/MM/yyyy HH:mm"
          className="custom-datepicker"
          locale={datePickerLocale}
        />
      </Box>
    </DatePickerLayout>
  );
};
