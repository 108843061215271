import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  Stack,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  header: string;
  label?: string;
  children: React.ReactNode;
  disabled?: boolean;
  size?: string;
};

export const RealmModalLayout = ({
  isOpen,
  onClose,
  onSubmit,
  header,
  label,
  children,
  disabled,
  size = 'md',
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{label}</Text>
          <Stack spacing={0}>{children}</Stack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            <FormattedMessage id="commons_close" />
          </Button>

          {onSubmit && (
            <Button mr={3} onClick={onSubmit} disabled={disabled}>
              <FormattedMessage id="commons_submit" />
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
