import { useQuery } from '@tanstack/react-query';

export function useRefreshHardware() {
  const useRefreshHardwareQuery = (onRequestHardwareRefresh: () => any) => {
    useQuery({
      queryKey: ['refreshHardware'],
      queryFn: async () => {
        const result = await onRequestHardwareRefresh();
        return result || [];
      },
      refetchOnWindowFocus: false,
    });
  };

  return { useRefreshHardwareQuery };
}
