import { useState } from 'react';
import {
  ConnectionStatusEnum,
  Hardware,
  QueueStatusEnum,
  ReservationStatusEnum,
} from '../../apiClient/types';
import { isRequiredAccessLevelForAction } from '../../hardware/isRequiredAccessLevelForAction';

export function useSortHardware() {
  const [orderedHardware, setOrderedHardware] = useState<boolean>(false);

  const orderByAvailability =
    (hardwareQueueStatusMap: Map<string, QueueStatusEnum>) => (a: Hardware, b: Hardware) => {
      const orderOfReservationStatus = [
        ReservationStatusEnum.RESERVED_BY_ME,
        ReservationStatusEnum.FREE,
        ReservationStatusEnum.PENDING_FOR_ME,
        ReservationStatusEnum.PENDING,
        ReservationStatusEnum.RESERVED,
      ];

      const orderOfQueueStatus = [
        QueueStatusEnum.FIRST_FOR_ME,
        QueueStatusEnum.NOT_QUEUED,
        QueueStatusEnum.PENDING_FOR_ME,
        QueueStatusEnum.QUEUED_FOR_ME,
        QueueStatusEnum.FIRST,
        QueueStatusEnum.PENDING,
        QueueStatusEnum.QUEUED,
      ];

      const getQueueStatusOrder = (hardware: Hardware) => {
        const queueStatus =
          hardwareQueueStatusMap.get(hardware.location) || QueueStatusEnum.NOT_QUEUED;
        return orderOfQueueStatus.indexOf(queueStatus);
      };

      const compareOnlineStatus = (a: Hardware, b: Hardware) => {
        if (
          a.status.connection === ConnectionStatusEnum.ONLINE &&
          b.status.connection !== ConnectionStatusEnum.ONLINE
        )
          return -1;
        if (
          a.status.connection !== ConnectionStatusEnum.ONLINE &&
          b.status.connection === ConnectionStatusEnum.ONLINE
        )
          return 1;
        return 0;
      };

      const compareReservationStatus = (a: Hardware, b: Hardware) => {
        return (
          orderOfReservationStatus.indexOf(a.status.reservation.status) -
          orderOfReservationStatus.indexOf(b.status.reservation.status)
        );
      };

      const compareAccessLevel = (a: Hardware, b: Hardware) => {
        if (
          isRequiredAccessLevelForAction(a.accessLevel) &&
          !isRequiredAccessLevelForAction(b.accessLevel)
        )
          return -1;
        if (
          !isRequiredAccessLevelForAction(a.accessLevel) &&
          isRequiredAccessLevelForAction(b.accessLevel)
        )
          return 1;
        return 0;
      };

      const compareQueueStatus = (a: Hardware, b: Hardware) => {
        return getQueueStatusOrder(a) - getQueueStatusOrder(b);
      };

      return (
        compareAccessLevel(a, b) ||
        compareReservationStatus(a, b) ||
        compareOnlineStatus(a, b) ||
        compareQueueStatus(a, b)
      );
    };

  return { orderByAvailability, orderedHardware, setOrderedHardware };
}
