import { createContext, ReactNode, useEffect } from 'react';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';
import { useIsAuthenticated } from '@azure/msal-react';
import { SessionInformationResponse } from '../../apiClient/types';
import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';

type Props = {
  children: ReactNode;
};

type ContextProps = {
  sessionInformation: SessionInformationResponse | undefined;
  isLoading: boolean;
};

export const PermissionsContext = createContext<ContextProps | undefined>(undefined);

/**
 * Provider for the PermissionsContext.
 *
 * Loads the user's permissions for the current session and provides them to the context.
 * @param children - children of the provider
 * @returns the provider
 */

export const PermissionsProvider = ({ children }: Props) => {
  const { getSessionInformation } = useApiClient();
  const isLoggedIn = useIsAuthenticated();
  const showError = useShowError();
  const intl = useIntl();

  const {
    data: sessionInformation,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryFn: () => getSessionInformation(),
    queryKey: ['sessionInformation'],
    enabled: isLoggedIn,
  });

  useEffect(() => {
    if (isError) {
      showError(error.message, {
        description: intl.formatMessage({ id: 'session_load_permissions_error' }),
      });
    }
  }, [isError, error, showError, intl]);

  return (
    <PermissionsContext.Provider value={{ sessionInformation, isLoading }}>
      {children}
    </PermissionsContext.Provider>
  );
};
