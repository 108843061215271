import { Button, HStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Hardware } from '../../apiClient/types';
import { useReserveHardware } from '../../hardware/useReserveHardware';

type Props = {
  hardwares: Hardware[];
  selectedHardware: Set<string>;
  multiSelectEnabled: boolean;
  isLoading: boolean;
  canQueue: boolean;
  canReserve: boolean;
  shouldShowQueueOptions: () => boolean;
  refresh: () => unknown;
  onOpen: () => void;
};

export const HardwarePageAccordionQueueActions = (props: Props) => {
  const canQueue = props.hardwares.some((hardware) => hardware.guard.canQueue);
  const allCanReserve = props.hardwares.every((hardware) => hardware.guard.canReserve);

  const { handleReserve, isLoading: isLoadingReserve } = useReserveHardware(
    props.hardwares[0],
    props.refresh
  );

  const reserveBestMatch = async () => {
    await handleReserve();
  };

  const queueToResults = () => {
    props.onOpen();
  };

  const count = props.selectedHardware.size;

  if (props.multiSelectEnabled) {
    return (
      <HStack w="full" justify="end">
        <Button
          mt="4"
          isDisabled={props.selectedHardware.size === 0}
          onClick={props.onOpen}
          isLoading={props.isLoading}
        >
          {props.selectedHardware.size === 0 ? (
            <FormattedMessage id="hardwarepage_queue_selected" />
          ) : (
            <FormattedMessage id="hardwarepage_queue_selected_count" values={{ count }} />
          )}
        </Button>
      </HStack>
    );
  } else if (props.shouldShowQueueOptions()) {
    return (
      <HStack w="full" justify="end" mt="4">
        {canQueue ? (
          <Button onClick={queueToResults} isLoading={props.isLoading}>
            <FormattedMessage id="hardwarepage_queue_results" />
          </Button>
        ) : allCanReserve ? (
          <Button onClick={reserveBestMatch} isLoading={isLoadingReserve}>
            <FormattedMessage id="hardwarepage_reserve_best_match" />
          </Button>
        ) : null}
      </HStack>
    );
  }

  return null;
};
