import { Button } from '@chakra-ui/react';
import { BsArrowLeft } from 'react-icons/bs';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Button size="sm" leftIcon={<BsArrowLeft />} variant="outline" onClick={() => navigate(-1)}>
      <FormattedMessage id="commons_return" />
    </Button>
  );
};
