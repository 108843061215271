import { useCallback, useContext, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { ApiClientContext } from './ApiClientContext';
import { InvalidSessionError, ApiError } from './errors';
import {
  DownloadableFile,
  Hardware,
  ApiClient,
  RealmAdmin,
  RealmSession,
  ValueRetirement,
  Artifact,
  HardwareQueue,
} from './types';
import type { Config } from '../config/types';
import { SessionContext } from '../auth/SessionContext';

type RequestParams = {
  [key: string]: any;
  groups?: string[];
};

function buildQueryString(params: { [key: string]: string | number | boolean | string[] }) {
  let str = [];
  for (let param in params) {
    if (params.hasOwnProperty(param)) {
      const value = params[param];
      if (Array.isArray(value)) {
        str.push(...value.map((item) => `${param}=${item}`));
      } else {
        str.push(
          value !== null && typeof value === 'object'
            ? param + '=' + encodeURIComponent(JSON.stringify(value))
            : param + '=' + encodeURIComponent(value)
        );
      }
    }
  }
  return str.join('&');
}

function loggedThrow(error: Error, ...additionalLogs: unknown[]): never {
  console.warn(error, ...additionalLogs);
  throw error;
}

function buildUrl(apiBaseUrl: string, path: string, params: {} = {}) {
  return [apiBaseUrl, path, '?' + buildQueryString(params)].join('');
}

type Props = {
  children: React.ReactNode;
  config: Config;
};

let memoryTokenStorage = {
  token: '',
  expiresIn: 0,
};

function saveTokenToMemory(token: string, expiry: number) {
  const expiryTime = Date.now() + expiry * 1000;
  memoryTokenStorage = { token, expiresIn: expiryTime };
}

function getTokenFromMemory() {
  const { token, expiresIn } = memoryTokenStorage;
  const currentTime = Date.now();

  // Return token if it exists and has not expired
  if (token && currentTime < expiresIn) {
    return token;
  }

  // Clear token if it has expired
  memoryTokenStorage = { token: '', expiresIn: 0 };
  return null;
}

export function ApiClientProvider(props: Props) {
  const { msalLoginRequest } = useContext(SessionContext);
  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const getAccessToken = useCallback(async () => {
    const storedToken = getTokenFromMemory();
    if (storedToken) {
      return storedToken;
    }

    if (!account) {
      throw new InvalidSessionError('No account available.');
    }

    try {
      const response = await instance.acquireTokenSilent({
        ...msalLoginRequest,
        account: account,
      });

      // Calculate expiration time
      const expirationTime = response.expiresOn
        ? Math.floor((response.expiresOn.getTime() - Date.now()) / 1000)
        : 0;

      // Save token to memory and return if not expired
      if (expirationTime > 0) {
        saveTokenToMemory(response.accessToken, expirationTime);
      } else {
        loggedThrow(new InvalidSessionError('Token expiration invalid.'));
      }

      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        loggedThrow(new InvalidSessionError('Failed acquiring token.'));
      } else {
        throw error;
      }
    }
  }, [instance, account, msalLoginRequest]);

  const authenticateWebSocket = useCallback(
    async (webSocket: WebSocket) => {
      const accessToken = await getAccessToken();
      if (webSocket.readyState === WebSocket.OPEN) {
        webSocket.send(`Token ${accessToken}`);
      }
    },
    [getAccessToken]
  );

  const connectWebSocket = useMemo<ApiClient['connectWebSocket']>(() => {
    const { apiWebsocketUrl } = props.config;
    let currentWebSocket: WebSocket | null = null;

    if (apiWebsocketUrl) {
      return function connectWebSocket() {
        if (currentWebSocket) {
          currentWebSocket.close();
        }

        currentWebSocket = new WebSocket(apiWebsocketUrl);
        currentWebSocket.onopen = async () => {
          if (currentWebSocket?.readyState === 1) {
            authenticateWebSocket(currentWebSocket);
          }
        };
        return currentWebSocket;
      };
    } else {
      return undefined;
    }
  }, [props.config, authenticateWebSocket]);

  const request = useCallback(
    async function request(path: string, params: RequestParams = {}) {
      if (!account) {
        loggedThrow(new InvalidSessionError('No account available.'));
      }

      const headers = new Headers();
      const accessToken = await getAccessToken();
      headers.append('Authorization', `Bearer ${accessToken}`);

      let encodedURL = buildUrl(props.config.apiBaseUrl, path);
      const url = new URL(encodedURL);

      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (value.length === 0) {
            url.searchParams.append(key, '');
          } else {
            value.forEach((item) => url.searchParams.append(key, item));
          }
        } else if (value !== undefined) {
          url.searchParams.append(key, value);
        }
      });

      encodedURL = url.toString();

      const res = await fetch(encodedURL, { headers });
      if (!res.ok) {
        const text = await res.text();
        if (text.match('Invalid Session')) {
          const error = new InvalidSessionError('API reports that the session is invalid.');
          loggedThrow(error);
        }
        const error = new ApiError('Unknown API error.');
        error.responseText = text;
        loggedThrow(error, { responseText: text });
      }
      return res;
    },
    [getAccessToken, account, props.config.apiBaseUrl]
  );

  const jsonRequest = useCallback(
    async function jsonRequest(path: string, params: RequestParams = {}) {
      const res = await request(path, params);

      if (res.headers.get('Content-Type')?.match('json')) {
        return res.json();
      }

      loggedThrow(new ApiError('Invalid response.'), {
        'Content-Type': res.headers.get('Content-Type'),
      });
    },
    [request]
  );

  const downloadFile = useCallback(
    async function downloadFile(path: string, params: {} = {}): Promise<DownloadableFile> {
      const res = await request(path, params);

      // attachment;filename="Proxy Download DHBHPPU.zip"
      const contentDisposition = res.headers.get('Content-Disposition') ?? '';
      const fileName = contentDisposition
        ?.split(';')[1]
        ?.split('filename')[1]
        ?.split('=')[1]
        ?.trim()
        .replaceAll('"', '');

      return { blob: await res.blob(), name: fileName };
    },
    [request]
  );

  const freeHardware = useCallback<ApiClient['freeHardware']>(
    function freeHardware({ hardwareLocation }) {
      return jsonRequest('/hardware/item/free', {
        hardwareLocation,
      });
    },
    [jsonRequest]
  );

  const listHardware = useCallback<ApiClient['listHardware']>(
    async function listHardware(artifactLocation?: string) {
      let hardwares: Hardware[] = (
        await jsonRequest('/hardware/item/list', {
          artifactLocation: artifactLocation,
        })
      ).hardware;
      return hardwares;
    },
    [jsonRequest]
  );

  const listProxyManagers = useCallback<ApiClient['listProxyManagers']>(
    async function listProxyManagers() {
      return (await jsonRequest('/proxy/manager/list')).proxyManagers;
    },
    [jsonRequest]
  );

  const reserveHardware = useCallback<ApiClient['reserveHardware']>(
    function reserveHardware({ hardwareLocation }) {
      return jsonRequest('/hardware/item/select', {
        hardwareLocation,
      });
    },
    [jsonRequest]
  );

  const selectHardware = useCallback<ApiClient['selectHardware']>(
    function selectHardware({ hardwareLocation, proxyManager, proxyParameters }) {
      if (proxyManager) {
        return jsonRequest('/hardware/item/select', {
          hardwareLocation,
          proxyManager,
          proxyParameters: JSON.stringify(proxyParameters),
        });
      }
      return jsonRequest('/hardware/item/select', {
        hardwareLocation: hardwareLocation,
      });
    },
    [jsonRequest]
  );

  const getProxyParametersDefinition = useCallback<ApiClient['getProxyParametersDefinition']>(
    async function getProxyParametersDefinition({ hardwareLocation, proxyManager }) {
      return jsonRequest('/proxy/parameters/get', {
        proxyManager,
        hardwareLocation,
      }).then(({ result }) => result);
    },
    [jsonRequest]
  );

  const getSingleHardware = useCallback<ApiClient['getSingleHardware']>(
    function getSingleHardware({ hardwareLocation }) {
      return jsonRequest('/hardware/item/get', {
        hardwareLocation,
      });
    },
    [jsonRequest]
  );

  const executeHardwareAction = useCallback<ApiClient['executeHardwareAction']>(
    function executeHardwareAction({ hardwareLocation, actionId, optionId }) {
      if (optionId) {
        return jsonRequest('/hardware/action/execute', {
          hardwareLocation,
          actionId,
          optionId,
        }).then(({ result }) => result);
      }
      return jsonRequest('/hardware/action/execute', {
        actionId,
        hardwareLocation,
      });
    },
    [jsonRequest]
  );

  const getHardwareActionResult = useCallback<ApiClient['getHardwareActionResult']>(
    function getHardwareActionResult({ hardwareLocation, actionRunId }) {
      return jsonRequest('/hardware/action/result/get', {
        hardwareLocation,
        actionRunId,
      }).then(({ result }) => result);
    },
    [jsonRequest]
  );

  const downloadProxyManager = useCallback<ApiClient['downloadProxyManager']>(
    function downloadProxyManager() {
      return downloadFile('/download/proxy-manager');
    },
    [downloadFile]
  );

  const listArtifacts = useCallback<ApiClient['listArtifacts']>(
    async function listArtifacts() {
      return (
        await jsonRequest('/artifact/list/distributable', {
          versionLimit: 1, // TODO paging
          matchLimit: 20, // TODO paging
        })
      ).artifacts;
    },
    [jsonRequest]
  );

  const downloadArtifact = useCallback<ApiClient['downloadArtifact']>(
    function downloadArtifact(artifact) {
      return downloadFile('/artifact/download', {
        artifactLocation: artifact.location,
      });
    },
    [downloadFile]
  );

  const getSessionInformation = useCallback<ApiClient['getSessionInformation']>(
    async function getSessionInformation() {
      const response = await jsonRequest('/session/get');
      return {
        realms: response.realms as RealmSession[],
        realmCreate: response.realmCreate as boolean,
        realmAdmin: response.realmAdmin as boolean,
      };
    },
    [jsonRequest]
  );

  const updateSessionGroups = useCallback<ApiClient['updateSessionGroups']>(
    async function updateSessionGroups(realmGroupIds: string[]) {
      await jsonRequest('/session/groups/set', { group: realmGroupIds });
    },
    [jsonRequest]
  );

  const getRealmInformation = useCallback<ApiClient['getRealmInformation']>(
    async function getRealmInformation(realmId: string) {
      let realms: RealmAdmin = (await jsonRequest('/realm/get', { realm: realmId })).realm;
      return realms;
    },
    [jsonRequest]
  );

  const editUserGroups = useCallback<ApiClient['editUserGroups']>(
    async function editUserGroups(realmGroupId: string, userId: string, retirement?: boolean) {
      await jsonRequest('/realm/group/user/edit', {
        group: realmGroupId,
        user: userId,
        retirement: retirement,
      });
    },
    [jsonRequest]
  );

  const removeFromGroup = useCallback<ApiClient['removeFromGroup']>(
    async function removeFromGroup(realmGroupId: string, userId: string) {
      await jsonRequest('/realm/group/user/remove', { group: realmGroupId, user: userId });
    },
    [jsonRequest]
  );

  const listRealms = useCallback<ApiClient['listRealms']>(
    async function listRealms() {
      let realms: RealmAdmin[] = (await jsonRequest('/realm/list')).realms;
      return realms;
    },
    [jsonRequest]
  );

  const addUsersToRealm = useCallback<ApiClient['addUsersToRealm']>(
    async function addUsersToRealm(
      realmId: string,
      userIds: string[],
      groupIds: string[],
      retirement?: ValueRetirement
    ) {
      const payload = JSON.stringify({
        realm: realmId,
        user: userIds,
        group: groupIds,
        retirement,
      });
      await jsonRequest('/realm/user/add', { json: payload });
    },
    [jsonRequest]
  );

  const setRealmUserGroups = useCallback<ApiClient['setRealmUserGroups']>(
    async function setRealmUserGroups(
      realmId: string,
      userId: string,
      groupIds?: string[],
      retirement?: ValueRetirement
    ) {
      const payload = JSON.stringify({
        realm: realmId,
        user: userId,
        group: groupIds,
        retirement,
      });
      await jsonRequest('/realm/user/groups', { json: payload });
    },
    [jsonRequest]
  );

  const listSearchArtifacts = useCallback<ApiClient['listSearchArtifacts']>(
    async function listSearchArtifacts() {
      let artifacts: Artifact[] = (await jsonRequest('/hardware/search/list')).artifacts;
      return artifacts;
    },
    [jsonRequest]
  );

  const hardwareQueueFromSearch = useCallback<ApiClient['hardwareQueueFromSearch']>(
    async function hardwareQueueFromSearch(artifactLocation: string, message?: string) {
      let queue: string = (
        await jsonRequest('/hardware/queue/create/search', {
          artifactLocation,
          message,
        })
      ).queue;
      return queue;
    },
    [jsonRequest]
  );

  const hardwareQueueFromSelection = useCallback<ApiClient['hardwareQueueFromSelection']>(
    async function hardwareQueueFromSelection(hardwareLocation: string[], message?: string) {
      let queue: string = (
        await jsonRequest('/hardware/queue/create/selected', {
          hardwareLocation,
          message,
        })
      ).queue;
      return queue;
    },
    [jsonRequest]
  );

  const getHardwareQueue = useCallback<ApiClient['getHardwareQueue']>(
    async function getHardwareQueue(queueId: string) {
      let queue: HardwareQueue = (await jsonRequest('/hardware/queue/get', { queue: queueId }))
        .queue;
      return queue;
    },
    [jsonRequest]
  );

  const listHardwareQueues = useCallback<ApiClient['listHardwareQueues']>(
    async function listHardwareQueues(all?: boolean) {
      let queue: HardwareQueue[] = (await jsonRequest('/hardware/queue/list', { all })).queue;
      return queue;
    },
    [jsonRequest]
  );

  const removeHardwareQueue = useCallback<ApiClient['removeHardwareQueue']>(
    async function removeHardwareQueue(queueId: string) {
      await jsonRequest('/hardware/queue/remove', { queue: queueId });
    },
    [jsonRequest]
  );

  const reserveHardwareQueue = useCallback<ApiClient['reserveHardwareQueue']>(
    async function reserveHardwareQueue(
      queueId: string,
      message?: string,
      hardwareLocation?: string
    ) {
      let hardware: Hardware['location'] = (
        await jsonRequest('/hardware/queue/reserve', { queue: queueId, message, hardwareLocation })
      ).hardware;
      return hardware;
    },
    [jsonRequest]
  );

  return (
    <ApiClientContext.Provider
      value={{
        freeHardware,
        listProxyManagers,
        listHardware,
        reserveHardware,
        selectHardware,
        executeHardwareAction,
        getSingleHardware,
        getHardwareActionResult,
        downloadProxyManager,
        listArtifacts,
        downloadArtifact,
        authenticateWebSocket,
        connectWebSocket,
        getProxyParametersDefinition,
        getRealmInformation,
        editUserGroups,
        removeFromGroup,
        listRealms,
        addUsersToRealm,
        setRealmUserGroups,
        getSessionInformation,
        updateSessionGroups,
        listSearchArtifacts,
        hardwareQueueFromSearch,
        hardwareQueueFromSelection,
        getHardwareQueue,
        listHardwareQueues,
        removeHardwareQueue,
        reserveHardwareQueue,
      }}
    >
      {props.children}
    </ApiClientContext.Provider>
  );
}
