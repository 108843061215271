import { Alert, AlertIcon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { CompatibilityCodeEnum, ProxyManager } from '../../apiClient/types';

export function CompatibilityAlert(props: { proxyManager: ProxyManager }) {
  return (
    <Alert
      mt={6}
      status={props.proxyManager.compatibilityCode === CompatibilityCodeEnum.OK ? 'info' : 'error'}
    >
      <AlertIcon />
      <FormattedMessage
        id={'connectionmodal_compatibilitycode_' + props.proxyManager.compatibilityCode}
      ></FormattedMessage>
    </Alert>
  );
}
