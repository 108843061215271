import { useState } from 'react';
import { Table, Tbody, Tr, Thead, Th } from '@chakra-ui/react';
import { RealmSectionLayout } from './RealmSectionLayout';
import { ManageUserModal } from './ManageUserModal';
import { UserItem } from './UserItem';
import { GroupAdmin, GroupUserAdmin, RealmAdmin } from '../../apiClient/types';
import { FormattedMessage } from 'react-intl';
import { isAdminOrRoot } from '../realms/RealmHelper';

type Props = {
  users: GroupUserAdmin[];
  groupCount: Map<string, number>;
  groupNames: Map<string, string[]>;
  groups: GroupAdmin[];
  realm: RealmAdmin;
  isMobile: boolean | undefined;
};

const removeBorderForLastRow = {
  '& tr:last-of-type': {
    '& td': {
      borderBottomWidth: 0,
    },
  },
};

export const UserSection = ({ users, groupCount, groupNames, groups, realm, isMobile }: Props) => {
  const [selectedUser, setSelectedUser] = useState<GroupUserAdmin | null>(null);

  const openModal = (user: GroupUserAdmin) => {
    setSelectedUser(user);
  };

  return (
    <RealmSectionLayout title={<FormattedMessage id="realms_common_users" />} pt={4} pb={0}>
      <Table colorScheme="gray" bg="white">
        <Thead>
          <Tr>
            {isMobile ? (
              <Th>
                <FormattedMessage id="realm_table_header_user_information" />
              </Th>
            ) : (
              <>
                <Th>
                  <FormattedMessage id="realm_table_header_id" />
                </Th>
                <Th>
                  <FormattedMessage id="realms_common_groups" />
                </Th>
              </>
            )}
            <Th />
          </Tr>
        </Thead>
        <Tbody css={removeBorderForLastRow}>
          {Array.from(users).map((user) => (
            <UserItem
              key={user.id}
              user={user}
              isMobile={isMobile}
              groupCount={groupCount.get(user.id) ?? 0}
              groupNames={groupNames.get(user.id) ?? []}
              onOpenUser={openModal}
              disabled={!isAdminOrRoot(realm.accessLevel)}
            />
          ))}
        </Tbody>
      </Table>
      {selectedUser && (
        <ManageUserModal
          isOpen={!!selectedUser}
          onClose={() => setSelectedUser(null)}
          user={selectedUser}
          groups={groups}
          realm={realm}
        />
      )}
    </RealmSectionLayout>
  );
};
