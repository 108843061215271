import { Artifact, Hardware } from '../../apiClient/types';

const matchesHardware = (hardware: Hardware, searchString: string): boolean => {
  const searchLower = searchString.toLowerCase();
  const searchEnum = searchString.replace(/\s/g, '_').toUpperCase();

  const searchInObject = (obj: any): boolean => {
    return Object.values(obj).some((value) => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(searchLower) || value === searchEnum;
      } else if (typeof value === 'object' && value !== null) {
        return searchInObject(value);
      }

      return false;
    });
  };

  return searchInObject(hardware);
};

export const displayedHardwares = (
  hardwares: Hardware[],
  predefinedFilter: string,
  includedKeywords: string[],
  excludedKeywords: string[]
): Hardware[] => {
  let filtered = [...hardwares];

  if (predefinedFilter) {
    filtered = filtered.filter((hardware) => matchesHardware(hardware, predefinedFilter));
  }

  includedKeywords.forEach((keyword) => {
    filtered = filtered.filter((hardware) => matchesHardware(hardware, keyword));
  });

  excludedKeywords.forEach((keyword) => {
    filtered = filtered.filter((hardware) => !matchesHardware(hardware, keyword));
  });

  return filtered;
};

export const artifactInLocalStorage = (artifactId: string | null) => {
  return JSON.parse(localStorage.getItem('hardware-searches') || '[]').find(
    (a: Artifact) => a.id === artifactId
  );
};
