import { useToast } from '@chakra-ui/toast';
import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { extractedError, isApiError, isTypeError, isInvaliSessionError } from './apiClient/errors';
import { SessionContext } from './auth/SessionContext';
import { ErrorToastWithPopover } from './toast/ErrorToastWithPopover';

export function useShowError() {
  const toast = useToast();
  const intl = useIntl();
  const { showInvalidSession } = useContext(SessionContext);

  return useCallback(
    function showError(error: unknown, options: { description?: string } = {}) {
      if (isInvaliSessionError(error)) {
        showInvalidSession();
        return;
      }

      let description: string = '';
      let errorDetails: string = '';

      if (options.description) {
        description = options.description;
      } else if (error instanceof Error) {
        description = error.message;
      }

      if (isTypeError(error) && error.message === 'Failed to fetch') {
        errorDetails = intl.formatMessage({ id: 'useshowerror_network_error' });
        description = error.message;
      } else if (isApiError(error)) {
        errorDetails =
          extractedError(error) || intl.formatMessage({ id: 'useshowerror_no_error_details' });
      }

      isTypeError(error) && console.error('Unexpected error', error);

      toast({
        duration: 9000,
        isClosable: true,
        render: () => (
          <ErrorToastWithPopover
            description={description}
            errorStack={errorDetails}
            title={
              isApiError(error)
                ? intl.formatMessage({ id: 'useshowerror_request_failed' })
                : intl.formatMessage({ id: 'useshowerror_error' })
            }
          />
        ),
      });
    },
    [showInvalidSession, toast, intl]
  );
}
