import {
  HardwareProxyParameters,
  HardwareProxyParametersDefinitionView,
  InterfaceProxyParametersDefinitionView,
  ProxyParameterDefinition,
} from '../../apiClient/types';
import { Text } from '@chakra-ui/layout';
import { FormControl, Checkbox, FormLabel, Select, Input } from '@chakra-ui/react';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { produce } from 'immer';

const initialValue = { interfaces: [] };

function mergeChangedParameter(
  prev: HardwareProxyParameters | undefined,
  interfaceId: string,
  parameterKey: string,
  parameterValue: string
): HardwareProxyParameters {
  return produce(prev || initialValue, (draft) => {
    let proxyParametersInterface = draft.interfaces.find(({ id }) => id === interfaceId);

    if (!proxyParametersInterface) {
      proxyParametersInterface = { id: interfaceId, parameters: [] };
      draft.interfaces.push(proxyParametersInterface);
    }

    let parameter = proxyParametersInterface.parameters.find(({ key }) => key === parameterKey);

    if (parameter) {
      parameter.value = parameterValue;
    } else {
      proxyParametersInterface.parameters.push({ key: parameterKey, value: parameterValue });
    }
  });
}

export function ProxyParametersForm(props: {
  definition: HardwareProxyParametersDefinitionView;
  value: HardwareProxyParameters | undefined;
  onChange: (newValue: HardwareProxyParameters) => void;
}) {
  function handleChange(
    interfaceId: InterfaceProxyParametersDefinitionView['id'],
    parameterKey: ProxyParameterDefinition['key'],
    parameterValue: string
  ) {
    props.onChange(mergeChangedParameter(props.value, interfaceId, parameterKey, parameterValue));
  }

  return (
    <Fragment>
      {props.definition && (
        <Text as="b" my={4}>
          {props.definition.productName} - {props.definition.hardwareName}
        </Text>
      )}

      {props.definition.interfaces.map(({ name, id, parameters }) => (
        <Fragment key={id}>
          <Text mt={2} color={'gray.500'}>
            {name} <FormattedMessage id="connectionmodal_interface_settings" />
          </Text>
          {parameters.map(({ key, label, type, defaultValue, discreet }) => (
            <FormControl my={2} key={key}>
              {type === 'BOOLEAN' ? (
                <Checkbox
                  defaultChecked={defaultValue === 'true'}
                  onChange={(event) => handleChange(id, key, event.target.checked.toString())}
                  defaultValue={defaultValue}
                >
                  {label}
                </Checkbox>
              ) : (
                <>
                  <FormLabel>{label}</FormLabel>
                  <FormControl>
                    {discreet ? (
                      <Select
                        onChange={(event) => handleChange(id, key, event.target.value)}
                        defaultValue={defaultValue}
                      >
                        {discreet.map(({ id, display }) => (
                          <option key={id} value={id}>
                            {display}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        id={id + ',' + key}
                        onChange={(event) => handleChange(id, key, event.target.value)}
                        defaultValue={defaultValue}
                      />
                    )}
                  </FormControl>
                </>
              )}
            </FormControl>
          ))}
        </Fragment>
      ))}
    </Fragment>
  );
}
