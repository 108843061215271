import { EditIcon } from '@chakra-ui/icons';
import { Button, IconButton, Td, Text, Tr, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { GroupAdmin } from '../../apiClient/types';

type Props = {
  group: GroupAdmin;
  onGroupOpen: (group: GroupAdmin) => void;
  disabled: boolean;
  isMobile: boolean | undefined;
};

export const GroupItem = ({ group, onGroupOpen, disabled, isMobile }: Props) => {
  const isDesktop = !isMobile;

  return (
    <Tr onClick={() => onGroupOpen(group)} _hover={{ bgColor: 'gray.50', cursor: 'pointer' }}>
      <Td>
        <VStack align="start">
          <Text>{group.name}</Text>
          {isMobile && <Text color="gray.500">{group.description}</Text>}
        </VStack>
      </Td>
      {isDesktop && <Td>{group.description}</Td>}
      <Td>
        {!group.users ? (
          <FormattedMessage id="realm_table_no_users" />
        ) : group.users.length === 1 ? (
          <FormattedMessage id="realm_table_single_user" />
        ) : (
          <FormattedMessage id="realm_table_users" values={{ count: group.users.length }} />
        )}
      </Td>
      <Td textAlign="end">
        {isMobile ? (
          <IconButton
            disabled={disabled}
            onClick={() => onGroupOpen(group)}
            variant="ghost"
            mr="-4"
            icon={<EditIcon />}
            aria-label="Edit user"
          />
        ) : (
          <Button
            disabled={disabled}
            onClick={() => onGroupOpen(group)}
            variant="ghost"
            leftIcon={<EditIcon />}
            marginY="-2.5"
          >
            <FormattedMessage id="realm_table_manage" />
          </Button>
        )}
      </Td>
    </Tr>
  );
};
