import { ReactNode } from 'react';
import { Box } from '@chakra-ui/layout';
import { Table, TableCaption, Tbody, Td, Th, Tr } from '@chakra-ui/table';
import { Text, Stack } from '@chakra-ui/react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { AttributeTypeEnum } from '../apiClient/types';

type Props = {
  attributes: Array<[string, string, string?, AttributeTypeEnum?]>;
  caption?: ReactNode;
};

export function AttributesTable(props: Props) {
  function getCellElement(value: string | number | Date, type: AttributeTypeEnum | undefined) {
    if (Array.isArray(value))
      return value.map((line, index) => (
        <Text key={index}>{getValueElement(line, undefined)}</Text>
      ));
    else return getValueElement(value, type);
  }

  function getValueElement(value: string | number | Date, type: AttributeTypeEnum | undefined) {
    if (!value) {
      return (
        <Stack>
          <Text>
            <Text as="mark">Missing Attribute Value</Text>
          </Text>
        </Stack>
      );
    }
    if (AttributeTypeEnum.DATETIME === type) {
      return (
        <Text>
          <FormattedDate value={value} />
          &nbsp;
          <FormattedTime value={value} />
        </Text>
      );
    }
    if (typeof value === 'string') return value;
    if (typeof value === 'boolean' || typeof value === 'number')
      return <Text as="i">{value.toString()}</Text>;
    return (
      <Stack>
        <Text>
          <Text as="mark">
            Unsupported Attribute: type={typeof value}, array={Array.isArray(value).toString()}
          </Text>
        </Text>
        <Text>value={value.toString()}</Text>
      </Stack>
    );
  }

  if (props.attributes.length === 0) {
    return null;
  }
  return (
    <Box borderWidth="1px" borderRadius="xl">
      <Table size="sm" colorScheme="gray">
        {props.caption && <TableCaption placement="top">{props.caption}</TableCaption>}
        <Tbody>
          {props.attributes.map(([headerValue, dataValue, key, type]) => (
            <Tr key={key ?? headerValue}>
              <Th>{headerValue}</Th>
              <Td>{getCellElement(dataValue, type)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
