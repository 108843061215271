import { useNavigate } from 'react-router-dom';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { PageHeading } from '../../layout/PageHeading';
import { BackButton } from '../../layout/BackButton';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { FormattedMessage } from 'react-intl';

type Props = {
  emptyContentLabel?: string | React.ReactElement;
  heading?: string | React.ReactElement;
  description?: string | React.ReactElement;
  buttonLabel?: string;
  hasNavigation?: boolean;
  includesHeading?: boolean;
};

export const EmptyContent = ({
  emptyContentLabel,
  heading,
  description,
  buttonLabel,
  hasNavigation = false,
  includesHeading = true,
}: Props) => {
  const navigate = useNavigate();
  if (includesHeading) {
    return (
      <HStack justify="space-between">
        <VStack align="top" w="full">
          <HStack>
            <PageHeading heading={heading} description={description} />
          </HStack>
          <HStack pt={2} align="start">
            <BackButton />
          </HStack>
          <VStack h="15vh" align="center" textAlign="center" justify="center" bg="white" p={2}>
            <WarningTwoIcon boxSize={8} color="gray.400" />
            <Text>{emptyContentLabel || <FormattedMessage id="empty_content_label" />}</Text>
            {hasNavigation && (
              <Button size="md" variant="ghost" onClick={() => navigate(-1)}>
                {buttonLabel || <FormattedMessage id="return_to_previous_page" />}
              </Button>
            )}
          </VStack>
        </VStack>
      </HStack>
    );
  }
  return (
    <VStack h="15vh" align="center" justify="center" bg="white">
      <WarningTwoIcon boxSize={8} color="gray.400" />
      <Text>{emptyContentLabel || <FormattedMessage id="empty_content_label" />}</Text>
      {hasNavigation && (
        <Button size="md" variant="ghost" onClick={() => navigate(-1)}>
          {buttonLabel || <FormattedMessage id="return_to_previous_page" />}
        </Button>
      )}
    </VStack>
  );
};
