import { useEffect, useState } from 'react';
import { Checkbox, HStack, Text, VStack } from '@chakra-ui/react';
import { UserModalAccordion } from './UserModalAccordion';
import { CustomDatePicker } from './CustomDatePicker';
import { RealmModalLayout } from './RealmModalLayout';
import { useShowError } from '../../useShowError';
import { useRealms } from '../realms/useRealms';
import { GroupAdmin, GroupUserAdmin, RealmAdmin, ValueRetirement } from '../../apiClient/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRealmInformation } from '../realms/useRealmInformation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  user: GroupUserAdmin;
  groups: GroupAdmin[];
  realm: RealmAdmin;
};

export const ManageUserModal = ({ isOpen, onClose, user, groups, realm }: Props) => {
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isRetiring, setIsRetiring] = useState<boolean>(false);
  const [isActivating, setIsActivating] = useState<boolean>(false);

  const { setUserGroups } = useRealms();
  const { refetch } = useRealmInformation(realm.id);

  const showError = useShowError();
  const intl = useIntl();

  useEffect(() => {
    const userGroupIds = groups
      .filter((group) => group.users?.some((u) => u.id === user.id))
      .map((group) => group.id);
    setSelectedGroups(userGroupIds);
  }, [user, groups]);

  const handleCheckboxChange = (groupId: string, isChecked: boolean) => {
    setSelectedGroups((prev) => {
      if (isChecked) {
        return [...prev, groupId];
      } else {
        return prev.filter((id) => id !== groupId);
      }
    });
  };

  const handleRetirementCheckboxChange = () => {
    setIsRetiring(true);
    setIsActivating(false);
  };

  const handleActivationCheckboxChange = () => {
    setIsActivating(true);
    setIsRetiring(false);
  };

  const handleSetGroups = () => {
    const formattedGroupIds = selectedGroups.map((groupId) => groupId.split(':')[1]);

    const toLocalISOString = (date: Date) => {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    };

    let retiredStatus = user.retirement?.retired;
    if (isRetiring) retiredStatus = true;
    if (isActivating) retiredStatus = false;

    const retirement: ValueRetirement = {
      retired: retiredStatus,
      validFrom: startDate ? toLocalISOString(new Date(startDate)) : undefined,
      validUntil: endDate ? toLocalISOString(new Date(endDate)) : undefined,
    };

    setUserGroups.mutate(
      { realmId: realm.id, userId: user.id, groupIds: formattedGroupIds, retirement },
      {
        onSuccess: () => {
          refetch();
          onClose();
        },
        onError: (error) => {
          showError(error, {
            description: intl.formatMessage({ id: 'realm_set_user_groups_error' }),
          });
        },
      }
    );
  };

  return (
    <RealmModalLayout isOpen={isOpen} onClose={onClose} header={user.id} onSubmit={handleSetGroups}>
      <UserModalAccordion title={<FormattedMessage id="realms_common_groups" />} isFocused>
        {groups.map((group) => (
          <HStack key={group.id} w="full">
            <Checkbox
              value={group.id}
              isChecked={selectedGroups.includes(group.id)}
              onChange={(e) => handleCheckboxChange(group.id, e.target.checked)}
              _hover={{ bg: 'gray.50' }}
              p={3}
              borderRadius="lg"
              w="full"
            >
              {group.name}
            </Checkbox>
          </HStack>
        ))}
      </UserModalAccordion>
      <UserModalAccordion title={'Retirement status'}>
        <VStack w="full" align="start" py={2}>
          <Text px={3}>
            <FormattedMessage
              id="realm_user_status"
              values={{ status: user.retirement ? 'Retired' : 'Active' }}
            />
          </Text>
          {user.retirement?.retired ? (
            <Checkbox
              _hover={{ bg: 'gray.50' }}
              p={3}
              borderRadius="lg"
              w="full"
              isChecked={isActivating}
              onChange={handleActivationCheckboxChange}
            >
              <FormattedMessage id="realm_user_set_active" />
            </Checkbox>
          ) : (
            <Checkbox
              _hover={{ bg: 'gray.50' }}
              p={3}
              borderRadius="lg"
              w="full"
              isChecked={isRetiring}
              onChange={handleRetirementCheckboxChange}
            >
              <FormattedMessage id="realm_user_set_retired" />
            </Checkbox>
          )}
          <VStack w="full" spacing={2}>
            <CustomDatePicker
              label={<FormattedMessage id="realm_add_user_validFrom" />}
              date={startDate}
              onChangeDate={setStartDate}
            />
            <CustomDatePicker
              label={<FormattedMessage id="realm_add_user_validUntil" />}
              date={endDate}
              onChangeDate={setEndDate}
            />
          </VStack>
        </VStack>
      </UserModalAccordion>
    </RealmModalLayout>
  );
};
