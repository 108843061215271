import { useState } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { RealmModalLayout } from './RealmModalLayout';
import { ManageGroupUserItem } from './ManageGroupUserItem';
import { GroupAdmin, GroupUserAdmin } from '../../apiClient/types';
import { FormattedMessage } from 'react-intl';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  group: GroupAdmin;
  realmId: string;
};

export const ManageGroupModal = ({ isOpen, onClose, group, realmId }: Props) => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [currentUsers, setCurrentUsers] = useState<GroupUserAdmin[]>(group.users ?? []);

  const formattedId = group.id.split(':')[1];
  const capitalizedId = formattedId.charAt(0).toUpperCase() + formattedId.slice(1);

  const handleRemoveUser = (userId: string) => {
    setSelectedUserId(userId);
  };

  const userCountHeader =
    !currentUsers || currentUsers.length === 0 ? (
      <FormattedMessage id="realm_group_header_count_empty" />
    ) : currentUsers.length === 1 ? (
      <FormattedMessage id="realm_group_header_count_single" />
    ) : (
      <FormattedMessage id="realm_group_header_count" values={{ count: currentUsers.length }} />
    );

  return (
    <RealmModalLayout isOpen={isOpen} onClose={onClose} header={capitalizedId} size="lg">
      <Table colorScheme="gray" bg="white" w="full" mb={8}>
        <Thead>
          <Tr>
            <Th>
              <FormattedMessage id="realm_group_header_name" />
            </Th>
            <Th textAlign="end">
              <FormattedMessage id="realms_common_description" />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{group.name}</Td>
            <Td textAlign="end">{group.description}</Td>
          </Tr>
        </Tbody>
      </Table>
      <Table colorScheme="gray" bg="white">
        <Thead>
          <Tr>
            <Th borderBottomWidth={group.users ? 1 : 0}>{userCountHeader}</Th>
          </Tr>
        </Thead>
        {group.users && (
          <Tbody>
            {currentUsers.map((user) => (
              <ManageGroupUserItem
                key={user.id}
                user={user}
                isSelected={user.id === selectedUserId}
                realmId={realmId}
                realmGroupId={group.id}
                onUserListUpdate={setCurrentUsers}
                onChangeSelection={(isSelected) => {
                  isSelected ? setSelectedUserId(user.id) : setSelectedUserId(null);
                }}
                onRemoveUser={() => {
                  handleRemoveUser(user.id);
                }}
              />
            ))}
          </Tbody>
        )}
      </Table>
    </RealmModalLayout>
  );
};
