import { AddIcon, RepeatIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import { BadgeVariant, RealmWarningBadge } from './RealmWarningBadge';
import { PageHeading } from '../../layout/PageHeading';
import { BackButton } from '../../layout/BackButton';
import { Layout } from '../../layout/Layout';
import { InformationSection } from './InformationSection';
import { GroupSection } from './GroupSection';
import { AddUserModal } from './AddUserModal';
import { UserSection } from './UserSection';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getUniqueUsers, getUserGroupCount, getUserGroups } from './RealmManagementHelper';
import { isAdminOrRoot } from '../realms/RealmHelper';
import { useRealmInformation } from '../realms/useRealmInformation';
import { useIsMobile } from '../../useIsMobile';
import { Loader } from '../../layout/common/Loader';
import { EmptyContent } from '../../layout/common/EmptyContent';

/**
 * Realm management page.
 *
 * Displays the realm information, groups and users.
 */

export const RealmManagement = () => {
  const { realmId } = useParams();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    realmInformation: realmData,
    refetch,
    isLoading,
    isError,
  } = useRealmInformation(realmId || '');

  const intl = useIntl();
  const isMobile = useIsMobile();

  if (!realmId) {
    throw new Error('Component can only be used as a route element with the required params.');
  }

  let content;
  if (isLoading || !realmData) {
    content = <Loader label={<FormattedMessage id="commons_loading" />} />;
  } else if (isError || !realmData) {
    content = (
      <EmptyContent
        emptyContentLabel={<FormattedMessage id="realm_error" />}
        includesHeading={false}
      />
    );
  } else {
    content = (
      <>
        <InformationSection realmData={realmData} isMobile={isMobile} />
        <GroupSection realmData={realmData} isMobile={isMobile} />
        <UserSection
          users={getUniqueUsers(realmData.groups)}
          groupCount={getUserGroupCount(realmData.groups)}
          groupNames={getUserGroups(realmData.groups)}
          groups={realmData.groups}
          realm={realmData}
          isMobile={isMobile}
        />
      </>
    );
  }

  return (
    <Layout>
      <HStack align="top">
        <PageHeading
          heading={
            <FormattedMessage
              id="realm_title"
              values={{ realmName: realmData ? realmData.name : 'realm' }}
            />
          }
          description={<FormattedMessage id="realm_description" />}
        />
        <IconButton
          isRound
          isLoading={isLoading}
          variant="ghost"
          onClick={() => refetch()}
          icon={<RepeatIcon />}
          aria-label={intl.formatMessage({ id: 'commons_refresh' })}
        />
      </HStack>
      <HStack align="start">
        <BackButton />
        <Button
          size="sm"
          leftIcon={<AddIcon />}
          onClick={onOpen}
          disabled={!isAdminOrRoot(realmData?.accessLevel)}
        >
          <FormattedMessage id="realm_add_user" />
        </Button>
        {realmData && !isAdminOrRoot(realmData.accessLevel) && (
          <RealmWarningBadge
            label={<FormattedMessage id="realm_access_error" />}
            variant={BadgeVariant.WARNING}
          />
        )}
      </HStack>

      <Box bg="white" w="full">
        {content}
      </Box>

      {realmData && (
        <AddUserModal
          isOpen={isOpen}
          onClose={onClose}
          realmId={realmId}
          groups={realmData.groups}
        />
      )}
    </Layout>
  );
};
