import { Icon, Badge, HStack, Text } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { Hardware, QueueStatusEnum } from '../../apiClient/types';
import { useIntl } from 'react-intl';

type Props = {
  queueStatus?: QueueStatusEnum;
  hardware?: Hardware;
  onClick?: () => void;
  clickable?: boolean;
};

export function QueueStatusBadge({ queueStatus, hardware, onClick, clickable }: Props) {
  const intl = useIntl();
  const queueCount = hardware?.status.reservation.queueCount;
  const displayQueueCount = queueCount && queueCount > 1 ? `${queueCount} | ` : '';

  const getBadgeColorScheme = () => {
    switch (queueStatus) {
      case QueueStatusEnum.PENDING_FOR_ME:
        return 'brand';
      case QueueStatusEnum.FIRST_FOR_ME:
        return 'brand';
      case QueueStatusEnum.QUEUED_FOR_ME:
        return 'purple';
      case QueueStatusEnum.PENDING:
      case QueueStatusEnum.FIRST:
      case QueueStatusEnum.QUEUED:
        return 'teal';
      default:
        return 'gray';
    }
  };

  const getBadgeText = () => {
    switch (queueStatus) {
      case QueueStatusEnum.PENDING_FOR_ME:
        return intl.formatMessage({ id: 'queue_status_pending_for_me' });
      case QueueStatusEnum.FIRST_FOR_ME:
        return intl.formatMessage({ id: 'queue_status_first_for_me' });
      case QueueStatusEnum.QUEUED_FOR_ME:
        return intl.formatMessage({ id: 'queue_status_queued_for_me' });
      case QueueStatusEnum.PENDING:
        return intl.formatMessage({ id: 'queue_status_pending' });
      case QueueStatusEnum.FIRST:
        return intl.formatMessage({ id: 'queue_status_first' });
      case QueueStatusEnum.QUEUED:
        return intl.formatMessage({ id: 'queue_status_queued' });
      default:
        return '';
    }
  };

  const badgeColorScheme = getBadgeColorScheme();
  const badgeText = getBadgeText();

  if (!badgeText) return null;

  if (clickable) {
    return (
      <Badge variant="solid" colorScheme={badgeColorScheme} cursor="pointer" onClick={onClick}>
        <HStack spacing={1}>
          {queueCount && queueCount > 1 && <Icon boxSize={'11px'} as={StarIcon} />}
          <Text>{`${displayQueueCount}${badgeText}`}</Text>
        </HStack>
      </Badge>
    );
  }

  return (
    <Badge variant="solid" colorScheme={badgeColorScheme}>
      <HStack spacing={1}>
        {queueCount && queueCount > 1 && <Icon boxSize={'11px'} as={StarIcon} />}
        <Text>{`${displayQueueCount}${badgeText}`}</Text>
      </HStack>
    </Badge>
  );
}
