import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { type PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export function AuthRequired(props: PropsWithChildren<{}>) {
  const location = useLocation();
  return (
    <>
      <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to="/login" state={{ from: location.pathname }} />
      </UnauthenticatedTemplate>
    </>
  );
}
