import { IntlShape } from 'react-intl';
import {
  INSTALL_FIRMWARE,
  LONG_WAIT,
  PING,
  ERROR_CODE,
  FOLDERS,
  VERSION,
  TEMP_FILE,
  MISSING_EXECUTABLE,
} from './HardwareActionEnums';
import type { Action } from '../apiClient/types';

type HardwareActionMessages = {
  description: string;
  execute: string;
  cancel: string;
  error: string;
};

export function getHardwareActionMessages(hardwareActionId: Action['id'], intl: IntlShape) {
  const hardwareActionMessages: Record<Action['id'], HardwareActionMessages> = {
    [PING]: {
      description: intl.formatMessage({ id: 'ping_description' }),
      execute: intl.formatMessage({ id: 'ping_ping' }),
      cancel: intl.formatMessage({ id: 'commons_cancel' }),
      error: intl.formatMessage({ id: 'ping_ping_destination_error' }),
    },
    [INSTALL_FIRMWARE]: {
      description: intl.formatMessage({ id: 'installfirmware_description' }),
      execute: intl.formatMessage({ id: 'installfirmware_install' }),
      cancel: intl.formatMessage({ id: 'commons_cancel' }),
      error: intl.formatMessage({ id: 'installfirmware_install_error' }),
    },
    [LONG_WAIT]: {
      description: intl.formatMessage({ id: 'longwait_description' }),
      execute: intl.formatMessage({ id: 'commons_execute' }),
      cancel: intl.formatMessage({ id: 'commons_cancel' }),
      error: intl.formatMessage({ id: 'longwait_error' }),
    },
    [ERROR_CODE]: {
      description: intl.formatMessage({ id: 'errorcode_description' }),
      execute: intl.formatMessage({ id: 'commons_execute' }),
      cancel: intl.formatMessage({ id: 'commons_cancel' }),
      error: intl.formatMessage({ id: 'errorcode_error' }),
    },
    [FOLDERS]: {
      description: intl.formatMessage({ id: 'folders_description' }),
      execute: intl.formatMessage({ id: 'commons_execute' }),
      cancel: intl.formatMessage({ id: 'commons_cancel' }),
      error: intl.formatMessage({ id: 'folders_error' }),
    },
    [VERSION]: {
      description: intl.formatMessage({ id: 'version_description' }),
      execute: intl.formatMessage({ id: 'commons_execute' }),
      cancel: intl.formatMessage({ id: 'commons_cancel' }),
      error: intl.formatMessage({ id: 'version_error' }),
    },
    [TEMP_FILE]: {
      description: intl.formatMessage({ id: 'tempfile_description' }),
      execute: intl.formatMessage({ id: 'tempfile_create' }),
      cancel: intl.formatMessage({ id: 'commons_cancel' }),
      error: intl.formatMessage({ id: 'tempfile_error' }),
    },
    [MISSING_EXECUTABLE]: {
      description: intl.formatMessage({ id: 'missingexecutable_description' }),
      execute: intl.formatMessage({ id: 'commons_execute' }),
      cancel: intl.formatMessage({ id: 'commons_cancel' }),
      error: intl.formatMessage({ id: 'missingexecutable_error' }),
    },
  };

  if (!hardwareActionId || !hardwareActionMessages[hardwareActionId]) {
    return {
      description: intl.formatMessage({ id: 'hardwareaction_generic_description' }),
      execute: intl.formatMessage({ id: 'commons_execute' }),
      cancel: intl.formatMessage({ id: 'commons_cancel' }),
      error: intl.formatMessage({ id: 'hardwareaction_generic_error' }),
    };
  }
  return hardwareActionMessages[hardwareActionId];
}
