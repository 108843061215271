import { useState } from 'react';
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { RealmSectionLayout } from './RealmSectionLayout';
import { ManageGroupModal } from './ManageGroupModal';
import { GroupItem } from './GroupItem';
import { GroupAdmin, RealmAdmin } from '../../apiClient/types';
import { FormattedMessage } from 'react-intl';
import { isAdminOrRoot } from '../realms/RealmHelper';

export const GroupSection = ({
  realmData,
  isMobile,
}: {
  realmData: RealmAdmin;
  isMobile: boolean | undefined;
}) => {
  const [selectedGroup, setSelectedGroup] = useState<GroupAdmin | null>(null);

  const openModal = (group: GroupAdmin) => {
    setSelectedGroup(group);
  };

  const isDesktop = !isMobile;

  return (
    <RealmSectionLayout title={<FormattedMessage id="realms_common_groups" />}>
      <Table colorScheme="gray" bg="white">
        <Thead>
          <Tr>
            <Th>
              <FormattedMessage id="realm_table_header_name" />
            </Th>
            {isDesktop && (
              <Th>
                <FormattedMessage id="realms_common_description" />
              </Th>
            )}
            <Th>
              <FormattedMessage id="realms_common_users" />
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {realmData.groups.map((group) => (
            <GroupItem
              key={group.id}
              group={group}
              isMobile={isMobile}
              onGroupOpen={openModal}
              disabled={!isAdminOrRoot(realmData.accessLevel)}
            />
          ))}
        </Tbody>
      </Table>
      {selectedGroup && (
        <ManageGroupModal
          isOpen={!!selectedGroup}
          onClose={() => setSelectedGroup(null)}
          group={selectedGroup}
          realmId={realmData.id}
        />
      )}
    </RealmSectionLayout>
  );
};
