import { Badge, BadgeProps } from '@chakra-ui/layout';
import { ActionResult, ProgressMessageEnum } from '../apiClient/types';
import { FormattedMessage } from 'react-intl';

export function HardwareActionProgressBadge(props: {
  result: ActionResult;
  fontSize?: BadgeProps['fontSize'];
}) {
  if (!props.result.progress || !props.result.progress.status) return null;
  switch (props.result.progress.status) {
    case ProgressMessageEnum.COMPLETE:
      return (
        <Badge variant="outline" colorScheme="green" fontSize={props.fontSize}>
          <FormattedMessage id="hardwareactionresultbadge_complete" />
        </Badge>
      );
    case ProgressMessageEnum.IN_PROGRESS:
      return (
        <Badge variant="outline" colorScheme="yellow" fontSize={props.fontSize}>
          <FormattedMessage id="hardwareactionresultbadge_inprogress" />
        </Badge>
      );
    case ProgressMessageEnum.FAILED:
      return (
        <Badge variant="outline" colorScheme="red" fontSize={props.fontSize}>
          <FormattedMessage id="hardwareactionresultbadge_failed" />
        </Badge>
      );
    case ProgressMessageEnum.CANCELLED:
      return (
        <Badge variant="outline" colorScheme="gray" fontSize={props.fontSize}>
          <FormattedMessage id="hardwareactionresultbadge_cancelled" />
        </Badge>
      );
    default:
      return (
        <Badge variant="outline" colorScheme="gray" fontSize={props.fontSize}>
          <FormattedMessage id="hardwareactionresultbadge_unknown" />
        </Badge>
      );
  }
}
