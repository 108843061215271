import { useParams } from 'react-router-dom';
import { Layout } from '../../layout/Layout';
import { QueueDetailContent } from './QueueDetailContent';
import { useEffect, useState } from 'react';
import { useQueueHardware } from './useQueueHardware';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../layout/common/Loader';
import { HardwareQueue } from '../../apiClient/types';
import { FormattedMessage } from 'react-intl';

export const QueueDetailPage = () => {
  const { hardwareLocation } = useParams();
  if (!hardwareLocation) {
    throw new Error('Component can only be used as a route element with the required params.');
  }

  const navigate = useNavigate();
  const { loadHardwareQueues, hardwareQueues, isLoading: isLoadingQueue } = useQueueHardware();
  const [queuesForCurrentHardware, setQueuesForCurrentHardware] = useState<
    HardwareQueue[] | undefined
  >([]);

  useEffect(() => {
    loadHardwareQueues({ loadAllQueues: true });
  }, [loadHardwareQueues]);

  useEffect(() => {
    const filteredHardware = hardwareQueues?.filter((queue) =>
      queue.hardware?.some((hardware) => hardware.location === hardwareLocation)
    );

    setQueuesForCurrentHardware(filteredHardware);

    if (!isLoadingQueue && filteredHardware?.length === 0) {
      navigate('/hardware');
    }
  }, [hardwareQueues, isLoadingQueue, hardwareLocation, navigate]);

  let content;
  if (isLoadingQueue) {
    content = <Loader label={<FormattedMessage id="queue_details_loading" />} />;
  } else if (queuesForCurrentHardware && queuesForCurrentHardware.length > 0) {
    content = (
      <QueueDetailContent
        queues={queuesForCurrentHardware}
        refresh={() => loadHardwareQueues({ loadAllQueues: true })}
        hardwareLocation={hardwareLocation}
        isLoading={isLoadingQueue}
      />
    );
  }

  return <Layout>{content}</Layout>;
};
