import { Box, Flex, Text } from '@chakra-ui/react';

type Props = {
  title: string | React.ReactNode;
  children: React.ReactNode;
  pt?: number | string;
  pb?: number | string;
  py?: number | string;
  controls?: React.ReactNode;
};

/**
 * Layout for a section in the RealmManagement component.
 * @param title - title of the section
 * @param children - content of the section
 * @param pt - optional padding top
 * @param pb - optional padding bottom
 * @param py - optional padding top and bottom
 * @param controls - optional controls (e.g. button) for the section
 */

export const RealmSectionLayout = ({ title, children, pt, pb, py = 4, controls }: Props) => {
  const padding = {
    pt: pt ?? py,
    pb: pb ?? py,
  };

  return (
    <Box {...padding}>
      <Flex justifyContent="space-between" alignItems="start">
        <Text
          pl={4}
          pb={4}
          fontSize="14px"
          fontWeight="bold"
          textTransform="uppercase"
          color="gray.600"
          textAlign="start"
        >
          {title}
        </Text>
        {controls}
      </Flex>
      {children}
    </Box>
  );
};
