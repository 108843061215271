import { Button, ButtonGroup, Tooltip } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { AutoResizeTextArea } from './AutoResizeTextArea';

type Props = {
  value: string | number;
};

export function ClickToCopyTextArea(props: Props) {
  const intl = useIntl();
  const [copied, setCopied] = useState<boolean>(false);

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(props.value.toString()).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 4000);
    });
  }

  return (
    <>
      <ButtonGroup
        style={{
          justifyContent: 'right',
          display: 'flex',
          marginRight: 4,
          marginBottom: 8,
        }}
      >
        <Tooltip
          label={!copied && <FormattedMessage id="hardwareactionlogpage_tooltip_copytoclipboard" />}
          fontSize="md"
        >
          <Button
            leftIcon={<FiCopy />}
            variant="outline"
            color="blue.300"
            onClick={handleCopyToClipboard}
          >
            {copied ? (
              <FormattedMessage id="hardwareactionlogpage_copied" />
            ) : (
              <FormattedMessage id="hardwareactionlogpage_copy" />
            )}
          </Button>
        </Tooltip>
      </ButtonGroup>

      <AutoResizeTextArea
        placeholder={intl.formatMessage({
          id: 'hardwareactionlogpage_textarea_placeholder_helper',
        })}
        value={props.value.toString()}
      />
    </>
  );
}
