import { type ReactNode } from 'react';
import { Heading, Stack, Text } from '@chakra-ui/layout';
// @ts-expect-error
import { CappedText, CappedHeading } from '@ceteio/chakra-capsize';
import { useIsMobile } from '../useIsMobile';

type Props = {
  heading?: ReactNode;
  description?: ReactNode;
};

export function PageHeading(props: Props) {
  const isMobile = useIsMobile();

  return (
    <Stack spacing={4}>
      {isMobile ? (
        <>
          <Heading as="h3" size="lg" lineHeight={1}>
            {props.heading}
          </Heading>
          <Text lineHeight={1}>{props.description}</Text>
        </>
      ) : (
        <>
          <CappedHeading as="h3" size="lg">
            {props.heading}
          </CappedHeading>
          <CappedText>{props.description}</CappedText>
        </>
      )}
    </Stack>
  );
}
