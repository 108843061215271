import { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';
import type { Artifact, Hardware } from '../../apiClient/types';
import { useWebSocket } from '../../webSocket/useWebSocket';
import { extractedError, isApiError } from '../../apiClient/errors';

export function useLoadHardware() {
  const [hardwares, setHardwares] = useState<Hardware[]>();
  const [hardwareSearches, setHardwareSearches] = useState<Artifact[]>();
  const [isLoading, setLoading] = useState(true);
  const [isLoadingSearches, setLoadingSearches] = useState(true);
  const { listHardware, listSearchArtifacts, getSingleHardware } = useApiClient();
  const { webSocket, initialize, latestWsMessage, sendMessage } = useWebSocket();
  const showError = useShowError();
  const intl = useIntl();

  const loadHardware = useCallback(
    async function (artifactLocation?: string) {
      setLoading(true);
      try {
        setHardwares(await listHardware(artifactLocation));
        if (!webSocket) {
          initialize();
        }
      } catch (error: unknown) {
        if (isApiError(error)) {
          const errorMessage = extractedError(error) || error.message;
          showError(error, {
            description: intl.formatMessage(
              { id: 'hardwarepage_hardware_list_error' },
              { error: errorMessage }
            ),
          });
        } else {
          console.error('Unexpected error', error);
        }
      } finally {
        setLoading(false);
      }
    },
    [showError, listHardware, intl, webSocket, initialize]
  );

  const loadHardwareSearches = useCallback(
    async function ({ forceRefresh } = { forceRefresh: false }) {
      if (!forceRefresh) {
        const cachedData = localStorage.getItem('hardware-searches');
        if (cachedData) {
          setHardwareSearches(JSON.parse(cachedData));
          return;
        }
      }

      setLoadingSearches(true);
      try {
        const newSearches = await listSearchArtifacts();
        setHardwareSearches(newSearches);
        localStorage.setItem('hardware-searches', JSON.stringify(newSearches));
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'hardwarepage_hardware_searches_error' }),
        });
      }
      setLoadingSearches(false);
    },
    [showError, listSearchArtifacts, intl]
  );

  const reloadSingleHardware = useCallback(
    async function ({ hardwareLocation }: { hardwareLocation: Hardware['location'] }) {
      const { hardware } = await getSingleHardware({ hardwareLocation });

      setHardwares((prevHardwares) =>
        prevHardwares?.map((prevHardware) =>
          prevHardware.location === hardwareLocation ? { ...hardware } : prevHardware
        )
      );
    },
    [getSingleHardware]
  );

  useEffect(() => {
    if (webSocket && hardwares && hardwares.length > 0) {
      const locations = hardwares.map((hardware) => hardware.location);
      const message = `Hardware { "hardware": ${JSON.stringify(locations)} }`;
      sendMessage(message);
    }
  }, [webSocket, hardwares, sendMessage]);

  useEffect(() => {
    if (
      latestWsMessage &&
      latestWsMessage.subject === 'hardwareStateChange' &&
      typeof latestWsMessage.location === 'string'
    ) {
      reloadSingleHardware({
        hardwareLocation: latestWsMessage.location,
      });
    }
  }, [latestWsMessage, reloadSingleHardware]);

  return {
    loadHardware,
    isLoading,
    hardwares,
    setHardwares,
    isLoadingSearches,
    loadHardwareSearches,
    hardwareSearches,
  };
}
