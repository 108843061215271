import React from 'react';

import { Accordion, Box, HStack, IconButton, VStack } from '@chakra-ui/react';
import { HardwareQueue } from '../../apiClient/types';
import { BackButton } from '../../layout/BackButton';
import { PageHeading } from '../../layout/PageHeading';
import { RepeatIcon } from '@chakra-ui/icons';
import { QueueDetailItem } from './QueueDetailItem';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  queues: HardwareQueue[];
  refresh: () => Promise<void>;
  hardwareLocation: string;
  isLoading: boolean;
};

export const QueueDetailContent = ({ queues, refresh, hardwareLocation, isLoading }: Props) => {
  const intl = useIntl();

  const getHardwareNameFromQueue = () => {
    for (const queue of queues) {
      const matchingHardware = queue.hardware?.find(
        (hardware) => hardware.location === hardwareLocation
      );
      if (matchingHardware) {
        return `${matchingHardware.product.name} - ${matchingHardware.name} (${matchingHardware.serialNumber} - ${matchingHardware.server.host})`;
      }
    }
    return <FormattedMessage id="queue_details_hardware" />;
  };

  const hardwareDescription = getHardwareNameFromQueue();

  return (
    <HStack justify="space-between">
      <VStack align="top" w="full">
        <HStack>
          <PageHeading
            heading={<FormattedMessage id="queue_details_heading" />}
            description={hardwareDescription}
          />
          <IconButton
            isRound
            isLoading={isLoading}
            variant="ghost"
            onClick={refresh}
            icon={<RepeatIcon />}
            aria-label={intl.formatMessage({ id: 'commons_refresh' })}
          />
        </HStack>
        <HStack pt={2} align="start">
          <BackButton />
        </HStack>
        <Box background={'white'}>
          <Accordion allowToggle w="full" allowMultiple>
            {queues.map((queue) => (
              <QueueDetailItem
                key={queue.id}
                queue={queue}
                hardware={queue.hardware}
                hardwareLocation={hardwareLocation}
                refresh={refresh}
              />
            ))}
          </Accordion>
        </Box>
      </VStack>
    </HStack>
  );
};
