import { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Hardware } from '../../apiClient/types';

export function useFilterHardware() {
  const intl = useIntl();
  const searchString = '';
  const defaultFilter = intl.formatMessage({ id: 'hardwarepage_all_hardware' });

  const [includedKeywords, setIncludedKeywords] = useState<string[]>([]);
  const [excludedKeywords, setExcludedKeywords] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>(defaultFilter);

  const handleSearchChange = useCallback(
    (search: string, isExclude: boolean) => {
      if (search) {
        if (isExclude) {
          setExcludedKeywords([...excludedKeywords, search]);
        } else {
          setIncludedKeywords([...includedKeywords, search]);
        }
      }
    },
    [excludedKeywords, includedKeywords]
  );

  const removeKeywordFilter = useCallback(
    (keyword: string) => {
      if (includedKeywords.includes(keyword)) {
        setIncludedKeywords(includedKeywords.filter((k) => k !== keyword));
      } else if (excludedKeywords.includes(keyword)) {
        setExcludedKeywords(excludedKeywords.filter((k) => k !== keyword));
      }
    },
    [includedKeywords, excludedKeywords]
  );

  const displayQueueOptions = useCallback(
    (hardwares: Hardware[]) => {
      return (
        (selectedFilter !== intl.formatMessage({ id: 'hardwarepage_all_hardware' }) ||
          includedKeywords.length > 0 ||
          excludedKeywords.length > 0) &&
        hardwares.length > 0
      );
    },
    [selectedFilter, includedKeywords, excludedKeywords, intl]
  );

  const resetSelectedFilter = useCallback(() => {
    setSelectedFilter(defaultFilter);
  }, [defaultFilter]);

  return {
    searchString,
    includedKeywords,
    excludedKeywords,
    defaultFilter,
    selectedFilter,
    setSelectedFilter,
    handleSearchChange,
    removeKeywordFilter,
    displayQueueOptions,
    resetSelectedFilter,
  };
}
