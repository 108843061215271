import { HStack, SimpleGrid, VStack } from '@chakra-ui/layout';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { NavLink } from 'react-router-dom';
import { Hardware, HardwareQueue, ReservationStatusEnum } from '../../apiClient/types';
import { useQueueHardware } from '../hardwareQueue/useQueueHardware';
import { QueueConfirmationModal } from '../hardwareQueue/QueueConfirmationModal';
import { HardwareDetailInformationBadge } from './HardwareDetailInformationBadge';
import { useState } from 'react';
import { isRequiredAccessLevelForAction } from '../../hardware/isRequiredAccessLevelForAction';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReserveHardware } from '../../hardware/useReserveHardware';
import { useFreeHardware } from '../../hardware/useFreeHardware';
import { getTableAttributes } from '../../hardware/getTableAttributes';
import { ConnectModal } from '../../hardware/ConnectModal';
import { AttributesTable } from '../../hardware/AttributesTable';
import { getQueueIdForPendingForMe, isPendingForMe } from '../../hardware/getHardwareUtils';
import { useIsMobile } from '../../useIsMobile';

type Props = {
  hardware: Hardware;
  refresh: () => unknown;
  refreshQueues: () => unknown;
  hasQueues: boolean;
  hardwareQueues: HardwareQueue[] | undefined;
  hardwareQueueStatusMap: Map<any, any>;
};

export function HardwareDetailGrid({
  hardware,
  refresh,
  refreshQueues,
  hasQueues,
  hardwareQueues,
  hardwareQueueStatusMap,
}: Props) {
  const intl = useIntl();
  const [message, setMessage] = useState<string | undefined>(undefined);

  const isMobile = useIsMobile();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isQueueOpen, onOpen: onQueueOpen, onClose: onQueueClose } = useDisclosure();
  const { handleReserve, isLoading: isLoadingReserve } = useReserveHardware(hardware, refresh);
  const { handleFree, isLoading: isLoadingFree } = useFreeHardware(hardware, refresh);

  const {
    queueFromSelection,
    reserveQueue,
    deleteHardwareQueue,
    isLoading: isLoadingQueue,
  } = useQueueHardware();

  const handleQueue = async () => {
    await queueFromSelection([hardware.location], message);
    onQueueClose();
    refreshQueues();
  };

  const handleReserveQueue = async (queueId: string) => {
    await reserveQueue(queueId);
    refresh();
    refreshQueues();
  };

  const handleRemoveQueue = async (queueId: string) => {
    await deleteHardwareQueue(queueId);
    refresh();
    refreshQueues();
  };

  const pendingForMe = isPendingForMe(hardware, hardwareQueueStatusMap);
  const pendingQueueId = getQueueIdForPendingForMe(hardware, hardwareQueues);
  const isReserved = hardware.status.reservation.status !== ReservationStatusEnum.FREE;
  const canPerformAction = isRequiredAccessLevelForAction(hardware.accessLevel);

  return (
    <>
      <VStack align="start" mb={2}>
        <HardwareDetailInformationBadge
          isReserved={isReserved}
          canPerformAction={canPerformAction}
        />
        <HStack w="full" overflowX={isMobile ? 'scroll' : 'initial'}>
          <ButtonGroup variant="outline" mb={2}>
            {!isReserved && canPerformAction && !pendingForMe && (
              <Button onClick={handleReserve} isLoading={isLoadingReserve}>
                <FormattedMessage id="hardwaredetailgrid_reserve" />
              </Button>
            )}
            {!isReserved && !canPerformAction && (
              <Button isDisabled onClick={handleReserve} isLoading={isLoadingReserve}>
                <FormattedMessage id="hardwaredetailgrid_reserve" />
              </Button>
            )}
            {isReserved && canPerformAction && (
              <Button onClick={onQueueOpen} isLoading={isLoadingQueue}>
                <FormattedMessage id="queue_queue" />
              </Button>
            )}
            {!isReserved && pendingForMe && (
              <>
                <Button
                  onClick={() => handleReserveQueue(pendingQueueId)}
                  isLoading={isLoadingQueue}
                >
                  <FormattedMessage id="queue_reserve_queue" />
                </Button>
                <Button
                  onClick={() => handleRemoveQueue(pendingQueueId)}
                  isLoading={isLoadingQueue}
                >
                  <FormattedMessage id="queue_remove_queue" />
                </Button>
              </>
            )}
            <Button onClick={onOpen} isDisabled={!hardware.guard.canConnect || !canPerformAction}>
              <FormattedMessage id="hardwaredetailgrid_connect" />
            </Button>
            <Button
              onClick={handleFree}
              isDisabled={!hardware.guard.canFree || !canPerformAction}
              isLoading={isLoadingFree}
            >
              <FormattedMessage id="hardwaredetailgrid_free" />
            </Button>
            {hasQueues ? (
              <NavLink to={`/queue/hardware/${hardware.location}`}>
                <Button variant="ghost">
                  <FormattedMessage id="queue_heading" />
                </Button>
              </NavLink>
            ) : (
              <Button variant="ghost" isDisabled>
                <FormattedMessage id="queue_heading" />
              </Button>
            )}
            <Button as={NavLink} to={`/hardware/${hardware.location}`} variant="ghost">
              <FormattedMessage id="hardwaredetailgrid_details" />
            </Button>
          </ButtonGroup>
        </HStack>
      </VStack>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        <VStack align="stretch" spacing={4}>
          <AttributesTable
            attributes={getTableAttributes('product', hardware, intl)}
            caption={<FormattedMessage id="hardwaredetailgrid_product_attributes_caption" />}
          />
          {hardware.status.reservation.status !== ReservationStatusEnum.FREE && (
            <AttributesTable
              attributes={getTableAttributes('reservation', hardware, intl)}
              caption={<FormattedMessage id="hardwaredetailgrid_reservation_attributes_caption" />}
            />
          )}
        </VStack>
        <VStack align="stretch" spacing={4}>
          <AttributesTable
            attributes={getTableAttributes('hardware', hardware, intl)}
            caption={<FormattedMessage id="hardwaredetailgrid_hardware_attributes_caption" />}
          />
          {hardware.status.proxy.active && (
            <AttributesTable
              attributes={getTableAttributes('proxy', hardware, intl)}
              caption={<FormattedMessage id="hardwaredetailgrid_proxy_attributes_caption" />}
            />
          )}
        </VStack>
      </SimpleGrid>
      {isOpen && (
        <ConnectModal
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          hardware={hardware}
          refresh={refresh}
        />
      )}
      {isQueueOpen && (
        <QueueConfirmationModal
          isOpen={isQueueOpen}
          onOpen={onQueueOpen}
          onClose={onQueueClose}
          setMessage={setMessage}
          handleConfirmClick={handleQueue}
        />
      )}
    </>
  );
}
