import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  userIds: string[];
  onChangeUserId: (userIds: string[]) => void;
  error: boolean;
};

export const UserIdFormControl = ({ userIds, onChangeUserId, error }: Props) => {
  const intl = useIntl();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = event.target.value;
    onChangeUserId(input ? input.split('\n') : []);
  };

  const multilinePlaceholder = `${intl.formatMessage({
    id: 'realm_add_user_placeholder_id',
  })}\n${intl.formatMessage({ id: 'realm_add_user_placeholder_description' })}`;

  return (
    <FormControl isInvalid={error}>
      <FormLabel fontWeight="bold" textTransform="uppercase" fontSize="14px" htmlFor="userIds">
        <FormattedMessage id="realm_add_user_header_id" />
      </FormLabel>
      <Textarea
        id="userIds"
        maxH="200px"
        resize="vertical"
        onChange={handleChange}
        value={userIds.join('\n')}
        placeholder={multilinePlaceholder}
      />
    </FormControl>
  );
};
