import { CheckIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { Button, HStack, IconButton, Td, Text, Tr } from '@chakra-ui/react';
import { useRealms } from '../realms/useRealms';
import { useShowError } from '../../useShowError';
import { GroupUserAdmin } from '../../apiClient/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRealmInformation } from '../realms/useRealmInformation';

type Props = {
  user: GroupUserAdmin;
  onRemoveUser: () => void;
  isSelected: boolean;
  onChangeSelection: (isSelected: boolean) => void;
  realmGroupId: string;
  realmId: string;
  onUserListUpdate: (update: (prevUsers: GroupUserAdmin[]) => GroupUserAdmin[]) => void;
};

export const ManageGroupUserItem = ({
  user,
  onRemoveUser,
  isSelected,
  onChangeSelection,
  realmGroupId,
  realmId,
  onUserListUpdate,
}: Props) => {
  const { removeUser } = useRealms();
  const { refetch } = useRealmInformation(realmId);

  const showError = useShowError();
  const intl = useIntl();

  const handleConfirmClick = () => {
    removeUser.mutate(
      { realmGroupId, userId: user.id },
      {
        onSuccess: () => {
          onUserListUpdate((prevUsers: GroupUserAdmin[]) =>
            prevUsers.filter((u) => u.id !== user.id)
          );
          refetch();
          onChangeSelection(false);
        },
        onError: (error) => {
          showError(error, { description: intl.formatMessage({ id: 'realm_remove_user_error' }) });
        },
      }
    );
  };

  const handleCancelClick = () => {
    onChangeSelection(false);
  };

  return (
    <Tr key={user.id}>
      <Td w="full">
        {isSelected ? (
          <HStack w="full" justify="space-between">
            <Text>
              <FormattedMessage
                id="realm_group_remove_user_confirmation"
                values={{ userId: user.id }}
              />
            </Text>
            <HStack>
              <IconButton
                size="sm"
                variant="ghost"
                icon={<CheckIcon />}
                onClick={handleConfirmClick}
                aria-label={intl.formatMessage({ id: 'commons_confirm' })}
              />
              <IconButton
                size="sm"
                variant="ghost"
                icon={<CloseIcon />}
                onClick={handleCancelClick}
                aria-label={intl.formatMessage({ id: 'commons_cancel' })}
              />
            </HStack>
          </HStack>
        ) : (
          <HStack w="full" justify="space-between">
            <Text>{user.id}</Text>
            <Button size="sm" onClick={onRemoveUser} variant="ghost" leftIcon={<DeleteIcon />}>
              <FormattedMessage id="realm_group_remove_user" />
            </Button>
          </HStack>
        )}
      </Td>
    </Tr>
  );
};
