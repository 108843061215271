import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { useRealms } from '../realms/useRealms';
import { useShowError } from '../../useShowError';
import { GroupAdmin } from '../../apiClient/types';
import 'react-datepicker/dist/react-datepicker.css';
import './customdatepicker.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { renderAddUserValidationError } from '../realms/RealmHelper';
import { AddUserFormControl } from './AddUserFormControl';
import { useRealmInformation } from '../realms/useRealmInformation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  realmId: string;
  groups: GroupAdmin[];
};

export interface FormValues {
  userIds: string[];
  groupIds: string[];
  retirement?: {
    retired: boolean;
    validFrom?: string;
    validUntil?: string;
  };
}

export const AddUserModal = ({ isOpen, onClose, realmId, groups }: Props) => {
  const { addUser } = useRealms();
  const { refetch } = useRealmInformation(realmId);
  const showError = useShowError();
  const intl = useIntl();

  const UserFormSchema = Yup.object().shape({
    userIds: Yup.array().of(Yup.string().email('Invalid email address')).min(1),
    groupIds: Yup.array().of(Yup.string()).min(1),
    retirement: Yup.object({
      retired: Yup.boolean(),
      validFrom: Yup.date().nullable().optional(),
      validUntil: Yup.date().nullable().optional(),
    }),
  });

  const initialValues: FormValues = {
    userIds: [],
    groupIds: [],
    retirement: {
      retired: false,
      validFrom: undefined,
      validUntil: undefined,
    },
  };

  const handleGroupCheckboxChange = (
    groupId: string,
    isChecked: boolean,
    setFieldValue: FormikHelpers<FormValues>['setFieldValue'],
    values: FormValues
  ) => {
    setFieldValue(
      'groupIds',
      isChecked ? [...values.groupIds, groupId] : values.groupIds.filter((id) => id !== groupId)
    );
  };

  const handleFormSubmit = (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    const formattedGroupIds = values.groupIds.map((groupId) => groupId.split(':')[1]);

    const toLocalISOString = (date: Date) => {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    };

    const payload = {
      realmId,
      userIds: values.userIds,
      groupIds: formattedGroupIds,
      retirement: {
        retired: values.retirement?.retired ?? false,
        validFrom: values.retirement?.validFrom
          ? toLocalISOString(new Date(values.retirement.validFrom))
          : undefined,
        validUntil: values.retirement?.validUntil
          ? toLocalISOString(new Date(values.retirement.validUntil))
          : undefined,
      },
    };

    addUser.mutate(payload, {
      onSuccess: () => {
        refetch();
        resetForm();
        onClose();
      },
      onError: (error) => {
        showError(error, { description: intl.formatMessage({ id: 'realm_add_user_error' }) });
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserFormSchema}
      onSubmit={handleFormSubmit}
    >
      {({ values, setFieldValue, errors, touched, handleSubmit }) => (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
          <Form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <FormattedMessage id="realm_add_user_title" values={{ realmId }} />
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <AddUserFormControl
                  values={values}
                  touched={touched}
                  errors={errors}
                  groups={groups}
                  handleGroupChange={handleGroupCheckboxChange}
                  setFieldValue={setFieldValue}
                />
              </ModalBody>
              <ModalFooter>
                {renderAddUserValidationError('userIds', touched, errors)}
                {renderAddUserValidationError('groupIds', touched, errors)}
                <Button colorScheme="gray" mr={3} onClick={onClose}>
                  <FormattedMessage id="commons_cancel" />
                </Button>
                <Button type="submit">
                  <FormattedMessage id="commons_submit" />
                </Button>
              </ModalFooter>
            </ModalContent>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
