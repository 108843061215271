import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { SingleQueueLayout } from './SingleQueueLayout';
import { HardwareQueue } from '../../apiClient/types';
import { QueueStatusBadge } from '../hardware/QueueStatusBadge';
import { FormattedMessage } from 'react-intl';

export const SingleQueueInformationSection = ({
  queue,
  onOpen,
}: {
  queue: HardwareQueue;
  onOpen: () => void;
}) => {
  return (
    <SingleQueueLayout title={<FormattedMessage id="queue_single_table_info_heading" />}>
      <Table colorScheme="gray" bg="white">
        <Thead>
          <Tr>
            <Th>
              <FormattedMessage id="queue_single_table_info_status" />
            </Th>
            <Th>
              <FormattedMessage id="queue_single_table_info_queued_by" />
            </Th>
            <Th>
              <FormattedMessage id="queue_single_table_info_message" />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <QueueStatusBadge queueStatus={queue.status} clickable onClick={onOpen} />
            </Td>
            <Td>{queue.name}</Td>
            <Td>
              {queue.message ? (
                queue.message
              ) : (
                <FormattedMessage id="queue_single_table_info_no_message" />
              )}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </SingleQueueLayout>
  );
};
