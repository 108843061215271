import { Badge, type BadgeProps } from '@chakra-ui/layout';
import { ConnectionStatusEnum, Hardware } from '../apiClient/types';
import { FormattedMessage } from 'react-intl';

type Props = { hardware: Hardware } & BadgeProps;

export function ConnectionStatusBadge({ hardware, ...badgeProps }: Props) {
  switch (hardware.status.connection) {
    case ConnectionStatusEnum.ONLINE:
      return (
        <Badge variant="outline" colorScheme="green" {...badgeProps}>
          <FormattedMessage id="connectionstatusbadge_online" />
        </Badge>
      );
    case ConnectionStatusEnum.PARTIALLY_ONLINE:
      return (
        <Badge variant="outline" colorScheme="yellow">
          <FormattedMessage id="connectionstatusbadge_partially_online" />
        </Badge>
      );
    case ConnectionStatusEnum.OFFLINE:
      return (
        <Badge variant="outline" colorScheme="red" {...badgeProps}>
          <FormattedMessage id="connectionstatusbadge_offline" />
        </Badge>
      );
    case ConnectionStatusEnum.NO_CONNECTION:
      return (
        <Badge variant="outline" colorScheme="blackAlpha">
          <FormattedMessage id="connectionstatusbadge_no_connection" />
        </Badge>
      );
    default:
      return (
        <Badge variant="outline" colorScheme="gray" {...badgeProps}>
          <FormattedMessage id="connectionstatusbadge_unknown" />
        </Badge>
      );
  }
}
