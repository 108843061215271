import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export function InvalidSessionModal(props: { login: () => unknown; logout: () => unknown }) {
  return (
    <Modal
      isOpen
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => {}}
      size="sm"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="invalidsessionmodal_session_expired" />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage id="invalidsessionmodal_body" />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={3}>
            <Button variant="link" onClick={props.logout}>
              <FormattedMessage id="invalidsessionmodal_logout" />
            </Button>
            <Button onClick={props.login}>
              <FormattedMessage id="invalidsessionmodal_continue" />
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
