import { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';
import type { Action, ActionResult, Hardware } from '../../apiClient/types';
import { getHardwareActionMessages } from '../../hardware/getHardwareActionMessages';

export function useHardwareAction(hardware: Hardware) {
  const { executeHardwareAction } = useApiClient();
  const [isLoading, setLoading] = useState(false);
  const [actionResult, setActionResult] = useState<ActionResult>();
  const showError = useShowError();
  const intl = useIntl();

  const handleStartAction = useCallback(
    async function (action: Action, optionId: string | undefined) {
      setLoading(true);

      try {
        setActionResult(
          await executeHardwareAction({
            hardwareLocation: hardware.location,
            actionId: action.id,
            optionId,
          })
        );
        setLoading(false);
      } catch (error) {
        const hardwareActionMessages = getHardwareActionMessages(action.id, intl);
        showError(error, {
          description: intl.formatMessage({ id: hardwareActionMessages.error }),
        });
        setLoading(false);
      }
    },
    [executeHardwareAction, hardware, intl, showError]
  );

  function handleClearActionResult() {
    setActionResult(undefined);
  }

  return { isLoading, handleStartAction, actionResult, handleClearActionResult };
}
