import { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { produce } from 'immer';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SlideFade,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { ModalLayout } from './UserGroupModalLayout';
import { UserGroupModalProps } from './types';
import { useLoadGroups } from './useLoadGroups';
import { RealmSession, GroupSession } from '../../apiClient/types';
import { useShowError } from '../../useShowError';
import { FormattedMessage, useIntl } from 'react-intl';
import { PermissionsContext } from '../realms/PermissionsProvider';
import { useQueryClient } from '@tanstack/react-query';

export const UserGroupModal = ({ isOpen, onClose }: UserGroupModalProps) => {
  const { isLoading, groups, realms, setSessionGroups } = useLoadGroups();
  const [initialUserGroups, setInitialUserGroups] = useState<GroupSession[] | null>(null);
  const [formGroups, setFormGroups] = useState<GroupSession[] | null>(null);
  const [formRealms, setFormRealms] = useState<RealmSession[] | null>(null);
  const showError = useShowError();
  const intl = useIntl();

  const queryClient = useQueryClient();

  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error('Context is undefined');
  }

  const changesMade = useMemo(() => {
    if (formGroups && initialUserGroups) {
      return !isEqual(formGroups, initialUserGroups);
    }
    return false;
  }, [formGroups, initialUserGroups]);

  useEffect(() => {
    if (isOpen && realms) {
      setFormRealms(cloneDeep(realms));
      setInitialUserGroups(cloneDeep(groups));
      setFormGroups(cloneDeep(groups));
    }
  }, [isOpen, realms, groups]);

  // Handle 'Apply Selection' button click to send update request, load new groups and handle modal close
  const handleApplySelection = () => {
    const activeGroupIds =
      formGroups?.filter((group) => group.active).map((group) => group.id) || [];
    setSessionGroups(activeGroupIds)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['sessionInformation'] });
        queryClient.invalidateQueries({ queryKey: ['refreshHardware'] });
        handleCloseModal();
      })
      .catch((error: Error) => {
        showError(error, {
          description: intl.formatMessage({ id: 'session_groups_update_error' }),
        });
      });
  };

  // Compare initial and new values
  const handleChange = useCallback(
    (value: string, realmIndex: number, groupIndex: number) => {
      setFormRealms((prevRealms) => {
        const nextRealms = produce(prevRealms, (draft) => {
          const targetGroup = draft?.[realmIndex]?.groups[groupIndex];
          if (targetGroup) {
            targetGroup.active = value === 'Active';
          }
        });

        const targetGroupId = nextRealms?.[realmIndex]?.groups[groupIndex]?.id;

        setFormGroups((prevGroups) => {
          if (!prevGroups) return null;

          return produce(prevGroups, (draft) => {
            const flatGroupIndex = draft.findIndex((group) => group.id === targetGroupId);
            if (flatGroupIndex !== -1) {
              draft[flatGroupIndex].active = value === 'Active';
            }
          });
        });

        return nextRealms;
      });
    },
    [setFormRealms, setFormGroups]
  );

  // Dismiss changes if modal is closed
  const handleCloseModal = () => {
    onClose();
  };

  if (isOpen && groups) {
    return (
      <Modal size="3xl" isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage id="session_groups_modal_heading" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <Spinner />
            ) : (
              <ModalLayout
                realms={formRealms || realms}
                userGroups={formGroups || groups}
                initialUserGroups={initialUserGroups}
                handleChange={handleChange}
              />
            )}
          </ModalBody>
          <ModalFooter>
            {changesMade && (
              <SlideFade in={changesMade} offsetY="20px">
                <Text color="gray.500" mr="3">
                  <FormattedMessage id="session_groups_modal_unsaved" />
                </Text>
              </SlideFade>
            )}
            <Button colorScheme="blackAlpha" mr={3} onClick={onClose}>
              <FormattedMessage id="session_groups_modal_close" />
            </Button>
            <Button mr={3} onClick={handleApplySelection} isDisabled={!changesMade}>
              <FormattedMessage id="session_groups_modal_apply" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  } else {
    return null;
  }
};
