import { Alert, AlertIcon, Grid, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Hardware, HardwareInterface } from '../../apiClient/types';
import { ConnectionStatusBadge } from '../../hardware/ConnectionStatusBadge';
import { useIsMobile } from '../../useIsMobile';
import { AccordionLayout } from './AccordionLayout';

type Props = {
  hardware: Hardware;
};

type LayoutProps = {
  children: React.ReactNode;
};

const ConnectionInformationLayout = ({ children }: LayoutProps) => {
  const isMobile = useIsMobile();
  let content;

  isMobile
    ? (content = <VStack align="start">{children}</VStack>)
    : (content = (
        <Grid templateColumns="repeat(2, 1fr)" gap={1} w="75%" pl={2}>
          {children}
        </Grid>
      ));

  return content;
};

const ConnectionInformation = ({ hardware }: Props) => {
  return (
    <ConnectionInformationLayout>
      {hardware.interfaces.map((status: HardwareInterface) => (
        <React.Fragment key={status.id}>
          <Text fontWeight="bold">{status.name}</Text>
          <Text>{status.type.toString()}</Text>
        </React.Fragment>
      ))}
    </ConnectionInformationLayout>
  );
};

export const ConnectionSection = ({ hardware }: Props) => {
  return (
    <AccordionLayout
      buttonContent={
        <HStack justify="space-between" px={0} w="full" mr={2}>
          <Text fontWeight="bold">
            <FormattedMessage id="hardwaredetailpage_title_connection" />
          </Text>
          <ConnectionStatusBadge hardware={hardware} />
        </HStack>
      }
      panelContent={
        <VStack w="full">
          <HStack minH="40px" w="full" justify="space-between">
            {hardware.interfaces.length > 0 ? (
              <ConnectionInformation hardware={hardware} />
            ) : (
              <Alert status="info" bg="none" pl={0} py={0}>
                <AlertIcon />
                <FormattedMessage id="hardwaredetailpage_no_interfaces" />
              </Alert>
            )}
          </HStack>
        </VStack>
      }
      buttonStyles={{ mt: '-1px', borderBottom: 'none' }}
      panelStyles={{ px: '6', py: '4' }}
    />
  );
};
