import { Box, Flex, Text } from '@chakra-ui/react';

type Props = {
  title: string | React.ReactNode;
  children: React.ReactNode;
  pt?: number | string;
  pb?: number | string;
  py?: number | string;
  controls?: React.ReactNode;
};

export const SingleQueueLayout = ({ title, children, pt, pb, py = 4, controls }: Props) => {
  const padding = {
    pt: pt ?? py,
    pb: pb ?? py,
  };

  return (
    <Box {...padding}>
      <Flex justifyContent="space-between" alignItems="start">
        <Text
          pl={4}
          pb={4}
          fontSize="14px"
          fontWeight="bold"
          textTransform="uppercase"
          color="gray.600"
          textAlign="start"
        >
          {title}
        </Text>
        {controls}
      </Flex>
      {children}
    </Box>
  );
};
