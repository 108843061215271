import React, { useState } from 'react';
import { VStack, HStack } from '@chakra-ui/layout';
import { Text, useDisclosure, Divider, Link, Tooltip } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { HardwareActionOptionsModal } from './HardwareActionOptionsModal';
import { NavLink } from 'react-router-dom';
import { ActionStatusBadge } from '../../hardware/ActionStatusBadge';
import { Action, ActionRun, Hardware } from '../../apiClient/types';
import { getHardwareActionMessages } from '../../hardware/getHardwareActionMessages';
import { isRequiredAccessLevelForAction } from '../../hardware/isRequiredAccessLevelForAction';

type Props = {
  hardwareAction: Action;
  actionRun?: ActionRun;
  isLoading: boolean;
  onStartAction: (action: Action, option?: string | undefined) => void;
  isReserved: boolean;
  hardware: Hardware;
};

export function HardwareActionContainer(props: Props) {
  const [selectedOption, setSelectedOption] = useState<string | undefined>();

  const intl = useIntl();
  const hardwareActionMessages = getHardwareActionMessages(props.hardwareAction.id, intl);
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleChangeOption(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === '') setSelectedOption(undefined);
    else setSelectedOption(event.target.value);
  }

  return (
    <>
      <VStack _hover={{ bg: 'gray.50' }} w="full" align="start" pl={3} py={4}>
        <HStack w="full" align="end">
          <VStack spacing={0} w="50%" maxW="50%" align="start" pl={2} mr={-3.5} pr={3.5}>
            <Link onClick={onOpen} fontWeight="bold" color="blue.500">
              {props.hardwareAction.name}
            </Link>
            <Text fontSize="sm">{hardwareActionMessages.description}</Text>
          </VStack>
          <VStack spacing={0} w="24%" maxW="24%" align="start">
            {props.actionRun ? (
              <Link
                as={NavLink}
                to={`/hardware/${props.hardware.serialNumber}@${props.hardware.server.identifier}/run/${props.actionRun.id}`}
                fontSize="sm"
              >
                {moment(props.actionRun.createdOn).format('DD/MM/YYYY HH:mm')}
              </Link>
            ) : (
              <Text fontSize="sm" fontStyle="italic">
                <FormattedMessage id="hardwaredetailpage_actions_no_previous" />
              </Text>
            )}
            {props.actionRun?.option ? (
              <Tooltip label="Previous action">
                <Text fontSize="sm" minH="18px">
                  {props.actionRun?.option?.name}
                </Text>
              </Tooltip>
            ) : props.actionRun ? (
              <Text fontSize="xs" fontWeight="bold" minH="18px">
                <FormattedMessage id="hardwaredetailpage_actions_no_option" />
              </Text>
            ) : (
              <Text fontSize="xs" fontWeight="bold" minH="18px" />
            )}
          </VStack>
          <VStack spacing={0} align="start" pb={1.5}>
            <Text fontSize="sm" minH="18px">
              {props.actionRun ? <FormattedMessage id="hardwaredetailpage_actions_result" /> : ''}
            </Text>
            <Text fontSize="xs" fontWeight="bold" minH="18px">
              {props.actionRun ? <ActionStatusBadge run={props.actionRun} /> : ''}
            </Text>
          </VStack>
        </HStack>
      </VStack>
      <Divider p={0} />
      <HardwareActionOptionsModal
        hardwareAction={props.hardwareAction}
        isLoading={props.isLoading}
        selectedOption={selectedOption}
        onChange={handleChangeOption}
        onStartAction={async () => {
          await props.onStartAction(props.hardwareAction, selectedOption);
          onClose();
        }}
        onClose={onClose}
        isOpen={isOpen}
        isRequiredAccessLevelForAction={isRequiredAccessLevelForAction(
          props.hardwareAction.accessLevel
        )}
        isReserved={props.isReserved}
      />
    </>
  );
}
