import { Input, Text, VStack } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { RealmModalLayout } from '../realmManagement/RealmModalLayout';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

/**
 * NOT IN USE: Modal for creating a realm.
 */

export const CreateRealmModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const intl = useIntl();

  return (
    <RealmModalLayout
      header={intl.formatMessage({ id: 'realms_list_create_realm' })}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <CustomInputGroup
        text={intl.formatMessage({ id: 'realms_create_value_id' })}
        label={intl.formatMessage({ id: 'realms_create_label_id' })}
      />
      <CustomInputGroup
        text={intl.formatMessage({ id: 'realms_create_value_name' })}
        label={intl.formatMessage({ id: 'realms_create_label_name' })}
      />
      <CustomInputGroup
        text={intl.formatMessage({ id: 'realms_create_value_description' })}
        label={intl.formatMessage({ id: 'realms_create_label_description' })}
      />
    </RealmModalLayout>
  );
};

/**
 * NOT IN USE: Custom input group for CreateRealmModal.
 * @param label - label of the input
 * @param text - placeholder text of the input
 */

export const CustomInputGroup = ({ label, text }: { label: string; text: string }) => {
  return (
    <VStack align="start" pb={4}>
      <Text>{label}</Text>
      <Input placeholder={text} />
    </VStack>
  );
};
