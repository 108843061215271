import { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ActionResult } from '../../apiClient/types';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';

export function useLoadHardwareActionLog(hardwareLocation: string, actionRunId: string) {
  const [actionResult, setActionResult] = useState<ActionResult>();
  const [isLoading, setLoading] = useState(true);
  const showError = useShowError();
  const { getHardwareActionResult } = useApiClient();
  const intl = useIntl();
  const loadLog = useCallback(
    async function () {
      setLoading(true);
      try {
        setActionResult(
          await getHardwareActionResult({
            hardwareLocation,
            actionRunId,
          })
        );
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'hardwareactionlogpage_load_log_error' }),
        });
      }
      setLoading(false);
    },
    [showError, getHardwareActionResult, intl, hardwareLocation, actionRunId]
  );
  return { loadLog, isLoading, actionResult };
}
