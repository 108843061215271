import { useIntl } from 'react-intl';
import { Artifact } from '../../apiClient/types';
import { HardwareFilterMenu } from './HardwareFilterMenu';

type Props = {
  selectedFilter: string;
  onSelect: (title: string | null) => void;
  onChangeSelectedFilter: (filter: string) => void;
};

export const HardwareFilterSelection = (props: Props) => {
  const intl = useIntl();
  const hardwareSearches = JSON.parse(localStorage.getItem('hardware-searches') || '[]');

  const handleSelect = (artifactName: string | null) => {
    const artifact = hardwareSearches.find((a: Artifact) => a.name === artifactName);
    props.onChangeSelectedFilter(
      artifactName || intl.formatMessage({ id: 'hardwarepage_all_hardware' })
    );
    props.onSelect(artifact ? artifact.location : null);
  };

  return (
    <HardwareFilterMenu
      onSelect={handleSelect}
      searchData={[
        { name: intl.formatMessage({ id: 'hardwarepage_all_hardware' }) },
        ...hardwareSearches,
      ]}
      selectedFilter={props.selectedFilter}
    />
  );
};
