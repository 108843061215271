import { InfoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  title: string;
  description: string;
  errorStack: string | undefined;
};

export const ErrorToastWithPopover = ({ title, description, errorStack }: Props) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [showAlert, setShowAlert] = useState(true);
  const intl = useIntl();

  const handleCloseAlert = () => setShowAlert(false);

  if (!showAlert) return null;

  return (
    <Alert
      status="error"
      borderRadius="md"
      boxShadow="lg"
      width="auto"
      pr="2"
      position="relative"
      overflow="visible"
    >
      <AlertIcon />
      <Box flex="1">
        <AlertTitle>
          <HStack w="full" justify="space-between">
            <Text>{title}</Text>
            <HStack spacing={0} gap={0}>
              <Popover isOpen={isOpen} onOpen={onToggle} onClose={onClose} placement="top">
                <PopoverTrigger>
                  <IconButton
                    icon={<InfoIcon />}
                    size="sm"
                    variant="ghost"
                    colorScheme="blackAlpha"
                    color="gray.600"
                    aria-label={intl.formatMessage({ id: 'useshowerror_show_details' })}
                  />
                </PopoverTrigger>
                <PopoverContent w="md" bg="gray.100" border="2px solid">
                  <PopoverArrow bg="gray.100" />
                  <PopoverCloseButton />
                  <PopoverHeader>
                    <FormattedMessage id="useshowerror_popover_header" />
                  </PopoverHeader>
                  <PopoverBody>
                    <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {errorStack}
                    </pre>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              <CloseButton onClick={handleCloseAlert} />
            </HStack>
          </HStack>
        </AlertTitle>
        <AlertDescription display="block" pr={4}>
          {description}
        </AlertDescription>
      </Box>
    </Alert>
  );
};
