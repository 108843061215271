import { FormattedMessage } from 'react-intl';
import { Hardware } from '../../apiClient/types';
import { EmptyContent } from '../../layout/common/EmptyContent';

type Props = {
  hardwares: Hardware[] | undefined;
  displayedHardwares: Hardware[] | undefined;
  includedKeywords: string[];
  excludedKeywords: string[];
};

export const HardwarePageEmptyContent = (props: Props) => {
  const noHardwareFound = !props.hardwares || props.hardwares.length === 0;
  const noResultsFromSearch =
    props.displayedHardwares?.length === 0 &&
    (props.includedKeywords.length > 0 || props.excludedKeywords.length > 0);

  if (noHardwareFound) {
    return (
      <EmptyContent
        emptyContentLabel={<FormattedMessage id="hardwarepage_no_hardware" />}
        hasNavigation={false}
        includesHeading={false}
      />
    );
  } else if (noResultsFromSearch) {
    return (
      <EmptyContent
        emptyContentLabel={<FormattedMessage id="hardwarepage_no_hardware_with_criteria" />}
        hasNavigation={false}
        includesHeading={false}
      />
    );
  }

  return null;
};
