import { EditIcon } from '@chakra-ui/icons';
import { Td, Tr, Button, Text, Badge, VStack, Tooltip, IconButton } from '@chakra-ui/react';
import { GroupUserAdmin } from '../../apiClient/types';
import { FormattedMessage } from 'react-intl';

type Props = {
  user: GroupUserAdmin;
  groupCount: number;
  groupNames: string[];
  onOpenUser: (user: GroupUserAdmin) => void;
  disabled: boolean;
  isMobile: boolean | undefined;
};

type GroupsColumnProps = {
  isMobile: boolean | undefined;
  groupCount: number;
  groupNames: string[];
};

export const UserItem = ({
  user,
  groupCount,
  groupNames,
  onOpenUser,
  disabled,
  isMobile,
}: Props) => {
  return (
    <Tr
      bgColor={user.retirement?.retired ? 'blackAlpha.50' : 'white'}
      onClick={() => onOpenUser(user)}
      _hover={{
        bgColor: user.retirement?.retired ? 'blackAlpha.100' : 'gray.50',
        cursor: 'pointer',
      }}
    >
      {isMobile ? (
        <Td>
          <UserIdentifierColumn user={user} isMobile={isMobile} />
          <UserGroupsColumn isMobile={isMobile} groupCount={groupCount} groupNames={groupNames} />
        </Td>
      ) : (
        <>
          <Td>
            <UserIdentifierColumn user={user} isMobile={isMobile} />
          </Td>
          <Td>
            <UserGroupsColumn isMobile={isMobile} groupCount={groupCount} groupNames={groupNames} />
          </Td>
        </>
      )}
      <Td textAlign="end">
        {isMobile ? (
          <IconButton
            disabled={disabled}
            onClick={() => onOpenUser(user)}
            variant="ghost"
            mr="-4"
            icon={<EditIcon />}
            aria-label="Edit user"
          />
        ) : (
          <Button
            disabled={disabled}
            onClick={() => onOpenUser(user)}
            variant="ghost"
            leftIcon={<EditIcon />}
            marginY="-2.5"
          >
            <FormattedMessage id="realm_table_manage_user" />
          </Button>
        )}
      </Td>
    </Tr>
  );
};

/**
 * Column for displaying the user ID and retirement status.
 */

const UserIdentifierColumn = ({
  user,
  isMobile,
}: {
  user: GroupUserAdmin;
  isMobile: boolean | undefined;
}) => {
  return (
    <>
      {user.retirement?.retired ? (
        <VStack align="start" spacing={0}>
          <Text>{user.id}</Text>
          <Text fontSize="14px" fontWeight="bold" color="gray.600">
            <FormattedMessage id="realm_table_retired_user" />
          </Text>
        </VStack>
      ) : (
        <Text maxW={isMobile ? '50vw' : 'full'} noOfLines={1}>
          {user.id}
        </Text>
      )}
    </>
  );
};

/**
 * Column for displaying the user groups as badges. Falls back to a text & tooltip if the screen is too small.
 */

const UserGroupsColumn = ({ isMobile, groupCount, groupNames }: GroupsColumnProps) => {
  return (
    <>
      {isMobile ? (
        <Tooltip hasArrow label={groupNames.join(', ')} placement="top">
          <Text textAlign="start">
            {groupCount === 0 || groupCount > 1 ? (
              <FormattedMessage id="realm_table_groups" values={{ count: groupCount }} />
            ) : (
              <FormattedMessage id="realm_table_single_group" />
            )}
          </Text>
        </Tooltip>
      ) : (
        <>
          {groupNames.map((groupName, index) => (
            <Badge mr={2} key={index} colorScheme="blue" borderRadius="md">
              {groupName}
            </Badge>
          ))}
        </>
      )}
    </>
  );
};
