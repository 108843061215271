import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';
import { ValueRetirement } from '../../apiClient/types';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';

/**
 * Layer between the apiClient and the Realms pages. Uses react-query.
 *
 * Invokes ApiClient methods. Updates the queries and mutations in the react-query cache.
 */

export const useRealms = () => {
  const queryClient = useQueryClient();
  const { editUserGroups, removeFromGroup, listRealms, addUsersToRealm, setRealmUserGroups } =
    useApiClient();

  const showError = useShowError();
  const intl = useIntl();

  const {
    data: realmsList,
    isLoading: isRealmsListLoading,
    isError: isRealmsListError,
    error: realmsListError,
    refetch: refetchRealms,
  } = useQuery({
    queryKey: ['realms'],
    queryFn: listRealms,
  });

  useEffect(() => {
    if (isRealmsListError) {
      showError(realmsListError.message, {
        description: intl.formatMessage({ id: 'realms_list_realms_error' }),
      });
    }
  }, [isRealmsListError, realmsListError, showError, intl]);

  const editGroups = useMutation({
    mutationFn: ({
      realmGroupId,
      userId,
      retirement,
    }: {
      realmGroupId: string;
      userId: string;
      retirement?: boolean;
    }) => editUserGroups(realmGroupId, userId, retirement),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['realms'] });
    },
    onError: (error) =>
      showError(error, { description: intl.formatMessage({ id: 'realms_edit_groups_error' }) }),
  });

  const removeUser = useMutation({
    mutationFn: ({ realmGroupId, userId }: { realmGroupId: string; userId: string }) =>
      removeFromGroup(realmGroupId, userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['realms'] });
    },
    onError: (error) =>
      showError(error, { description: intl.formatMessage({ id: 'realms_remove_user_error' }) }),
  });

  const addUser = useMutation({
    mutationFn: ({
      realmId,
      userIds,
      groupIds,
      retirement,
    }: {
      realmId: string;
      userIds: string[];
      groupIds: string[];
      retirement?: ValueRetirement;
    }) => addUsersToRealm(realmId, userIds, groupIds, retirement),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['realms'] });
    },
    onError: (error) =>
      showError(error, { description: intl.formatMessage({ id: 'realms_add_user_error' }) }),
  });

  const setUserGroups = useMutation({
    mutationFn: ({
      realmId,
      userId,
      groupIds,
      retirement,
    }: {
      realmId: string;
      userId: string;
      groupIds?: string[];
      retirement?: ValueRetirement;
    }) => setRealmUserGroups(realmId, userId, groupIds, retirement),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['realms'] });
    },
    onError: (error) =>
      showError(error, { description: intl.formatMessage({ id: 'realms_set_user_groups_error' }) }),
  });

  return {
    realmsList,
    editGroups,
    removeUser,
    addUser,
    setUserGroups,
    isRealmsListLoading,
    isRealmsListError,
    refetchRealms,
  };
};
