import * as React from 'react';
import { getInitialLocale } from './getInitialLocale';

type LocaleContextType = {
  locale: string;
  onChangeLocale: (locale: string) => void;
  formatDate: (input: string) => string;
};

export const LocaleContext = React.createContext<LocaleContextType>({
  locale: getInitialLocale(),
  onChangeLocale: () => {},
  formatDate: () => '',
});
