import { CopyIcon } from '@chakra-ui/icons';
import {
  Button,
  IconButton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { RealmAdmin } from '../../apiClient/types';
import { useIsMobile } from '../../useIsMobile';

type Props = {
  realms: RealmAdmin[];
  handleRowClick: (realmId: string) => void;
};

type RowButtonProps = {
  children: React.ReactNode;
  handleClick: (realmId: string) => void;
  id: string;
};

// Copies the invitation link to the clipboard and displays a toast.
const handleCopyLink = (
  event: React.MouseEvent<HTMLButtonElement>,
  realmId: string,
  toast: any
) => {
  event.stopPropagation();

  navigator.clipboard.writeText(realmId);

  toast({
    title: <FormattedMessage id="realms_copy_link_success" values={{ realmId: realmId }} />,
    description: <FormattedMessage id="realms_copy_link_success_description" />,
    status: 'info',
    duration: 5000,
    isClosable: true,
  });
};

/**
 * Table for displaying the realms, their description and the amount of groups and users.
 */

export const RealmsTable = ({ realms, handleRowClick }: Props) => {
  const toast = useToast();
  const isMobile = useIsMobile();

  return (
    <Table colorScheme="gray" bg="white">
      <Thead>
        <Tr>
          <Th>
            <FormattedMessage id="realms_realm" />
          </Th>
          <Th>
            <FormattedMessage id="realms_details" />
          </Th>
          {isMobile ? (
            <Th />
          ) : (
            <Th textAlign="end" pr="4em">
              <FormattedMessage id="realms_invite_users" />
            </Th>
          )}
        </Tr>
      </Thead>
      <Tbody>
        {realms.map((realm) => (
          <RowButton key={realm.id} id={realm.id} handleClick={() => handleRowClick(realm.id)}>
            <Td fontWeight="bold">{realm.name}</Td>
            <Td>
              <Stack>
                <Text fontStyle="italic">
                  {realm.description ? (
                    realm.description
                  ) : (
                    <FormattedMessage id="realms_no_description" />
                  )}
                </Text>
                <Text>
                  {realm.groups.length === 1 ? (
                    <FormattedMessage id="realm_table_single_group" />
                  ) : (
                    <FormattedMessage
                      id="realm_table_groups"
                      values={{ count: realm.groups.length }}
                    />
                  )}
                </Text>
                <Text>
                  {realm.groups.flatMap((group) => group.users).length === 1 ? (
                    <FormattedMessage id="realm_table_single_user" />
                  ) : (
                    <FormattedMessage
                      id="realm_table_users"
                      values={{ count: realm.groups.flatMap((group) => group.users).length }}
                    />
                  )}
                </Text>
              </Stack>
            </Td>
            <Td textAlign="end">
              {isMobile ? (
                <IconButton
                  disabled
                  onClick={(event) => handleCopyLink(event, realm.id, toast)}
                  variant="ghost"
                  mr="-4"
                  icon={<CopyIcon />}
                  aria-label="Copy link"
                />
              ) : (
                <Button
                  disabled
                  onClick={(event) => handleCopyLink(event, realm.id, toast)}
                  variant="ghost"
                  leftIcon={<CopyIcon />}
                  marginY="-1.5"
                >
                  <FormattedMessage id="realms_copy_link" />
                </Button>
              )}
            </Td>
          </RowButton>
        ))}
      </Tbody>
    </Table>
  );
};

const RowButton = ({ children, handleClick, id }: RowButtonProps) => {
  return (
    <Tr onClick={() => handleClick(id)} _hover={{ bgColor: 'gray.50', cursor: 'pointer' }}>
      {children}
    </Tr>
  );
};
