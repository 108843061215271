import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalProps,
  ModalContentProps,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

type Props = Pick<ModalProps, 'isOpen' | 'onClose' | 'size'> &
  Pick<ModalContentProps, 'minH' | 'h'> & {
    onOpen: () => void;
    title: string;
    children: React.ReactNode;
  };

export const HardwareDetailModal = ({
  isOpen,
  onClose,
  title,
  children,
  size = 'md',
  minH,
  h,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent minH={minH} h={h}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            <FormattedMessage id="commons_close" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
