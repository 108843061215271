import { Box, Container, Link, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export function Footer() {
  return (
    <Box
      textAlign="center"
      width="100%"
      position="absolute"
      bottom={0}
      paddingX={{ base: 0, md: 8 }}
      paddingY={{ base: 2, md: 2 }}
    >
      <Container maxW="container.lg">
        <Text>
          <Link href="https://devicevault.io" isExternal>
            <FormattedMessage id="footer_devicevault_by" />
          </Link>{' '}
          <Link href="https://www.devatus.fi" isExternal>
            Devatus
          </Link>
        </Text>
        <Text>
          <Link href="mailto:devicevault@devatus.fi">devicevault@devatus.fi</Link>
        </Text>
      </Container>
    </Box>
  );
}
