import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/accordion';
import { Flex, Icon, Box, Heading, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { FiClipboard } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import { ActionResult } from '../../apiClient/types';
import { ClickToCopyTextArea } from './ClickToCopyTextArea';

type Props = {
  actionResult: ActionResult;
};

export const HardwareActionLogs = ({ actionResult }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <>
      <Text fontWeight="bold" pl={1} pt={1} pb={3}>
        <FormattedMessage id="hardwareactionlogpage_tab_logs" />
      </Text>
      <Accordion allowToggle>
        <AccordionItem onChange={() => setIsExpanded(!isExpanded)}>
          <AccordionButton>
            <Flex w="100%" justifyContent="space-between">
              <Flex p={2}>
                <Icon as={FiClipboard} mr={4} boxSize={8} color="blue.300"></Icon>
                <Box textAlign="left">
                  <Heading as="h5" size="sm" mt={1.5}>
                    <FormattedMessage id="hardwareactionlogpage_progress" />
                  </Heading>
                </Box>
              </Flex>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            {actionResult.progress && <ClickToCopyTextArea value={actionResult.progress.details} />}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {actionResult.commandResult && (
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Flex w="100%" justifyContent="space-between">
                <Flex p={2}>
                  <Icon as={FiClipboard} mr={4} boxSize={8} color="blue.300"></Icon>
                  <Box textAlign="left">
                    <Heading as="h5" size="sm" mt={1.5}>
                      <FormattedMessage id="hardwareactionlogpage_results" />
                    </Heading>
                  </Box>
                </Flex>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {actionResult.commandResult && (
                <ClickToCopyTextArea
                  value={
                    actionResult.commandResult.errOut ??
                    actionResult.commandResult.stdOut ??
                    actionResult.commandResult.exitValue ??
                    ''
                  }
                />
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};
