import { ReactNode } from 'react';
import { Box, Container, Stack } from '@chakra-ui/react';
import { Header } from './header/header';
import { Footer } from './footer';

export function Layout(props: { children: ReactNode }) {
  return (
    <>
      <Header />
      <Box paddingX={{ base: 0, md: 8 }} paddingTop={14} paddingBottom={28} bg="gray.100">
        <Container maxW="container.lg">
          <Stack spacing={8}>{props.children}</Stack>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
