import { AddIcon, RepeatIcon } from '@chakra-ui/icons';
import { Button, HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import { PageHeading } from '../../layout/PageHeading';
import { BackButton } from '../../layout/BackButton';
import { Layout } from '../../layout/Layout';
import { CreateRealmModal } from './CreateRealmModal';
import { RealmsTable } from './RealmsTable';
import { useNavigate } from 'react-router-dom';
import { useRealms } from './useRealms';
import { FormattedMessage, useIntl } from 'react-intl';
import { Loader } from '../../layout/common/Loader';
import { EmptyContent } from '../../layout/common/EmptyContent';

/**
 * Main realms list page, displays a list of realms the user can manage.
 */

export const RealmsList = () => {
  const {
    realmsList: realms,
    isRealmsListLoading: isLoading,
    isRealmsListError: isError,
    refetchRealms: refetch,
  } = useRealms();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const intl = useIntl();

  const handleRealmClick = (realmId: string) => {
    navigate(`/realm/manage/${realmId}`);
  };

  const handleCreateRealm = () => {
    onOpen();
  };

  let content;
  if (isLoading || !realms) {
    content = <Loader label={<FormattedMessage id="commons_loading" />} />;
  } else if (isError || !realms) {
    content = (
      <EmptyContent
        emptyContentLabel={<FormattedMessage id="realms_list_error" />}
        includesHeading={false}
      />
    );
  } else {
    content = <RealmsTable handleRowClick={handleRealmClick} realms={realms} />;
  }

  return (
    <Layout>
      <HStack align="top">
        <PageHeading
          heading={<FormattedMessage id="realms_list_title" />}
          description={<FormattedMessage id="realms_list_description" />}
        />
        <IconButton
          isRound
          isLoading={isLoading}
          variant="ghost"
          onClick={() => refetch()}
          icon={<RepeatIcon />}
          aria-label={intl.formatMessage({ id: 'commons_refresh' })}
        />
      </HStack>
      <HStack align="start" justify="space-between">
        <BackButton />
        <Button
          /* disabled={!sessionInformation?.realmCreate} */
          disabled
          size="sm"
          leftIcon={<AddIcon />}
          onClick={() => handleCreateRealm()}
        >
          <FormattedMessage id="realms_list_create_realm" />
        </Button>
      </HStack>
      {content}
      <CreateRealmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => {
          onClose();
        }}
      />
    </Layout>
  );
};
