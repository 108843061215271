import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Text, Box } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { CircularProgress, CircularProgressLabel, VStack } from '@chakra-ui/react';
import { ActionResult, Hardware } from '../../apiClient/types';
import { useEffect, useState } from 'react';
import { HardwareActionProgressBadge } from '../../hardware/HardwareActionProgressBadge';

export function HardwareActionModal(props: {
  result: ActionResult;
  hardware: Hardware;
  onClose: () => unknown;
  refresh: () => unknown;
}) {
  const [timer, setTimer] = useState<number>(5);
  const [progress, setProgress] = useState<number>(0);

  // Cancel redirect if modal is closed
  function handleClose() {
    if (timer) {
      clearTimeout(timer);
    }

    props.onClose();
    props.refresh();
  }

  // Redirect user to log results if timer 0
  useEffect(() => {
    if (timer === 0) {
      window.location.href = `/hardware/${props.hardware.location}/run/${props.result.run.id}`;
    }
  }, [timer, props.hardware.location, props.result.run.id]);

  // Update timer and progress values for CircularProgress
  useEffect(() => {
    if (timer > 0) {
      const timeout = setTimeout(() => {
        setTimer(timer - 1);
        setProgress((5 - timer + 1) * 20);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [timer]);

  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.result.run.action.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <Text>
              <FormattedMessage id="hardwareactionmodal_title" />
            </Text>
            <Text>
              <FormattedMessage id="hardwareactionmodal_subtitle" />
            </Text>
            <CircularProgress
              py={2}
              value={progress}
              color="blue.300"
              size="40px"
              thickness="10px"
              isIndeterminate={false}
            >
              <CircularProgressLabel>{timer}</CircularProgressLabel>
            </CircularProgress>
            <HardwareActionProgressBadge result={props.result} />
            <Box mt={2} textColor={'blue.300'}>
              <NavLink to={`/hardware/${props.hardware.location}/run/${props.result.run.id}`}>
                <Text>{props.result.run.id}</Text>
              </NavLink>
            </Box>
            <Box>
              <FormattedMessage id="hardwareactionmodal_description" />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" variant="solid" onClick={handleClose}>
            <FormattedMessage id="commons_close" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
