import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import { FiUsers } from 'react-icons/fi';
import { Hardware, HardwareQueue } from '../../apiClient/types';
import { QueueStatusBadge } from '../hardware/QueueStatusBadge';
import { QueueDetailGrid } from './QueueDetailGrid';

type Props = {
  queue: HardwareQueue;
  hardware: Hardware[] | undefined;
  hardwareLocation?: string;
  refresh: () => void;
};

export const QueueDetailItem = (props: Props) => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Flex w="100%" justifyContent="space-between">
          <Flex p={2}>
            <Icon as={FiUsers} mr={4} mt={1} boxSize={8} color="blue.300" />
            <Box textAlign="left">
              <Heading as="h5" size="sm">
                {props.queue.name}
              </Heading>
              {props.queue.message ? (
                <Text color="gray.500">{[props.queue.id, props.queue.message].join(' - ')}</Text>
              ) : (
                <Text color="gray.500">{props.queue.id}</Text>
              )}
            </Box>
          </Flex>
          <HStack>
            <QueueStatusBadge queueStatus={props.queue.status} />
          </HStack>
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pt={4} pb={4}>
        <QueueDetailGrid
          queue={props.queue}
          hardware={props.queue.hardware}
          refresh={props.refresh}
          hardwareLocation={props.hardwareLocation}
        />
      </AccordionPanel>
    </AccordionItem>
  );
};
