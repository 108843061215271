import { Badge, BadgeProps } from '@chakra-ui/layout';
import { Hardware, ReservationStatusEnum } from '../../apiClient/types';
import { FormattedMessage } from 'react-intl';

type Props = { hardware: Hardware } & BadgeProps;

export function ReservationStatusBadge({ hardware, ...badgeProps }: Props) {
  switch (hardware.status.reservation.status) {
    case ReservationStatusEnum.FREE:
      return (
        <Badge variant="outline" colorScheme="gray" {...badgeProps}>
          <FormattedMessage id="reservationstatusbadge_free" />
        </Badge>
      );
    case ReservationStatusEnum.RESERVED_BY_ME:
      return (
        <Badge variant="solid" colorScheme="green" {...badgeProps}>
          <FormattedMessage id="reservationstatusbadge_reserved_by_me" />
        </Badge>
      );
    case ReservationStatusEnum.RESERVED:
      return (
        <Badge variant="solid" colorScheme="red" {...badgeProps}>
          <FormattedMessage id="reservationstatusbadge_reserved" />
        </Badge>
      );
    default:
      return null;
  }
}
