import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { saveAs } from 'file-saver';
import { Layout } from '../../layout/Layout';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';
import { type Artifact } from '../../apiClient/types';
import { EmptyContent } from '../../layout/common/EmptyContent';
import { Loader } from '../../layout/common/Loader';
import { DownloadsPageContent } from './DownloadsPageContent';

export function DownloadsPage() {
  const showError = useShowError();
  const intl = useIntl();
  const { listArtifacts, downloadArtifact } = useApiClient();

  const [openTooltipId, setOpenTooltipId] = useState<string | null>(null);
  const [isLoadingArtifacts, setLoadingArtifacts] = useState(false);
  const [artifacts, setArtifacts] = useState<Artifact[]>();
  const loadArtifacts = useCallback(
    async function loadArtifacts() {
      setLoadingArtifacts(true);
      try {
        setArtifacts(await listArtifacts());
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'downloadspage_list_error' }),
        });
      }
      setLoadingArtifacts(false);
    },
    [listArtifacts, showError, intl]
  );

  useEffect(() => void loadArtifacts(), [loadArtifacts]);

  const [downloadingArtifactIds, setDownloadingArtifactIds] = useState<Set<string>>(new Set());
  const downloadFile = async (artifact: Artifact) => {
    try {
      setDownloadingArtifactIds((prev) => {
        const next = new Set(prev);
        return next.add(artifact.id);
      });
      const file = await downloadArtifact(artifact);
      saveAs(file.blob, file.name);
    } catch (error) {
      showError(error, {
        description: intl.formatMessage({ id: 'downloadspage_download_error' }),
      });
    } finally {
      setDownloadingArtifactIds((prev) => {
        const next = new Set(prev);
        next.delete(artifact.id);
        return next;
      });
    }
  };

  const handleToggle = (artifactId: string) => {
    setOpenTooltipId(openTooltipId === artifactId ? null : artifactId);
  };

  let content;
  if (isLoadingArtifacts) {
    content = <Loader label={<FormattedMessage id="downloadspage_loading" />} />;
  } else if (artifacts && artifacts.length > 0) {
    content = (
      <DownloadsPageContent
        artifacts={artifacts}
        loadArtifacts={loadArtifacts}
        isLoadingArtifacts={isLoadingArtifacts}
        downloadFile={downloadFile}
        downloadingArtifactIds={downloadingArtifactIds}
        handleToggle={handleToggle}
        openTooltipId={openTooltipId}
        setOpenTooltipId={setOpenTooltipId}
      />
    );
  } else {
    content = (
      <EmptyContent
        heading={<FormattedMessage id="downloadspage_heading" />}
        description={<FormattedMessage id="downloadspage_description" />}
        emptyContentLabel={<FormattedMessage id="downloadspage_no_downloads" />}
      />
    );
  }

  return <Layout>{content}</Layout>;
}
