import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useApiClient } from '../apiClient/useApiClient';
import { Hardware, HardwareProxyParameters } from '../apiClient/types';
import { useShowError } from '../useShowError';

export function useConnectHardware(
  hardware: Hardware,
  onSuccess: () => void,
  proxyManager?: string | undefined,
  proxyParameters?: HardwareProxyParameters
) {
  const { selectHardware } = useApiClient();
  const showError = useShowError();
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);
  async function handleConnect(event: React.FormEvent) {
    event.preventDefault();
    setLoading(true);
    try {
      await selectHardware({
        hardwareLocation: hardware.location,
        proxyManager,
        proxyParameters,
      });
      onSuccess();
    } catch (error) {
      showError(error, {
        description: intl.formatMessage({ id: 'connectionmodal_connect_proxies_error' }),
      });
    }
    setLoading(false);
  }
  return { isLoading, handleConnect };
}
