import { Button, ButtonGroup } from '@chakra-ui/button';
import { Divider, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Hardware, HardwareQueue, QueueStatusEnum } from '../../apiClient/types';
import { isRequiredAccessLevelForAction } from '../../hardware/isRequiredAccessLevelForAction';
import { QueueDetailGridActions } from './QueueDetailGridActions';
import { useQueueHardware } from './useQueueHardware';

type Props = {
  queue: HardwareQueue;
  hardware: Hardware[] | undefined;
  refresh: () => unknown;
  hardwareLocation?: string;
};

export function QueueDetailGrid(props: Props) {
  const [selectedHardwares, setSelectedHardwares] = useState<Set<string>>(new Set());
  const { isLoading, deleteHardwareQueue, reserveQueue, queueFromSelection } = useQueueHardware();

  const handleQueueSelectedHardwares = async () => {
    const hardwareLocations = Array.from(selectedHardwares);
    await queueFromSelection(hardwareLocations);
    await props.refresh();
  };

  const handleRefreshOnAction = async (action: () => Promise<void>) => {
    await action();
    await props.refresh();
  };

  const canPerformAction = (action: 'canReserve' | 'canQueue') => {
    return Array.from(selectedHardwares).every((location) => {
      const hardwareList = props.hardware?.find((hardware) => hardware.location === location);
      return (
        hardwareList?.guard[action] && isRequiredAccessLevelForAction(hardwareList?.accessLevel)
      );
    });
  };

  const toggleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      const allHardwareLocations = props.queue.hardware?.map((hardware) => hardware.location);
      setSelectedHardwares(new Set(allHardwareLocations));
    } else {
      setSelectedHardwares(new Set());
    }
  };

  const toggleHardwareSelection = (location: string, isChecked: boolean) => {
    const newSelectedHardware = new Set(selectedHardwares);
    if (isChecked) {
      newSelectedHardware.add(location);
    } else {
      newSelectedHardware.delete(location);
    }
    setSelectedHardwares(newSelectedHardware);
  };

  const pendingForMe = props.queue.status === QueueStatusEnum.PENDING_FOR_ME;
  const allChecked = props.queue.hardware?.length === selectedHardwares.size;
  const isIndeterminate = selectedHardwares.size > 0 && !allChecked;

  return (
    <VStack w="full" px="2" pb="4" align="left">
      <Text fontWeight="bold">
        <FormattedMessage id="queue_details_hardware_in_queue" />
      </Text>
      <Divider orientation="horizontal" borderColor="gray.300" />
      <QueueDetailGridActions
        queue={props.queue}
        allChecked={allChecked}
        isIndeterminate={isIndeterminate}
        selectedHardwares={selectedHardwares}
        toggleSelectAll={toggleSelectAll}
        toggleHardwareSelection={toggleHardwareSelection}
      />
      <ButtonGroup variant="outline" mb={2}>
        {props.queue.myQueue && (
          <>
            <Button
              onClick={handleQueueSelectedHardwares}
              isLoading={isLoading}
              isDisabled={selectedHardwares.size === 0 || !canPerformAction('canQueue')}
            >
              <FormattedMessage id="queue_details_grid_queue" />
            </Button>
            <Button
              onClick={() => handleRefreshOnAction(() => reserveQueue(props.queue.id))}
              isLoading={isLoading}
              isDisabled={!canPerformAction('canReserve' || !pendingForMe)}
            >
              <FormattedMessage id="queue_reserve_queue" />
            </Button>
            <Button
              onClick={() => handleRefreshOnAction(() => deleteHardwareQueue(props.queue.id))}
              isLoading={isLoading}
              isDisabled={!canPerformAction('canQueue')}
            >
              <FormattedMessage id="queue_details_grid_remove" />
            </Button>
          </>
        )}
        <Button
          as={NavLink}
          to={`/queues/${props.queue.id}`}
          variant={props.queue.myQueue ? 'ghost' : 'outline'}
        >
          <FormattedMessage id="queue_details_grid_details" />
        </Button>
      </ButtonGroup>
    </VStack>
  );
}
