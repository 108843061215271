import { VStack } from '@chakra-ui/react';
import { FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import { GroupAdmin } from '../../apiClient/types';
import { FormValues } from './AddUserModal';
import { UserGroupFormControl } from './UserGroupFormControl';
import { UserIdFormControl } from './UserIdFormControl';
import { UserRetirementFormControl } from './UserRetirementFormControl';

type Props = {
  values: FormValues;
  touched: FormikTouched<FormValues>;
  errors: FormikErrors<FormValues>;
  groups: GroupAdmin[];
  handleGroupChange: (
    groupId: string,
    isChecked: boolean,
    setFieldValue: FormikHelpers<FormValues>['setFieldValue'],
    values: FormValues
  ) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<FormValues>>;
};

export const AddUserFormControl = ({
  values,
  touched,
  errors,
  groups,
  handleGroupChange,
  setFieldValue,
}: Props) => {
  return (
    <VStack w="full" align="start">
      <UserIdFormControl
        userIds={values.userIds}
        onChangeUserId={(value) => setFieldValue('userIds', value)}
        error={!!(touched.userIds && errors.userIds)}
      />
      <UserGroupFormControl
        groups={groups}
        onGroupChange={(groupId, isChecked) =>
          handleGroupChange(groupId, isChecked, setFieldValue, values)
        }
        error={!!(touched.groupIds && errors.groupIds)}
      />
      <UserRetirementFormControl
        retired={values.retirement?.retired}
        onChangeRetirement={(retired) => setFieldValue('retirement.retired', retired)}
        startDate={values.retirement?.validFrom ? new Date(values.retirement.validFrom) : null}
        onChangeStartDate={(date) => setFieldValue('retirement.validFrom', date)}
        endDate={values.retirement?.validUntil ? new Date(values.retirement.validUntil) : null}
        onChangeEndDate={(date) => setFieldValue('retirement.validUntil', date)}
        error={!!(touched.retirement && errors.retirement)}
      />
    </VStack>
  );
};
