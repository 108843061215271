import { Box, Table, Tbody, Td, Text, Thead, Tr } from '@chakra-ui/react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { ActionResult } from '../../apiClient/types';
import { ActionStatusBadge } from '../../hardware/ActionStatusBadge';
import { AttributeHeader } from './AttributeHeader';

type Props = {
  actionResult: ActionResult;
};

type Header = {
  id: string;
  tooltip?: string;
};

const headers: Header[] = [
  { id: 'hardwareactionlogpage_actionname' },
  { id: 'hardwareactionlogpage_actionid' },
  { id: 'hardwareactionlogpage_runstatus' },
  { id: 'hardwareactionlogpage_runid' },
  { id: 'hardwareactionlogpage_startedby' },
  { id: 'hardwareactionlogpage_starttime', tooltip: 'DD/MM/YYYY' },
  { id: 'hardwareactionlogpage_completedtime', tooltip: 'DD/MM/YYYY' },
];

export function ActionResultAttributesTable({ actionResult }: Props) {
  return (
    <Box w="full">
      <Text fontWeight="bold" pl={1} pt={1} pb={3}>
        <FormattedMessage id="hardwareactionlogpage_tab_summary" />
      </Text>
      <Box overflowX="auto">
        <Table size="sm">
          <Thead>
            <Tr>
              {headers.map((header) => (
                <AttributeHeader key={header.id} localeId={header.id} tooltip={header.tooltip} />
              ))}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{actionResult.run.action.name}</Td>
              <Td>{actionResult.run.action.id}</Td>
              <Td>
                <ActionStatusBadge run={actionResult.run} />
              </Td>
              <Td>{actionResult.run.id}</Td>
              <Td>{actionResult.run.createdBy}</Td>
              <Td>{moment(actionResult.run.createdOn).format('DD/MM/YYYY HH:mm')}</Td>
              <Td>{moment(actionResult.completedOn).format('DD/MM/YYYY HH:mm')}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
