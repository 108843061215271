import { useContext } from 'react';
import { WebSocketContext } from './WebSocketProvider';

export type WebSocketMessage = {
  location: string[];
  subject: string;
};

export function useWebSocket() {
  const context = useContext(WebSocketContext);

  if (!context) {
    throw new Error('useWebSocket must be used inside WebSocketProvider');
  }

  const { latestWsMessage, webSocket, initialize, reconnectWebSocket, sendMessage } = context;
  return { latestWsMessage, webSocket, initialize, reconnectWebSocket, sendMessage };
}
