import { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useApiClient } from '../../apiClient/useApiClient';
import { ProxyManager } from '../../apiClient/types';
import { useShowError } from '../../useShowError';

export function useLoadProxyManagers() {
  const [proxyManagers, setProxyManagers] = useState<ProxyManager[]>([]);
  const [isLoading, setLoading] = useState(false);

  const { listProxyManagers } = useApiClient();
  const showError = useShowError();
  const intl = useIntl();

  const loadProxyManagers = useCallback(
    async function () {
      setLoading(true);
      try {
        setProxyManagers(await listProxyManagers());
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'connectionmodal_load_proxies_error' }),
        });
      }
      setLoading(false);
    },
    [listProxyManagers, showError, intl]
  );
  return { loadProxyManagers, proxyManagers, isLoading };
}
