import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  HStack,
} from '@chakra-ui/react';

type Props = {
  title: string | React.ReactElement;
  children: React.ReactNode;
  isFocused?: boolean;
};

/**
 * Accordion component for UserModal, used to display the different sections of the modal.
 * @param title - title of the accordion
 * @param children - content of the accordion
 * @param focused - optional, used to expand the accordion when true. useful if multiple accordions are used.
 */

export const UserModalAccordion = ({ title, children, isFocused }: Props) => {
  return (
    <Accordion
      defaultIndex={isFocused ? [0] : undefined}
      allowMultiple
      allowToggle
      border="transparent"
    >
      <AccordionItem>
        <h2>
          <AccordionButton w="full" borderRadius="lg">
            <HStack w="full" justify="space-between">
              <Text fontWeight="bold">{title}</Text>
              <AccordionIcon />
            </HStack>
          </AccordionButton>
        </h2>
        <AccordionPanel px="0" pb={4}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
