import React from 'react';
import {
  Badge,
  Box,
  Divider,
  Grid,
  HStack,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { ModalLayoutProps } from './types';
import { groupValueHelper } from './UserGroupHelper';
import { FormattedMessage } from 'react-intl';

export const ModalLayout: React.FC<ModalLayoutProps> = ({
  realms,
  initialUserGroups,
  handleChange,
}) => {
  return (
    <VStack align="stretch" spacing={5}>
      {realms?.map((realm, index) => (
        <Box key={realm.id}>
          <Grid templateColumns="repeat(3, 1fr)" gap={4} mb={4}>
            <Text fontSize="lg" fontWeight="bold">
              {realm.name}
            </Text>
            <Text fontSize="sm">{realm.description}</Text>
          </Grid>
          <Grid templateColumns="repeat(3, 1fr)" gap={4} alignItems="center">
            <Text fontWeight="bold">
              <FormattedMessage id="session_groups_modal_title_group" />
            </Text>
            <Text fontWeight="bold">
              <FormattedMessage id="session_groups_modal_title_status" />
            </Text>
            <Text fontWeight="bold">
              <FormattedMessage id="session_groups_modal_title_description" />
            </Text>
            {initialUserGroups &&
              realm.groups.map((group, groupIndex) => (
                <React.Fragment key={group.id}>
                  <HStack>
                    <Tooltip label={group.description}>
                      <Badge colorScheme={group.active ? 'green' : 'red'}>{group.name}</Badge>
                    </Tooltip>
                  </HStack>
                  <RadioGroup
                    onChange={(value) => handleChange(value, index, groupIndex)}
                    value={group.active ? 'Active' : 'Inactive'}
                  >
                    <Radio value="Inactive" mr="10px">
                      {groupValueHelper({ item: group, status: 'inactive' })}
                    </Radio>
                    <Radio value="Active">
                      {groupValueHelper({ item: group, status: 'active' })}
                    </Radio>
                  </RadioGroup>
                  <Text fontSize="sm">{group.description}</Text>
                </React.Fragment>
              ))}
          </Grid>
          {index < realms.length - 1 && <Divider pt="6" pb="0" w="100%" />}
        </Box>
      ))}
    </VStack>
  );
};
