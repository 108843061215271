import { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SingleHardwareResult } from '../../apiClient/types';
import { useApiClient } from '../../apiClient/useApiClient';
import { useShowError } from '../../useShowError';
import { useWebSocket } from '../../webSocket/useWebSocket';

export function useLoadSingleHardware(hardwareLocation: string) {
  const [hardwareResult, setHardwareResult] = useState<SingleHardwareResult>();
  const { getSingleHardware } = useApiClient();
  const [isLoading, setLoading] = useState(false);
  const showError = useShowError();
  const intl = useIntl();
  const { latestWsMessage, webSocket, sendMessage } = useWebSocket();

  const loadHardware = useCallback(
    async function () {
      setLoading(true);
      try {
        setHardwareResult(
          await getSingleHardware({
            hardwareLocation,
          })
        );
        setLoading(false);
      } catch (error) {
        showError(error, {
          description: intl.formatMessage({ id: 'hardwaredetailpage_load_hardware_error' }),
        });
        setLoading(false);
      }
    },
    [getSingleHardware, hardwareLocation, intl, showError]
  );

  useEffect(() => {
    if (webSocket) {
      const message = `Hardware { hardware: ["${hardwareLocation}"] }`;
      sendMessage(message);
    }
  }, [webSocket, hardwareLocation, sendMessage]);

  useEffect(() => {
    if (
      latestWsMessage &&
      latestWsMessage.subject === 'hardwareStateChange' &&
      latestWsMessage.location.includes(hardwareLocation)
    ) {
      loadHardware();
    }
  }, [latestWsMessage, hardwareLocation, loadHardware]);

  return {
    isLoading,
    loadHardware,
    hardware: hardwareResult?.hardware,
    hardwareDetail: hardwareResult?.detail,
  };
}
