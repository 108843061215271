import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Radio,
  Tooltip,
} from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  isExcluding: boolean;
  handleToggleExclude: () => void;
};

export function HardwareFilterInputMenu({ isExcluding, handleToggleExclude }: Props) {
  const intl = useIntl();
  return (
    <Menu>
      <Tooltip hasArrow label={intl.formatMessage({ id: 'hardwarefilter_more_options' })}>
        <MenuButton>
          <BsThreeDotsVertical />
        </MenuButton>
      </Tooltip>
      <MenuList onClick={(e) => e.stopPropagation()}>
        <MenuGroup title={intl.formatMessage({ id: 'hardwarefilter_keyword_behavior' })}>
          <MenuDivider />
          <MenuItem>
            <Radio isChecked={!isExcluding} onChange={handleToggleExclude}>
              <FormattedMessage id="hardwarefilter_include" />
            </Radio>
          </MenuItem>
          <MenuItem>
            <Radio isChecked={isExcluding} onChange={handleToggleExclude}>
              <FormattedMessage id="hardwarefilter_exclude" />
            </Radio>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
