import { Artifact } from '../../apiClient/types';
import { Button, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

type Props = {
  onSelect: (title: string) => void;
  searchData: Artifact[];
  selectedFilter: string;
};

export const HardwareFilterMenu = (props: Props) => {
  return (
    <Menu>
      <MenuButton minW="max-content" as={Button} rightIcon={<ChevronDownIcon />}>
        {props.selectedFilter}
      </MenuButton>
      <MenuList>
        {props.searchData.map((artifact, index) => (
          <MenuItem onClick={() => props.onSelect(artifact.name)} key={`${artifact.name}-${index}`}>
            <Text fontSize="sm">{artifact.name}</Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
