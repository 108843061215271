import { Textarea, TextareaProps } from '@chakra-ui/react';
import React from 'react';
import ResizeTextarea from 'react-textarea-autosize';

export const AutoResizeTextArea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    return (
      <Textarea
        minH="125px"
        maxH="500px"
        isReadOnly
        resize="none"
        overflowX="hidden"
        overflowY="auto"
        ref={ref}
        as={ResizeTextarea}
        {...props}
      />
    );
  }
);
