import { Layout } from '../../layout/Layout';
import { BackButton } from '../../layout/BackButton';
import { PageHeading } from '../../layout/PageHeading';
import { Hardware, HardwareQueue } from '../../apiClient/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { HardwareFilter } from '../hardwareFilter/HardwareFilter';
import { HStack, VStack } from '@chakra-ui/layout';
import { RepeatIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/button';
import { HardwarePageEmptyContent } from './HardwarePageEmptyContent';
import { HardwarePageAccordion } from './HardwarePageAccordion';

type Props = {
  hardwares: Hardware[] | undefined;
  hardwareQueues: HardwareQueue[] | undefined;
  hardwareQueueStatusMap: Map<any, any>;
  displayedHardwares: Hardware[] | undefined;
  orderedHardware: boolean;
  isLoading: boolean;
  isLoadingQueue: boolean;
  includedKeywords: string[];
  excludedKeywords: string[];
  inputSearchString: string;
  selectedFilter: string;
  artifactLocation: string | null;
  handleRefresh: () => Promise<void>;
  loadQueues: () => Promise<void>;
  onRefreshClick: () => void;
  handleSearchChange: (search: string, isExclude: boolean) => void;
  handleSelectFilter: (title: string | null) => void;
  onChangeSelectedFilter: (filter: string) => void;
  onChangeKeywordFilters: (keywords: string) => void;
  onChangeOrderedHardware: (orderByReservation: boolean) => void;
  handleQueueCreation: (hardwareLocations: string[], message?: string) => Promise<void>;
  shouldShowQueueOptions: () => boolean;
};

export const HardwarePageContent = (props: Props) => {
  const intl = useIntl();
  return (
    <Layout>
      <HStack justify="space-between" w="full">
        <VStack align="top" w="full">
          <HStack>
            <PageHeading
              heading={<FormattedMessage id="hardwarepage_heading" />}
              description={<FormattedMessage id="hardwarepage_description" />}
            />
            <IconButton
              isRound
              isLoading={props.isLoading}
              variant="ghost"
              onClick={props.onRefreshClick}
              icon={<RepeatIcon />}
              aria-label={intl.formatMessage({ id: 'commons_refresh' })}
            />
          </HStack>
          <HStack pt={2}>
            <BackButton />
          </HStack>
          <HardwareFilter {...props} />
        </VStack>
      </HStack>
      {<HardwarePageEmptyContent {...props} />}
      {props.displayedHardwares && (
        <HardwarePageAccordion
          displayedHardwares={props.displayedHardwares}
          orderedHardware={props.orderedHardware}
          hardwareQueues={props.hardwareQueues}
          hardwareQueueStatusMap={props.hardwareQueueStatusMap}
          isLoading={props.isLoadingQueue}
          artifactLocation={props.artifactLocation}
          handleRefresh={props.handleRefresh}
          loadQueues={props.loadQueues}
          handleQueueCreation={props.handleQueueCreation}
          onChangeOrderedHardware={props.onChangeOrderedHardware}
          shouldShowQueueOptions={props.shouldShowQueueOptions}
        />
      )}
    </Layout>
  );
};
